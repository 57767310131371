import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 * Project ListのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/__/__    新規作成
 */

@Injectable()
export class BundleFileDropDialogService extends AbstractService {
    constructor(private httpClient: HttpClient) {
        super();
    }

    postFileAndFileList(body, file: File) {
        if (file && file[0]) {
            const formData: FormData = new FormData();
            formData.append('file', file[0]);
            formData.append('imageFlg', body.imageFlg);
            formData.append('itemFlg', body.itemFlg);
            formData.append('rakutenAfterwardFlg', body.rakutenAfterwardFlg);
            formData.append('skuFlg', body.skuFlg);
            formData.append('selectFlg', body.selectFlg);
            formData.append('catFlg', body.catFlg);
            formData.append('productFlg', body.productFlg);
            formData.append('shoplistFlg', body.shoplistFlg);
            formData.append('shoplistAfterwardFlg', body.shoplistAfterwardFlg);
            formData.append('shoplistBrandFlg', body.shoplistBrandFlg);
            formData.append('yahooFlg', body.yahooFlg);
            formData.append('amazonFlg', body.amazonFlg);
            formData.append('itemDateFlg', body.itemDateFlg);
            formData.append('imageDateFlg', body.imageDateFlg);
            formData.append('mitsuiFlg', body.mitsuiFlg);
            const res = this.httpClient.post(`${this.API_URL}/rest/item/bundle`, formData);
            return res;
        } else {
            throw new Error('csvファイルが読み込めませんでした。');
        }
    }
}
