
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()

export class DeliveryResultService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getHist() {
    return this.httpClient.get(`${this.API_URL}/rest/delivery/history`);
  }

  downloadCsv(fileName,dirId) {
    const params = new HttpParams().set('fileName', fileName);
    return this.httpClient.get(`${this.API_URL}/rest/delivery/history-csv`, { params: params, responseType: 'blob' });
  }

  uploadMovementResultsCsv(file: File) {
    if (file && file[0]) {
      const formData: FormData = new FormData();
      formData.append('file', file[0]);
      const res = this.httpClient.post(`${this.API_URL}/rest/delivery/upload-movement-results`, formData);
      return res;
    } else {
      throw new Error('Invalid Image');
    }
  }
}
