import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 *
 * @author GAUSS
 * @since 1.0.0
 */

@Injectable()
export class SalesRankingService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getContent() {
    return this.httpClient.get(`${this.API_URL}/rest/sales-mgt/ranking`);
  }

  getRankingItems(params) {
    if(typeof params.selectedStartDate !== 'string') {
      params.selectedStartDate = params.selectedStartDate.format('YYYYMMDD');
    }
    if(typeof params.selectedEndDate !== 'string') {
      params.selectedEndDate = params.selectedEndDate.format('YYYYMMDD');
    }
    console.log(params);
    return this.httpClient.get(`${this.API_URL}/rest/sales-mgt/ranking/list`, {params});
  }

}
