import { Component, ViewChild } from '@angular/core';
import { SalesMgtListService } from './SalesMgtListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SalesMgtUploadDialogComponent } from '../upload-dialog/SalesMgtUploadDialogComponent';

@Component({
  selector: 'sales-mgt-list',
  templateUrl: './SalesMgtList.html',
  styleUrls: ['./SalesMgtList.scss'],
  providers: [],
})
export class SalesMgtListComponent extends AbstractComponent<any> {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private service: SalesMgtListService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) {
    super();
  }
  protected render() {
    this.service.get().subscribe((res: any) => {
      this.props.displayedColumns = [
        'site_name',
        'upload_file_name',
        'read_lines_num',
        'overwrite_flag_name',
        'selected_date',
        'exec_status_name',
        'created_at',
        'created_by'
      ];

      this.props.dataSource = new MatTableDataSource<any>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
    });

  }

  public uploadCsv() {
    const dialogRef = this.dialog.open(SalesMgtUploadDialogComponent, {
      width: '500px',
      data: { parent: this },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
      } else {
        this.render();
      }
    }, (err) => {
      this.toastr.error('CSV登録できませんでした。');
      this.loading = false;
    });
  }

  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
