import { RouterModule, Routes } from '@angular/router';
import { UserRoleGuardService } from '../UserRoleGuardService';
import { ShipmentComponent } from "./shipment/ShipmentComponent";
import { ShipmentReportComponent } from "./shipmentReport/ShipmentReportComponent";

/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'zozo',
    children: [
      { path: 'shipment/acs', component: ShipmentComponent, canActivate: [UserRoleGuardService] },
      { path: 'shipment/all', component: ShipmentComponent, canActivate: [UserRoleGuardService] },
      { path: 'shipment/af', component: ShipmentComponent, canActivate: [UserRoleGuardService] },
      { path: 'shipment-report', component: ShipmentReportComponent, canActivate: [UserRoleGuardService] },
    ]
  }
];
export const ZozoRoutingModule = RouterModule.forRoot(routes);
