import { Component, ViewChild } from '@angular/core';
import { ShoplistPassService } from './ShoplistPassService';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'shoplist-password',
    templateUrl: './ShoplistPass.html',
    styleUrls: ['./ShoplistPass.scss'],
    providers: []
})

export class ShoplistPassComponent extends AbstractComponent<any> {
	// View テーブル情報
	@ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private service: ShoplistPassService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private route: ActivatedRoute,
	) {
		super();
		this.props = {
			dataSource: null,
		};
	}
	protected render() {
		this.props.passwordForm = this.formBuilder.group({
			message: new FormControl('', Validators.required),
		});

		this.loading = true;
		this.service.getPassHistory().subscribe((res: any) => {
			this.props.displayedColumns = ['created_at', 'created_by', 'message'];
			this.props.dataSource = new MatTableDataSource<any[]>(res);
			this.props.dataSource.sort = this.sort;
			this.props.dataSource.paginator = this.paginator;
			this.route.params.subscribe(param => {
				if (param.color && param.msg) {
					this.setToast(param.color, param.msg);
				}
			});
			this.loading = false;
		}, (err) => {
			console.log(err);
			this.loading = false;
		});
	}

	/* フィルター検索 */
	applyFilter(filterValue: string) {
		this.props.dataSource.filter = filterValue.trim().toLowerCase();
	}
	
	onSubmit() {
		this.loading = true;
		this.service.postPassword(this.props.passwordForm.value).subscribe(() => {
			this.loading = false;
			this.render();
		}, (err) => {
			console.log(err);
		});
	}
	/* toast表示 */
	setToast(color, msg) {
		if (color === '1') {
			color = 'success';
		}
		if (msg === '10') {
			msg = '登録が完了しました';
		}
		if (msg === '20') {
			msg = '更新が完了しました';
		}
		this.toastr.success(msg);
	}
}