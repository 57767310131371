import { RouterModule, Routes } from '@angular/router';
import { UserListComponent } from './list/UserListComponent';
import { UserUpdateComponent } from './update/UserUpdateComponent';
import { RoleGuardService } from '../../RoleGuardService';

/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'admin/user',
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: UserListComponent, canActivate: [RoleGuardService] },
      { path: 'update', component: UserUpdateComponent, canActivate: [RoleGuardService] },
    ]
  }
];
export const AdminUserRoutingModule = RouterModule.forRoot(routes);
