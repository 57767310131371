import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class ShipmentService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  get(code) {
    const params = new HttpParams().set('code', code);
    return this.httpClient.get(`${this.API_URL}/rest/zozo/shipment/list`, { params:params });
  }

  uploadFile(file, code) {
    const formData: FormData = new FormData();
    formData.append('file', file[0]);
    formData.append('code', code);
    return this.httpClient.post(`${this.API_URL}/rest/zozo/shipment`, formData);
  }

  downloadCsv(path) {
    const params = new HttpParams().set('dirPath', path);
    return this.httpClient.get(`${this.API_URL}/rest/zozo/shipment/csv`, { params: params, responseType: 'blob' });
  }

}
