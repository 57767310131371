import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-yesno-dialog',
  templateUrl: './AppYesNoDialog.html',
  styleUrls: ['./AppYesNoDialog.scss']
})
export class AppYesNoDialogComponent implements OnInit {
  title : string;
  body : string;
  caption : string;
  yes : string;
  no : string;
  isCancel : boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public parameter: any,
              private dialog: MatDialog) {
    this.title = parameter.title;
    this.body = parameter.body;
    if (parameter.caption) {
      this.caption = parameter.caption;
    }
    if (parameter.yes) {
      this.yes = parameter.yes;
    }else{
      this.yes = 'はい';
    }
    if (parameter.no) {
      this.no = parameter.no;
    }else{
      this.no = 'いいえ';
    }
    this.isCancel = parameter.isCancel;
  }


  ngOnInit() {
  }

  onSubmit() {
    this.dialog.closeAll();
  }
}
