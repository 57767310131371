import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NoticeListComponent } from '../list/NoticeListComponent';
import { NoticeDeleteDialogService } from './NoticeDeleteDialogService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'notice-delete-dialog',
  templateUrl: './NoticeDeleteDialog.html',
  styleUrls: ['./NoticeDeleteDialog.scss'],
  providers: []
})
export class NoticeDeleteDialogComponent extends AbstractComponent<any> {

  private parent: NoticeListComponent;
  // コンストラクタ
  constructor(private service: NoticeDeleteDialogService,
    @Inject(MAT_DIALOG_DATA) public parameter: any,
    private formBuilder: FormBuilder) {
    super();
    if (parameter) {
      this.parent = parameter.parent;
      this.props.notice = parameter.notice;
    }
  }

  /** 書き出し */
  protected render() {
    let id: string;
    let name: string;
    let email: string;
    if (this.props.notice) {
      id = this.props.notice.id || '';
      name = this.props.notice.name || '';
      email = this.props.notice.email || '';
    }
    this.props.registerForm = this.formBuilder.group({
      name: new FormControl(name, Validators.required),
      email: new FormControl(email),
    });
  }
  click(){
    console.log(this.props.notice);
    this.loading = true;
    const dialogRef = this.parent.deleteDialogRef;
    this.service.deleteNotice(this.props.notice.tid).subscribe((res: any) => {
      this.loading = false;
      dialogRef.close();
    }, (err) => {
      this.loading = false;
      dialogRef.close();
      alert('お知らせ削除に失敗しました。');
    });
  }
}
