import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 * delivery ListのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2019/07/01              新規作成
 */
@Injectable()
export class HomeService extends AbstractService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  getContent() {
    const params = new HttpParams()
      .set('contentName', 'home');
    return this.httpClient.get(`${this.API_URL}/rest/static-content`, { params: params });
  }

}
