import { RouterModule, Routes } from '@angular/router';
import { UserRoleGuardService } from '../UserRoleGuardService';
import { NewListComponent } from './new/NewListComponent';
import { RearrivalListComponent } from './rearrival/RearrivalListComponent';
import { IndividualListComponent } from './individual/IndividualListComponent';
import { BundleListComponent } from './bundle/BundleListComponent';
import { ExcludeListComponent } from './exclude_list/ExcludeListComponent';
import { SkuDialogComponent } from './exclude_list/sku-dialog/SkuDialogComponent';


/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'items',
    children: [
      { path: 'new', component: NewListComponent, canActivate: [UserRoleGuardService] },
      { path: 'rearrival', component: RearrivalListComponent, canActivate: [UserRoleGuardService] },
      { path: 'individual', component: IndividualListComponent, canActivate: [UserRoleGuardService] },
      { path: 'bundle', component: BundleListComponent, canActivate: [UserRoleGuardService] },
      { path: 'exclude_list', component: ExcludeListComponent, canActivate: [UserRoleGuardService] },
      { path: 'sku-dialog', component: SkuDialogComponent, canActivate: [UserRoleGuardService] }
    ]
  }
];
export const ItemsRoutingModule = RouterModule.forRoot(routes);
