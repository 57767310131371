import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource, MatDialogRef } from '@angular/material';
import { BundleListService } from './BundleListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { ToastrService } from 'ngx-toastr';
// import { BundleFileDropDialogComponent } from '../bundle-filedrop-dialog/BundleFileDropDialogComponent';
import { BundleFileDropDialogComponent } from '../bundle-filedrop-dialog/BundleFileDropDialogComponent';

@Component({
  selector: 'bundle-list',
  templateUrl: './BundleList.html',
  styleUrls: ['./BundleList.scss'],
  providers: []
})
export class BundleListComponent extends AbstractComponent<any> {
  // View テーブル情報
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ダイアログ設定
  public BundleFileDropDialog: MatDialogRef<BundleFileDropDialogComponent>;

  // コンストラクタ
  constructor(private service: BundleListService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService) {
    super();
    this.props = {
      dataSource: null,
    };
  }

  // Override
  protected render() {
    this.props.dateList = [];
    for (let cnt = 1; cnt <= 14; cnt++) {
      this.props.dateList.push(cnt);
    }

    // ユーザ一覧取得
    this.loading = true;
    this.service.getConvHistory().subscribe((res: any) => {
      this.props.displayedColumns = ['item_count', 'created_at', 'created_by', 'actions'];
      this.props.dataSource = new MatTableDataSource<any[]>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
      this.route.params.subscribe(param => {
        if (param.color && param.msg) {
          this.setToast(param.color, param.msg);
        }
      });
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
  }

  /* CSV登録 */
  bulkCsv() {
    this.BundleFileDropDialog = this.dialog.open(BundleFileDropDialogComponent, {
      width: '550px',
      data: {parent: this},
    });
    this.BundleFileDropDialog.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
      } else {
        this.render();
      }
    }, (err) => {
      this.toastr.error('CSV登録できませんでした。');
      this.loading = false;
    });
  }

  /* フィルター検索 */
  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* ユーザ登録・編集 */
  updateUser(elementId) {
    this.router.navigate(['/admin/user/update/', { 'id': elementId }]);
  }

  /* zipダウンロード */
  download(convertedFileName, filePath) {
    this.loading = true;
    this.service.downloadCsv(convertedFileName, filePath).subscribe((res: any) => {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(res, convertedFileName);
      } else {
        const objectURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = objectURL;
        link.download = `${convertedFileName}`;
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false;
    }, (err) => {
      this.toastr.error('対象ファイルはダウンロードできません。');
      this.loading = false;
    });
  }

  /* toast表示 */
  setToast(color, msg) {
    if (color === '1') {
      color = 'success';
    }
    if (msg === '10') {
      msg = '登録が完了しました';
    }
    if (msg === '20') {
      msg = '更新が完了しました';
    }
    this.toastr.success(msg);
  }
}
