import { Component, ViewChild, Inject, ViewEncapsulation  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MatSort, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA,MatTableModule } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { SalesRankingService } from './SalesRankingService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { ToastrService } from 'ngx-toastr';
import { tap, map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConditionalExpr } from '@angular/compiler';

@Component({
  selector: 'ranking',
  templateUrl: './SalesRanking.html',
  styleUrls: ['./SalesRanking.scss'],
  providers: []
})
export class SalesRankingComponent extends AbstractComponent<any> {

  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // コンストラクタ
  constructor(
    private service: SalesRankingService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {
    super();
    this.props = {
      brands: [],
      categories: [],
      targets: [],
      selectPeriodList: this.getselectPeriodList(),
      dataSource: null,
      selectedDateRequired: false,
    };
  }

  protected componentWillMount() {
    this.loading = true;
    // 商品情報選択
    this.props.form = new FormGroup({
      selectPeriodList: new FormControl(''),
      rankingTarget: new FormControl(''),
      rankingCategory: new FormControl(''),
      rankingBrand: new FormControl(''),
      selectedStartDate: new FormControl(''),
      selectedEndDate: new FormControl(''),
    });

    this.service.getContent().subscribe((res: any) => {
      this.props.targets = res.targets;
      this.props.categories = res.categories;
      this.props.brands = res.brands;
      this.props.max_order_date = res.period.max_order_date;
      this.props.min_order_date = res.period.min_order_date;
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
    this.props.form.get('selectPeriodList').valueChanges.subscribe((value) => {
      const today = moment().add( -1 , 'days').format('YYYYMMDD');
      switch (value) {
        // 今日から一週間前までのデータ
        case '1':
          let week = moment().add(-7 , 'days').format('YYYYMMDD');
          this.props.form.get('selectedStartDate').setValue(week);
          this.props.form.get('selectedEndDate').setValue(today);
          break;
        case '2':
        // 今日から一ヶ月前までのデータ
          const month = moment().add( -30 , 'days').format('YYYYMMDD');
          this.props.form.get('selectedStartDate').setValue(month);
          this.props.form.get('selectedEndDate').setValue(today);
          break;
        case '3':
         // 今日から半年前までのデータ
          const halfyear = moment().add(-180 , 'days').format('YYYYMMDD');
          this.props.form.get('selectedStartDate').setValue(halfyear);
          this.props.form.get('selectedEndDate').setValue(today);
          break;
        // 全期間データ
        case '4':
          this.props.form.get('selectedStartDate').setValue(this.props.min_order_date);
          this.props.form.get('selectedEndDate').setValue(this.props.max_order_date);
          break;
        // 入力されたデータ
        case '5':
          ;
          break;
      }
    });

    this.props.form.get('selectedStartDate').valueChanges.subscribe((value) => {
      const periodlist = this.props.form.get('selectPeriodList').value;
      let period = {};
      switch ( periodlist ) {
        case '1':
          period = moment().add(-7 , 'days').format('YYYYMMDD');
          break;
        case '2':
          period = moment().add(-30 , 'days').format('YYYYMMDD');
          break;
        case '3':
          period = moment().add(-180 , 'days').format('YYYYMMDD');
          break;
        case '4':
          period = this.props.form.max_order_date.format('YYYYMMDD');
          break;
      }
      if (typeof value === 'string') {
        if ( value !== period) {
          this.props.form.get('selectPeriodList').setValue('5');
        }
      } else {
        if (value.format('YYYYMMDD') !== period) {
          this.props.form.get('selectPeriodList').setValue('5');
        }
      }
    });

    this.props.form.get('selectedEndDate').valueChanges.subscribe((value) => {
      const periodlist = this.props.form.get('selectPeriodList').value;
      let period = {};
      switch (periodlist) {
        case '1':
          period = moment().add(-1 , 'days').format('YYYYMMDD');
          break;
        case '2':
          period = moment().add(-1 , 'days').format('YYYYMMDD');
          break;
        case '3':
          period = moment().add(-1 , 'days').format('YYYYMMDD');
          break;
        case '4':
          period = this.props.form.min_order_date.format('YYYYMMDD');
          break;
      }
      if (typeof value === 'string') {
        if ( value !== period) {
          this.props.form.get('selectPeriodList').setValue('5');
        }
      } else {
        if (value.format('YYYYMMDD') !== period) {
          this.props.form.get('selectPeriodList').setValue('5');
        }
      }
    });

  }

  protected render() {
    this.service.getRankingItems(this.props.form.value).subscribe((res: any) => {
      console.log(res);
      this.props.displayedColumns = ['no', 'image', 'order_date' , 'brand_name' , 'item_id' , 'item_name' , 'total_quantity'];
      const result = res.map((data, no) => {
        no += 1;
        return {
          no,
          image: data.thumb || '/static/images/noimage.png',
          order_date: data.order_date || '-',
          brand_name: data.brand_name || '-',
          item_id: data.item_id,
          item_name: data.item_name || '-',
          total_quantity: data.total_quantity,
        };
      });
    this.props.dataSource = new MatTableDataSource<any[]>(result);
    this.props.dataSource.sort = this.sort;
    this.props.dataSource.paginator = this.paginator;
    this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    })
  }

  private getselectPeriodList() {
    return [
      {
        periodValue: '1',
        periodName: '7日',
      },
      {
        periodValue: '2',
        periodName: '30日',
      },
      {
        periodValue: '3',
        periodName: '180日',
      },
      {
        periodValue: '4',
        periodName: '全期間',
      },
      {
        periodValue: '5',
        periodName: 'カスタム',
      },
    ];
  };

  protected onSubmit() {
    if (this.props.form.invalid) {
      return;
    }
    this.render();
  }
}
