import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog',
  templateUrl: './AppDialog.html',
  styleUrls: ['./AppDialog.scss']
})
export class AppDialogComponent implements OnInit {
  title: string;
  body: string;
  caption: string;
  isCancel: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public parameter: any,
    private dialog: MatDialog) {
    this.title = parameter.title;
    this.body = parameter.body;
    if (parameter.caption) {
      this.caption = parameter.caption;
    }
    this.isCancel = parameter.isCancel;
  }


  ngOnInit() {
  }

  onSubmit() {
    this.dialog.closeAll();
  }

}
