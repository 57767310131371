import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/common/service/AuthService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';

@Component({
  selector: 'app-login',
  templateUrl: './Login.html',
  styleUrls: ['./Login.scss']
})
export class LoginComponent extends AbstractComponent<any> {
  public errorMessage: string;
  public loginForm: FormGroup;
  public submitted = false;
  constructor(
    private router: Router,
    private authService: AuthService) {
    super();
  }

  public render() {
    this.loading = false;
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(255)]),
      password: new FormControl('', [Validators.required])
    });
  }

  public onSubmit() {
    this.loading = true;
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }
    this.authService.login(
      this.loginForm.controls.email.value,
      this.loginForm.controls.password.value
    ).subscribe(res => {
      this.loading = false;
      this.router.navigate(['/']);
    }, err => {
      this.loading = false;
      this.errorMessage = 'Emailまたはパスワードが間違っています。';
    });
  }
}
