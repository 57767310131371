import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UserPasswordService } from './UserPasswordService';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'user-password',
  templateUrl: './UserPassword.html',
  styleUrls: ['./UserPassword.scss'],
  providers: []
})

export class UserPasswordComponent extends AbstractComponent<any>  {
  private isUser: boolean;
  private id: number;
  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private service: UserPasswordService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    super();
    this.props.user = {};
  }

  protected render() {
    // 管理者の場合
    const params = this.route.snapshot.queryParams;
    if (params) {
      this.id = params.id;
      this.props.user.name = params.name;
      } else {
        // 一般ユーザの場合
        this.isUser = true;
        this.props.user.name = '';
        this.service.getUserMe().subscribe((res: any) => {
          this.id = res.tid;
        }, err => {
          this.router.navigate([`/login`]);
        });
      }
    this.props.form = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?["!?\#$%&@\'()*+,-.\/])[a-zA-Z0-9!?z"\#$%&@\'()*+,-.\/]{8,100}$')]),
      // checkPassword: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9!"\#$%&@\\\'()*+,-.\/]{8,100}$')]),
      checkPassword: new FormControl('', Validators.required)
    }, {validator: this.checkPasswords});
  }

  onSubmit() {
    if (this.props.form.invalid) {
      return;
    }
    this.service.updatePass(Number(this.id), this.props.form.controls.password.value).subscribe(res => {
      this.toastr.success('パスワード変更が完了しました');
    }, err => {
      this.toastr.error(err.message);
    });
  }

  private checkPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const checkPassword = group.get('checkPassword').value;
    return pass === checkPassword ? null : { notSame: true };
  }

  return(id) {
    if (this.isUser) {
      this.router.navigate(['/user/edit/']);
    } else {
      this.router.navigate(['/admin/user/update/', { 'id': id }]);
    }
  }
}
