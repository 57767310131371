import { Component, ViewChild } from '@angular/core';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


import { TemplateListService } from './TemplateListService';
import * as moment from 'moment';
import { TemplateDeleteDialogComponent } from '../delete-dialog/TemplateDeleteDialogComponent';

@Component({
  selector: 'template-list',
  templateUrl: './TemplateList.html',
  styleUrls: ['./TemplateList.scss'],
  providers: [],
})
export class TemplateListComponent extends AbstractComponent<any> {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private service: TemplateListService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    super();
  }


  protected render() {

    this.service.getList().subscribe((e:any) => {
      console.log(e);
      let result:any = [];
      for (let i=0; i < e.length; i++) {
        result.push(Object.assign({ no : (i+1) }, e[i]))
      }
      this.props.displayedColumns = [
        'no',
        'block_id',
        'block_title',
        'actions'
      ];
      this.props.dataSource = new MatTableDataSource<any[]>(result);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator
    });
  }


  /** 登録/更新 */
  public update(type, element= {}) {
    this.router.navigate([`/cms/template/update/${ element['tid'] ? element['tid'] : "create" }`, {}]);
  }

  /** 削除 */
  public delete(element) {
    const dialogRef = this.dialog.open(TemplateDeleteDialogComponent, {
      disableClose : true,
      width : "70%",
      height : "600px",
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'SUCCESS') {
        this.render();
      }
    }, error => {
      console.log(error);
    });
  }
}
