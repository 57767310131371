import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { DeliveryEntryDialogService } from './DeliveryEntryDialogService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import { DeliveryListComponent } from '../list/DeliveryListComponent';
/**
 * 案件登録画面
 *  <Controller>
 *
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/06/28 T.Utsunomiya   新規作成
 */
@Component({
  selector: 'delivery-entry-dialog',
  templateUrl: './DeliveryEntryDialog.html',
  styleUrls: ['./DeliveryEntryDialog.scss']
})
export class DeliveryEntryDialogComponent extends AbstractComponent<any> {
  private parent: DeliveryListComponent;

  // コンストラクタ
  constructor(private service: DeliveryEntryDialogService,
    @Inject(MAT_DIALOG_DATA) public parameter: any,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private formBuilder: FormBuilder) {
    super();
    if (parameter) {
      this.parent = parameter.parent;
      this.props.delivery = parameter.delivery;
      this.props.project = parameter.project;
    }
  }

  /** 書き出し */
  protected render() {
    this.props.registerForm = this.formBuilder.group({
      inputDate: new FormControl('', Validators.required),
      warehouseId: new FormControl('', Validators.required),
      deliverySlipNum: new FormControl('', Validators.required),
    });
  }

  /** */
  protected onSubmit() {
    if (this.props.registerForm.invalid) {
      return;
    }
    this.loading = true;
    this.service.registerDeliverySlip(this.props.registerForm.value).subscribe((res: any) => {
      this.toastr.success('登録が完了しました');
      const dialogRef = this.parent.entryDialogRef;
      dialogRef.close();
      this.loading = false;
    }, () => {
      this.loading = false;
      this.toastr.error('登録に失敗しました');
    });

  }

}
