import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 *
 * @author GAUSS
 * @since 1.0.0
 */

@Injectable()
export class SaleService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getContent(siteId) {
    const params = new HttpParams()
      .set('siteId', siteId)
      .set('contentType', 'sale_item');
    return this.httpClient.get(`${this.API_URL}/rest/cms/content`, {params});
  }

  postContent(data) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/content`, data);
  }

  postPreview(data) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/content/preview`, data);
  }

}
