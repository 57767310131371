import { Injectable } from 'node_modules/@angular/core';
import { HttpClient, HttpParams } from 'node_modules/@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 * 在庫振分取得、登録、更新のServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/__/__    新規作成
 */
@Injectable()
export class AdjustStocksService extends AbstractService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  // 在庫振分取得
  getAdjust() {
    return this.httpClient.get(`${this.API_URL}/rest/stocks/adjustStocks`);
  }

  // 在庫振分登録＆更新
  updateAdjust(update) {
    return this.httpClient.post(`${this.API_URL}/rest/stocks/adjustStocks`, {
      update
    });
  }

  templateNotice(sysIds: string) {
    return this.httpClient.post(`${this.API_URL}/rest/notice/template/`, {'sysIds': sysIds});
  }

}
