import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from 
'@angular/material';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './AppErrorDialog.html',
  styleUrls: ['./AppErrorDialog.scss']
})
export class AppErrorDialogComponent implements OnInit {
  title : string;
  body : string;
  caption : string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public parameter: any,
              private dialog: MatDialog) {
    this.title = parameter.title;
    this.body = parameter.body;
    if (parameter.caption) {
      this.caption = parameter.caption;
    }
  }

  ngOnInit() {
  }

  onSubmit() {
    this.dialog.closeAll();
  }

}
