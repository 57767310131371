import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';

/*ファイル選択ダイアログ
@params
  [rq]title : string / ダイアログタイトル
  [rq]body : string / ダイアログ本文
  [rq]caption : string / ダイアログのキャプション
  [op]yes : string / [yes]ボタンに表示するテキスト
  [op]no : string / [no]ボタンに表示するテキスト
  [op]isCancel : boolean = false / [cancel]ボタンの表示
  [op]fileName: string / 指定ファイル名称
  [op]fileExt: string / 指定ファイルの拡張子
  [op]fileMaxSize: number / 指定ファイルの最大ファイルサイズ
  [op]fileMaxCount: number / 指定ファイルのファイル数
@return
  fileInfo:ファイルオブジェクト
@TODO:
  ・複数ファイル対応(制限事項：現在は1ファイルのみ動作)
  ・ファイルサイズ：MB,GB,TBの和名表示対応(制限事項：現在はバイトのみ)
  */

@Component({
  selector: 'app-filedrop-dialog',
  templateUrl: './AppFileDropDialog.html',
  styleUrls: ['./AppFileDropDialog.scss']
})
export class AppFileDropDialogComponent implements OnInit {
  title : string;
  body : string;
  caption : string;
  yes : string;
  no : string;
  isCancel : boolean = false;
  fileName: string;
  fileExt: string;
  fileMaxSize: number;
  fileMaxCount: number = 1;
  fileInfo:any;
  fileInfoFlg:string = '';
  fileObjArray:any[];
  submitFlg:boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public parameter: any,
              private dialog: MatDialogRef<AppFileDropDialogComponent>
  )
  {
    this.title = parameter.title;
    this.body = parameter.body;
    if (parameter.caption) {
      this.caption = parameter.caption;
    }
    if (parameter.yes) {
      this.yes = parameter.yes;
    }else{
      this.yes = 'はい';
    }
    if (parameter.no) {
      this.no = parameter.no;
    }else{
      this.no = 'いいえ';
    }
    if (parameter.isCancel){
      this.isCancel = parameter.isCancel;
    }
    if (parameter.fileName){
      this.fileName = parameter.fileName;
    }
    if (parameter.fileExt){
      this.fileExt = parameter.fileExt.toLowerCase();
    }
    if (parameter.fileMaxSize){
      this.fileMaxSize = parameter.fileMaxSize;
    }else{
      this.fileMaxSize = 5000000;
    }
    if (parameter.fileMaxCount){
      this.fileMaxCount = parameter.fileMaxContent;
    }else{
      this.fileMaxCount = 1;
    }
  }


  ngOnInit() {
    this.fileInfoFlg='';
    this.fileInfo = 'ファイルを選択してください';
  }

  onSubmit() {
    this.dialog.close();
    return this.fileObjArray;
  }

  // ファイルのドラッグ&ドロップ
  onFileDrop(files: File[]): void {
    this.submitFlg = false;
    this.fileInfoFlg='';
    this.fileObjArray = [];
    //ファイル未指定チェック
    if(files && 0<files.length){
      //ファイル数上限チェック
      if(files.length<=this.fileMaxCount) {
        for(let i=0; i<this.fileMaxCount; i++){
          const file = files[i];
          console.log(file);
          //ファイルサイズチェック
          console.log("容量: "+file.size);
          if(file.size<=this.fileMaxSize){
            //ファイル名チェック
            console.log("ファイル名: "+file.name);
            if(typeof this.fileName == "undefined" || file.name == this.fileName){
              //拡張子チェック
              console.log("拡張子: "+file.name.slice(file.name.lastIndexOf('.')+1));
              if(typeof this.fileExt == "undefined" || file.name.slice(file.name.lastIndexOf('.')+1).toLowerCase() == this.fileExt){
                this.submitFlg = true;
                this.fileObjArray.push(file);
                // this.fileInfo = file.name + ' (' + file.type + ', ' + file.size + 'bytes)';
                let FileSize = this.formatFileSize(file.size);
                this.fileInfoFlg='ok';
                this.fileInfo = file.name + ' (' + file.type + ', ' + FileSize + ')';
              }else{
                this.submitFlg = false;
                this.fileInfoFlg='err';
                this.fileInfo = `拡張子が${this.fileExt}であるファイルを選択してください`;
                return;
              }
            }else{
              this.submitFlg = false;
              this.fileInfoFlg='err';
              this.fileInfo = `ファイル名が${this.fileName}であるファイルを選択してください`;
              return;
            }
          }else{
            this.submitFlg = false;
            this.fileInfoFlg='err';
            this.fileInfo = `サイズが${this.fileMaxSize}バイト以下のファイルを選択してください`;
            return;
          }
        }
      }else{
        if(this.fileMaxCount == 1){
          this.submitFlg = false;
          this.fileInfoFlg='err';
          this.fileInfo = 'ファイルを1件だけ選択してください';
        }else{
          this.submitFlg = false;
          this.fileInfoFlg='err';
          this.fileInfo = `ファイルは${this.fileMaxCount}件までしか選択できません`;
        }
        return;
      }
    }else{
      this.submitFlg = false;
      this.fileInfoFlg='err';
      this.fileInfo = 'ファイルを選択してください';
      return;
    }
  }

  // ファイル選択
  onChangeFile(event) {
    this.submitFlg = false;
    this.fileInfoFlg='';
    this.fileObjArray = [];
    //ファイル未指定チェック
    if(event.target.files && 0<event.target.files.length){
      //ファイル数上限チェック
      if(event.target.files.length<=this.fileMaxCount) {
        for(let i=0; i<this.fileMaxCount; i++){
          const file = event.target.files[i];
          console.log(file);
          //ファイルサイズチェック
          console.log("容量: "+file.size);
          if(file.size<=this.fileMaxSize){
            //ファイル名チェック
            console.log("ファイル名: "+file.name);
            if(typeof this.fileName == "undefined" || file.name == this.fileName){
              //拡張子チェック
              console.log("拡張子: "+file.name.slice(file.name.lastIndexOf('.')+1));
              if(typeof this.fileExt == "undefined" || file.name.slice(file.name.lastIndexOf('.')+1).toLowerCase() == this.fileExt){
                this.submitFlg = true;
                this.fileObjArray.push(file);
                // this.fileInfo = file.name + ' (' + file.type + ', ' + file.size + 'bytes)';
                let FileSize = this.formatFileSize(file.size);
                this.fileInfoFlg='ok';
                this.fileInfo = file.name + ' (' + file.type + ', ' + FileSize + ')';
              }else{
                this.submitFlg = false;
                this.fileInfoFlg='err';
                this.fileInfo = `拡張子が${this.fileExt}であるファイルを選択してください`;
                return;
              }
            }else{
              this.submitFlg = false;
              this.fileInfoFlg='err';
              this.fileInfo = `ファイル名が${this.fileName}であるファイルを選択してください`;
              return;
            }
          }else{
            this.submitFlg = false;
            this.fileInfoFlg='err';
            this.fileInfo = `サイズが${this.fileMaxSize}バイト以下のファイルを選択してください`;
            return;
          }
        }
      }else{
        if(this.fileMaxCount == 1){
          this.submitFlg = false;
          this.fileInfoFlg='err';
          this.fileInfo = 'ファイルを1件だけ選択してください';
        }else{
          this.submitFlg = false;
          this.fileInfoFlg='err';
          this.fileInfo = `ファイルは${this.fileMaxCount}件までしか選択できません`;
        }
        return;
      }
    }else{
      this.submitFlg = false;
      this.fileInfoFlg='err';
      this.fileInfo = 'ファイルを選択してください';
      return;
    }
  }

  formatFileSize(fileSize){
    let calFileSize:number = fileSize;
    let i:number = 0
    let showFileSize:string
    while(calFileSize>1024){
      calFileSize = calFileSize / 1024;
      i++
    }
    if(i===1){showFileSize = toInteger(calFileSize).toString()+'KB';}
    else if(i===2){showFileSize = toInteger(calFileSize).toString()+'MB';}
    else if(i===3){showFileSize = toInteger(calFileSize).toString()+'TB';}
    else{showFileSize = toInteger(calFileSize).toString()+'B';}
    return showFileSize;
  }

}
