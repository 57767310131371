import { Component } from '@angular/core';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import { HomeEditService } from './HomeEditService';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'home-edit',
    templateUrl: './HomeEdit.html',
    styleUrls: ['./HomeEdit.scss'],
    providers: []
})
export class HomeEditComponent extends AbstractComponent<any> {

  // コンストラクタ
  constructor(private service: HomeEditService,
      private toastr: ToastrService) {
      super();
      this.props = {
        registerFlg: false,
      };
  }

  protected render() {
    this.props.form = new FormGroup({
      content: new FormControl(null, [Validators.required])
    });

    this.loading = true;
    this.service.getContent().subscribe((res: any) => {
      this.props.form.patchValue({
        content: res.contentBody,
      });
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
  }

  onSubmit() {
    this.loading = true;
    this.props.registerFlg = true;
    this.service.postContent(this.props.form.value).subscribe((res: any) => {
      this.render();
      this.props.registerFlg = false;
      this.loading = false;
      this.toastr.success('更新しました');
    }, (err) => {
      console.log(err);
      this.toastr.error((err.message && (typeof err.message === 'string')) ? err.message : '更新に失敗しました');
      this.props.registerFlg = false;
      this.loading = false;
    });
  }

}
