import { Component, ViewChild } from '@angular/core';
import { CrossmallListService } from './CrossmallListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { typeofExpr } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'crossmall-list',
  templateUrl: './CrossmallList.html',
  styleUrls: ['./CrossmallList.scss'],
  providers: []
})
export class CrossmallListComponent extends AbstractComponent<any> {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public form;
  public file;
  public title: string;
  public linkFileType = this.route.url['value'][0].path;

  constructor(
    private service: CrossmallListService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    super();
  }
  protected render() {
    this.linkFileType = this.route.url['value'][0].path;
    if (this.linkFileType === 'anapnet') {
      this.title = 'ANAPNET';
    } else if (this.linkFileType.indexOf('digit')) {
      this.title = `${this.linkFileType.slice(0, -5)}桁`;
    }
    this.service.getList(this.linkFileType).subscribe((res: any) => {
      this.props.displayedColumns =
        ['convertedFileName', 'convertedFileLinesNum', 'preConvertedFileName', 'preConvertedFileLinesNum', 'createdAt', 'createdBy'];
      this.props.dataSource = new MatTableDataSource<any>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
    });
    this.form = new FormGroup({
      csv: new FormControl(null, [Validators.required])
    });
  }

  upload(file: File[]) {
    this.file = file;
  }

  onSubmit() {
    this.service.uploadCsv(this.file, this.linkFileType).subscribe((res: any) => {
      this.render();
      this.toastr.success('CSVデータの変換が正常に完了しました');
    }, err => {
      this.toastr.error(err.message);
    });
  }

  download(dirPath) {
    this.service.downloadCsv(dirPath).subscribe((res: any) => {
      const fileName = dirPath.slice(dirPath.lastIndexOf('/') + 1);
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(res, fileName);
      } else {
        const objectURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = objectURL;
        link.download = fileName;
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
