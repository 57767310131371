import { Component, ViewChild, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MatSort, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { DeliveryListService } from './DeliveryListService';
import { DeliveryEntryDialogComponent } from '../entry-dialog/DeliveryEntryDialogComponent';
import { DeliveryUpdateDialogComponent } from '../update-dialog/DeliveryUpdateDialogComponent';
import { DeliveryDeleteDialogComponent } from '../delete-dialog/DeliveryDeleteDialogComponent';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

/**
 * 案件一覧画面
 *  <Controller>
 *
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/06/28 T.Utsunomiya   新規作成
 */
@Component({
  selector: 'delivery-list',
  templateUrl: './DeliveryList.html',
  styleUrls: ['./DeliveryList.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class DeliveryListComponent extends AbstractComponent<any> {
  // View テーブル情報
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public entryDialogRef: MatDialogRef<DeliveryEntryDialogComponent>;
  public updateDialogRef: MatDialogRef<DeliveryUpdateDialogComponent>;
  public deleteDialogRef: MatDialogRef<DeliveryDeleteDialogComponent>;
  public inputDate;

  // コンストラクタ
  constructor(private service: DeliveryListService,
    private dialog: MatDialog,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
    ) {
    super();
    this.props = {
      dataSource: null
    };
  }

  // Override
  protected render() {
    this.props.today = moment();
    this.props.warehouseId = this.route.url['value'][1].path;
    this.props.registerForm = this.formBuilder.group({
      inputDate: this.inputDate ? new FormControl(this.inputDate) : new FormControl(this.props.today),
      deliverySlipNum: new FormControl('', Validators.required),
      warehouseId: new FormControl(this.props.warehouseId)
    });
    this.loading = true;
    this.service.getDelivery(this.props.warehouseId).subscribe((res: any) => {
      const result = res.map((data, no) => {
        no += 1;
        return {
          no,
          tid: data.tid,
          warehouseId: data.warehouseId,
          inputDate: data.inputDate,
          deliverySlipId: data.deliverySlipId,
        };
      });
      this.props.displayedColumns = ['no', 'inputDate', 'deliverySlipId', 'actions'];
      this.props.dataSource = new MatTableDataSource<any[]>(result);  // サーバAPI用
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
      this.loading = false;
    }, () => {
      this.loading = false;
    });

  }

  protected onSubmit() {
    if (this.props.registerForm.invalid) {
      return;
    }
    this.loading = true;
    this.service.registerDeliverySlip(this.props.registerForm.value).subscribe((res: any) => {
      this.toastr.success('登録が完了しました');
      this.loading = false;
      this.render();
    }, (e) => {
      console.log(e);
      this.toastr.error((e.message) ? e.message : '登録に失敗しました');
      this.loading = false;
    });

  }

  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // 案件登録
  entryDelivery(element) {
    this.entryDialogRef = this.dialog.open(DeliveryEntryDialogComponent, {
      data: { 'project': element, parent: this },
      height: '410px',
      width: '300px',
    });
    this.entryDialogRef.afterClosed().subscribe(result => {
      this.render();
    });
  }

  updateDelivery(element) {
    this.updateDialogRef = this.dialog.open(DeliveryUpdateDialogComponent, {
      data: { 'project': element, parent: this },
      height: '410px',
      width: '300px',
    });
    this.updateDialogRef.afterClosed().subscribe(result => {
      this.render();
    });
  }

  // 削除
  deleteDelivery(element) {
    this.deleteDialogRef = this.dialog.open(DeliveryDeleteDialogComponent, {
      data: { 'project': element, parent: this },
      height: '410px',
      width: '300px',
    });
    this.deleteDialogRef.afterClosed().subscribe(result => {
      this.render();
    });
  }

  /*
  updatedelivery(delivery) {
    const dialogRef = this.dialog.open(DeliveryUpdateDialogComponent, {
      data : {'delivery' : delivery}
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.render();
    });
  }
  */

}
