import { RouterModule, Routes } from '@angular/router';
import { DeliveryListComponent } from './delivery-input/list/DeliveryListComponent';
import { DeliveryLogComponent } from './delivery-log/DeliveryLogComponent';
import { DeliveryResultComponent } from './delivery-result/DeliveryResultComponent';
import { AuthService } from '../../../core/common/service/AuthService';

/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'delivery',
    children: [
      { path: '', redirectTo: '', pathMatch: 'full' },
      { path: 'list/101707', component: DeliveryListComponent, canActivate: [AuthService] },
      { path: 'list/101640', component: DeliveryListComponent, canActivate: [AuthService] },
      { path: 'log', component: DeliveryLogComponent, canActivate: [AuthService] },
      { path: 'result', component: DeliveryResultComponent, canActivate: [AuthService] },
    ]
  }
];

export const DeliveryRoutingModule = RouterModule.forRoot(routes);
