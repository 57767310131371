import { RouterModule, Routes } from '@angular/router';
import { MappingListComponent } from './mapping/MappingListComponent';
import { UserRoleGuardService } from '../../UserRoleGuardService';

/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'admin/category-mapping',
    children: [
      { path: 'list', component: MappingListComponent, canActivate: [UserRoleGuardService] },
    ]
  }
];
export const CategoryMappingRoutingModule = RouterModule.forRoot(routes);
