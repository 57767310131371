import { NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgProgressModule } from '@ngx-progressbar/core';

import { TreeModule } from 'angular-tree-component';
import { TreeviewModule } from 'ngx-treeview';
import { NgxLoadingModule } from 'ngx-loading';

import { FileUploadModule } from 'ng2-file-upload';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MasonryGalleryModule } from 'ngx-masonry-gallery';
import { ToastrModule } from 'ngx-toastr';

import { JwtInterceptor } from '../interceptors/JwtInterceptor';
import { ErrorInterceptor } from '../interceptors/ErrorInterceptor';

import { DATE_FORMATS } from '../adapter/JPDateAdapter';

/*-- routing import start --*/
import {CategoryMappingRoutingModule} from '../../app/pages/admin/category/CategoryMappingRoutingModule';
import {HomeRoutingModule} from '../../app/pages/admin/home/HomeRoutingModule';
import {AdminUserRoutingModule} from '../../app/pages/admin/user/AdminUserRoutingModule';
import {CmsRoutingModule} from '../../app/pages/cms/cmsRoutingModule';
import {CrossmallRoutingModule} from '../../app/pages/crossmall/CrossmallRoutingModule';
import {DeliveryRoutingModule} from '../../app/pages/delivery/DeliveryRoutingModule';
import {DhRoutingModule} from '../../app/pages/dh/DhRoutingModule';
import {ItemMstRoutingModule} from '../../app/pages/item-mst/ItemMstRoutingModule';
import {ItemsRoutingModule} from '../../app/pages/items/ItemsRoutingModule';
import {MovedataRoutingModule} from '../../app/pages/movedata/MovedataRoutingModule';
import {SalesMgtRoutingModule} from '../../app/pages/sales-mgt/SalesMgtRoutingModule';
import {SalesdataRoutingModule} from '../../app/pages/salesdata/SalesdataRoutingModule';
import {ShoplistRoutingModule} from '../../app/pages/shoplist/ShoplistRoutingModule';
import {StocksRoutingModule} from '../../app/pages/stocks/stocksRoutingModule';
import {UserRoutingModule} from '../../app/pages/user/UserRoutingModule';
import {ZozoRoutingModule} from '../../app/pages/zozo/ZozoRoutingModule';
/*-- routing import end --*/

import { AppRoutingModule } from '../../app/pages/AppRoutingModule';

/*-- declaration import start --*/
import {AngularTreePatchComponent} from '../../app/directives/angular-tree-patch/AngularTreePatchComponent';
import {AppDialogComponent} from '../../app/directives/app-dialog/AppDialogComponent';
import {AppErrorDialogComponent} from '../../app/directives/app-error-dialog/AppErrorDialogComponent';
import {AppFileDropDialogComponent} from '../../app/directives/app-filedrop-dialog/AppFileDropDialogComponent';
import {AppFooterComponent} from '../../app/directives/app-footer/AppFooterComponent';
import {AppHeaderComponent} from '../../app/directives/app-header/AppHeaderComponent';
import {AppLeftSideComponent} from '../../app/directives/app-left-side/AppLeftSideComponent';
import {AppYesNoDialogComponent} from '../../app/directives/app-yesno-dialog/AppYesNoDialogComponent';
import {MappingListComponent} from '../../app/pages/admin/category/mapping/MappingListComponent';
import {HomeEditComponent} from '../../app/pages/admin/home/edit/HomeEditComponent';
import {UserDeleteDialogComponent} from '../../app/pages/admin/user/delete-dialog/UserDeleteDialogComponent';
import {UserListComponent} from '../../app/pages/admin/user/list/UserListComponent';
import {UserUpdateComponent} from '../../app/pages/admin/user/update/UserUpdateComponent';
import {BannerDeleteDialogComponent} from '../../app/pages/cms/banner/delete-dialog/BannerDeleteDialogComponent';
import {BannerListComponent} from '../../app/pages/cms/banner/list/BannerListComponent';
import {BannerUpdateComponent} from '../../app/pages/cms/banner/update/BannerUpdateComponent';
import {DetailComponent} from '../../app/pages/cms/detail/DetailComponent';
import {IndexComponent} from '../../app/pages/cms/index/IndexComponent';
import {NewComponent} from '../../app/pages/cms/new/NewComponent';
import {NoticeDeleteDialogComponent} from '../../app/pages/cms/notice/delete-dialog/NoticeDeleteDialogComponent';
import {NoticeListComponent} from '../../app/pages/cms/notice/list/NoticeListComponent';
import {NoticeUpdateComponent} from '../../app/pages/cms/notice/update/NoticeUpdateComponent';
import {PageDeleteDialogComponent} from '../../app/pages/cms/page/delete-dialog/PageDeleteDialogComponent';
import {PageListComponent} from '../../app/pages/cms/page/list/PageListComponent';
import {PageUpdateComponent} from '../../app/pages/cms/page/update/PageUpdateComponent';
import {RankingComponent} from '../../app/pages/cms/ranking/RankingComponent';
import {SaleComponent} from '../../app/pages/cms/sale/SaleComponent';
import {RakutenComponent} from '../../app/pages/cms/setting/rakuten/RakutenComponent';
import {WowmaComponent} from '../../app/pages/cms/setting/wowma/WowmaComponent';
import {YahooComponent} from '../../app/pages/cms/setting/yahoo/YahooComponent';
import {YahooTokenComponent} from '../../app/pages/cms/setting/yahoo-token/YahooTokenComponent';
import {TemplateDeleteDialogComponent} from '../../app/pages/cms/template/delete-dialog/TemplateDeleteDialogComponent';
import {TemplateListComponent} from '../../app/pages/cms/template/list/TemplateListComponent';
import {TemplateUpdateComponent} from '../../app/pages/cms/template/update/TemplateUpdateComponent';
import {ImportPriceComponent} from '../../app/pages/crossmall/importPrice/ImportPriceComponent';
import {CrossmallListComponent} from '../../app/pages/crossmall/list/CrossmallListComponent';
import {DeliveryDeleteDialogComponent} from '../../app/pages/delivery/delivery-input/delete-dialog/DeliveryDeleteDialogComponent';
import {DeliveryEntryDialogComponent} from '../../app/pages/delivery/delivery-input/entry-dialog/DeliveryEntryDialogComponent';
import {DeliveryListComponent} from '../../app/pages/delivery/delivery-input/list/DeliveryListComponent';
import {DeliveryUpdateDialogComponent} from '../../app/pages/delivery/delivery-input/update-dialog/DeliveryUpdateDialogComponent';
import {DeliveryLogComponent} from '../../app/pages/delivery/delivery-log/DeliveryLogComponent';
import {DeliveryResultComponent} from '../../app/pages/delivery/delivery-result/DeliveryResultComponent';
import {DeliveryResultDialogComponent} from '../../app/pages/delivery/delivery-result/delivery-result-dialog/DeliveryResultDialogComponent';
import {MaruiListComponent} from '../../app/pages/dh/marui/MaruiListComponent';
import {PvsListComponent} from '../../app/pages/dh/pvs/PvsListComponent';
import {HomeComponent} from '../../app/pages/home/HomeComponent';
import {ItemMstListComponent} from '../../app/pages/item-mst/list/ItemMstListComponent';
import {BundleListComponent} from '../../app/pages/items/bundle/BundleListComponent';
import {BundleFileDropDialogComponent} from '../../app/pages/items/bundle-filedrop-dialog/BundleFileDropDialogComponent';
import {ExcludeListComponent} from '../../app/pages/items/exclude_list/ExcludeListComponent';
import {ExcludeItemDeleteDialogComponent} from '../../app/pages/items/exclude_list/delete-dialog/ExcludeItemDeleteDialogComponent';
import {SkuDialogComponent} from '../../app/pages/items/exclude_list/sku-dialog/SkuDialogComponent';
import {IndividualListComponent} from '../../app/pages/items/individual/IndividualListComponent';
import {NewListComponent} from '../../app/pages/items/new/NewListComponent';
import {RearrivalListComponent} from '../../app/pages/items/rearrival/RearrivalListComponent';
import {LoginComponent} from '../../app/pages/login/LoginComponent';
import {MovedataListComponent} from '../../app/pages/movedata/kumai/MovedataListComponent';
import {SalesMgtListComponent} from '../../app/pages/sales-mgt/list/SalesMgtListComponent';
import {SalesRankingComponent} from '../../app/pages/sales-mgt/ranking/SalesRankingComponent';
import {SalesMgtUploadDialogComponent} from '../../app/pages/sales-mgt/upload-dialog/SalesMgtUploadDialogComponent';
import {SalesdataListComponent} from '../../app/pages/salesdata/list/SalesdataListComponent';
import {JanCsvComponent} from '../../app/pages/shoplist/janCsv/JanCsvComponent';
import {MasterComponent} from '../../app/pages/shoplist/master/MasterComponent';
import {ShoplistMovementComponent} from '../../app/pages/shoplist/movement/ShoplistMovementComponent';
import {ShoplistPassComponent} from '../../app/pages/shoplist/password/ShoplistPassComponent';
import {AdjustStocksComponent} from '../../app/pages/stocks/adjust_stocks/AdjustStocksComponent';
import {UserEditComponent} from '../../app/pages/user/edit/UserEditComponent';
import {UserPasswordComponent} from '../../app/pages/user/password/UserPasswordComponent';
import {ShipmentComponent} from '../../app/pages/zozo/shipment/ShipmentComponent';
import {ShipmentReportComponent} from '../../app/pages/zozo/shipmentReport/ShipmentReportComponent';
import {AppComponent} from '../app/AppComponent';
/*-- declaration import end --*/
/*-- service import start --*/
import {AppHeaderService} from '../../app/directives/app-header/AppHeaderService';
import {AppLeftSideService} from '../../app/directives/app-left-side/AppLeftSideService';
import {RoleGuardService} from '../../app/pages/RoleGuardService';
import {UserRoleGuardService} from '../../app/pages/UserRoleGuardService';
import {MappingListService} from '../../app/pages/admin/category/mapping/MappingListService';
import {HomeEditService} from '../../app/pages/admin/home/edit/HomeEditService';
import {UserDeleteDialogService} from '../../app/pages/admin/user/delete-dialog/UserDeleteDialogService';
import {UserListService} from '../../app/pages/admin/user/list/UserListService';
import {UserUpdateService} from '../../app/pages/admin/user/update/UserUpdateService';
import {BannerDeleteDialogService} from '../../app/pages/cms/banner/delete-dialog/BannerDeleteDialogService';
import {BannerListService} from '../../app/pages/cms/banner/list/BannerListService';
import {BannerUpdateService} from '../../app/pages/cms/banner/update/BannerUpdateService';
import {DetailService} from '../../app/pages/cms/detail/DetailService';
import {IndexService} from '../../app/pages/cms/index/IndexService';
import {NewService} from '../../app/pages/cms/new/NewService';
import {NoticeDeleteDialogService} from '../../app/pages/cms/notice/delete-dialog/NoticeDeleteDialogService';
import {NoticeListService} from '../../app/pages/cms/notice/list/NoticeListService';
import {NoticeUpdateService} from '../../app/pages/cms/notice/update/NoticeUpdateService';
import {PageDeleteDialogService} from '../../app/pages/cms/page/delete-dialog/PageDeleteDialogService';
import {PageListService} from '../../app/pages/cms/page/list/PageListService';
import {PageUpdateService} from '../../app/pages/cms/page/update/PageUpdateService';
import {RankingService} from '../../app/pages/cms/ranking/RankingService';
import {SaleService} from '../../app/pages/cms/sale/SaleService';
import {RakutenService} from '../../app/pages/cms/setting/rakuten/RakutenService';
import {WowmaService} from '../../app/pages/cms/setting/wowma/WowmaService';
import {YahooService} from '../../app/pages/cms/setting/yahoo/YahooService';
import {YahooTokenService} from '../../app/pages/cms/setting/yahoo-token/YahooTokenService';
import {TemplateDeleteDialogService} from '../../app/pages/cms/template/delete-dialog/TemplateDeleteDialogService';
import {TemplateListService} from '../../app/pages/cms/template/list/TemplateListService';
import {TemplateUpdateService} from '../../app/pages/cms/template/update/TemplateUpdateService';
import {ImportPriceService} from '../../app/pages/crossmall/importPrice/ImportPriceService';
import {CrossmallListService} from '../../app/pages/crossmall/list/CrossmallListService';
import {DeliveryDeleteDialogService} from '../../app/pages/delivery/delivery-input/delete-dialog/DeliveryDeleteDialogService';
import {DeliveryEntryDialogService} from '../../app/pages/delivery/delivery-input/entry-dialog/DeliveryEntryDialogService';
import {DeliveryListService} from '../../app/pages/delivery/delivery-input/list/DeliveryListService';
import {DeliveryUpdateDialogService} from '../../app/pages/delivery/delivery-input/update-dialog/DeliveryUpdateDialogService';
import {DeliveryLogService} from '../../app/pages/delivery/delivery-log/DeliveryLogService';
import {DeliveryResultService} from '../../app/pages/delivery/delivery-result/DeliveryResultService';
import {DeliveryResultDialogService} from '../../app/pages/delivery/delivery-result/delivery-result-dialog/DeliveryResultDialogService';
import {MaruiListService} from '../../app/pages/dh/marui/MaruiListService';
import {PvsListService} from '../../app/pages/dh/pvs/PvsListService';
import {HomeService} from '../../app/pages/home/HomeService';
import {ItemMstListService} from '../../app/pages/item-mst/list/ItemMstListService';
import {BundleListService} from '../../app/pages/items/bundle/BundleListService';
import {BundleFileDropDialogService} from '../../app/pages/items/bundle-filedrop-dialog/BundleFileDropDialogService';
import {ExcludeListService} from '../../app/pages/items/exclude_list/ExcludeListService';
import {ExcludeItemDeleteDialogService} from '../../app/pages/items/exclude_list/delete-dialog/ExcludeItemDeleteDialogService';
import {SkuDialogService} from '../../app/pages/items/exclude_list/sku-dialog/SkuDialogService';
import {IndividualListService} from '../../app/pages/items/individual/IndividualListService';
import {NewListService} from '../../app/pages/items/new/NewListService';
import {RearrivalListService} from '../../app/pages/items/rearrival/RearrivalListService';
import {MovedataListService} from '../../app/pages/movedata/kumai/MovedataListService';
import {SalesMgtListService} from '../../app/pages/sales-mgt/list/SalesMgtListService';
import {SalesRankingService} from '../../app/pages/sales-mgt/ranking/SalesRankingService';
import {SalesMgtUploadDialogService} from '../../app/pages/sales-mgt/upload-dialog/SalesMgtUploadDialogService';
import {SalesdataListService} from '../../app/pages/salesdata/list/SalesdataListService';
import {JanCsvService} from '../../app/pages/shoplist/janCsv/JanCsvService';
import {MasterService} from '../../app/pages/shoplist/master/MasterService';
import {ShoplistMovementService} from '../../app/pages/shoplist/movement/ShoplistMovementService';
import {ShoplistPassService} from '../../app/pages/shoplist/password/ShoplistPassService';
import {AdjustStocksService} from '../../app/pages/stocks/adjust_stocks/AdjustStocksService';
import {UserEditService} from '../../app/pages/user/edit/UserEditService';
import {UserPasswordService} from '../../app/pages/user/password/UserPasswordService';
import {ShipmentService} from '../../app/pages/zozo/shipment/ShipmentService';
import {ShipmentReportService} from '../../app/pages/zozo/shipmentReport/ShipmentReportService';
import {AuthService} from '../common/service/AuthService';
import {UserService} from '../common/service/UserService';
/*-- service import end --*/
import { AngularMaterialModule } from './AngularMaterialModule';

import { FileDropDirective } from '../../app/directives/app-filedrop/filedrop';  // ファイルドロップ

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MonacoEditorModule } from 'ngx-monaco-editor';


import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
  declarations: [
    /*-- declarations start --*/
    AngularTreePatchComponent
    ,AppDialogComponent
    ,AppErrorDialogComponent
    ,AppFileDropDialogComponent
    ,AppFooterComponent
    ,AppHeaderComponent
    ,AppLeftSideComponent
    ,AppYesNoDialogComponent
    ,MappingListComponent
    ,HomeEditComponent
    ,UserDeleteDialogComponent
    ,UserListComponent
    ,UserUpdateComponent
    ,BannerDeleteDialogComponent
    ,BannerListComponent
    ,BannerUpdateComponent
    ,DetailComponent
    ,IndexComponent
    ,NewComponent
    ,NoticeDeleteDialogComponent
    ,NoticeListComponent
    ,NoticeUpdateComponent
    ,PageDeleteDialogComponent
    ,PageListComponent
    ,PageUpdateComponent
    ,RankingComponent
    ,SaleComponent
    ,RakutenComponent
    ,WowmaComponent
    ,YahooComponent
    ,YahooTokenComponent
    ,TemplateDeleteDialogComponent
    ,TemplateListComponent
    ,TemplateUpdateComponent
    ,ImportPriceComponent
    ,CrossmallListComponent
    ,DeliveryDeleteDialogComponent
    ,DeliveryEntryDialogComponent
    ,DeliveryListComponent
    ,DeliveryUpdateDialogComponent
    ,DeliveryLogComponent
    ,DeliveryResultComponent
    ,DeliveryResultDialogComponent
    ,MaruiListComponent
    ,PvsListComponent
    ,HomeComponent
    ,ItemMstListComponent
    ,BundleListComponent
    ,BundleFileDropDialogComponent
    ,ExcludeListComponent
    ,ExcludeItemDeleteDialogComponent
    ,SkuDialogComponent
    ,IndividualListComponent
    ,NewListComponent
    ,RearrivalListComponent
    ,LoginComponent
    ,MovedataListComponent
    ,SalesMgtListComponent
    ,SalesRankingComponent
    ,SalesMgtUploadDialogComponent
    ,SalesdataListComponent
    ,JanCsvComponent
    ,MasterComponent
    ,ShoplistMovementComponent
    ,ShoplistPassComponent
    ,AdjustStocksComponent
    ,UserEditComponent
    ,UserPasswordComponent
    ,ShipmentComponent
    ,ShipmentReportComponent
    ,AppComponent
    /*-- declarations end --*/
    , FileDropDirective
  ],
  imports: [
    BrowserModule
    , FormsModule
    , DragDropModule
    , ReactiveFormsModule
    , HttpClientModule
    , NgbModule
    , TreeModule
    , FileUploadModule
    , ContextMenuModule.forRoot({
      autoFocus: true,
      useBootstrap4: true,
    })
    , NgProgressModule.forRoot()
    , TreeviewModule.forRoot()
    , NgxLoadingModule.forRoot({
      fullScreenBackdrop: true,
    })
    , BrowserAnimationsModule
    , ToastrModule.forRoot({
      positionClass: 'toast-top-center'
    })
    , MasonryGalleryModule
    , AppRoutingModule
    , OwlDateTimeModule
    , OwlNativeDateTimeModule
    , AngularMaterialModule,
    /*-- routing start --*/
    CategoryMappingRoutingModule
    ,HomeRoutingModule
    ,AdminUserRoutingModule
    ,CmsRoutingModule
    ,CrossmallRoutingModule
    ,DeliveryRoutingModule
    ,DhRoutingModule
    ,ItemMstRoutingModule
    ,ItemsRoutingModule
    ,MovedataRoutingModule
    ,SalesMgtRoutingModule
    ,SalesdataRoutingModule
    ,ShoplistRoutingModule
    ,StocksRoutingModule
    ,UserRoutingModule
    ,ZozoRoutingModule
    /*-- routing end --*/
    , TooltipModule.forRoot()
    , MonacoEditorModule.forRoot() // use forRoot() in main app module only.
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    /*-- service start --*/
    AppHeaderService
    ,AppLeftSideService
    ,RoleGuardService
    ,UserRoleGuardService
    ,MappingListService
    ,HomeEditService
    ,UserDeleteDialogService
    ,UserListService
    ,UserUpdateService
    ,BannerDeleteDialogService
    ,BannerListService
    ,BannerUpdateService
    ,DetailService
    ,IndexService
    ,NewService
    ,NoticeDeleteDialogService
    ,NoticeListService
    ,NoticeUpdateService
    ,PageDeleteDialogService
    ,PageListService
    ,PageUpdateService
    ,RankingService
    ,SaleService
    ,RakutenService
    ,WowmaService
    ,YahooService
    ,YahooTokenService
    ,TemplateDeleteDialogService
    ,TemplateListService
    ,TemplateUpdateService
    ,ImportPriceService
    ,CrossmallListService
    ,DeliveryDeleteDialogService
    ,DeliveryEntryDialogService
    ,DeliveryListService
    ,DeliveryUpdateDialogService
    ,DeliveryLogService
    ,DeliveryResultService
    ,DeliveryResultDialogService
    ,MaruiListService
    ,PvsListService
    ,HomeService
    ,ItemMstListService
    ,BundleListService
    ,BundleFileDropDialogService
    ,ExcludeListService
    ,ExcludeItemDeleteDialogService
    ,SkuDialogService
    ,IndividualListService
    ,NewListService
    ,RearrivalListService
    ,MovedataListService
    ,SalesMgtListService
    ,SalesRankingService
    ,SalesMgtUploadDialogService
    ,SalesdataListService
    ,JanCsvService
    ,MasterService
    ,ShoplistMovementService
    ,ShoplistPassService
    ,AdjustStocksService
    ,UserEditService
    ,UserPasswordService
    ,ShipmentService
    ,ShipmentReportService
    ,AuthService
    ,UserService
    /*-- service end --*/
  ],
  entryComponents: [
    DeliveryEntryDialogComponent,
    DeliveryUpdateDialogComponent,
    DeliveryDeleteDialogComponent,
    DeliveryResultDialogComponent,
    BundleFileDropDialogComponent,
    UserDeleteDialogComponent,
    BannerDeleteDialogComponent,
    TemplateDeleteDialogComponent,
    ExcludeItemDeleteDialogComponent,
    SalesMgtUploadDialogComponent,
    NoticeDeleteDialogComponent,
    PageDeleteDialogComponent,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }