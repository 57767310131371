import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 * Mapping ListのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2019/07/15    新規作成
 */

@Injectable()
export class MappingListService extends AbstractService {
    constructor(private httpClient: HttpClient) {
      super();
    }

    getHistory() {
      const params = new HttpParams();
      return this.httpClient.get(`${this.API_URL}/rest/category-mapping/history`, { params: params });
    }

    uploadXls(file: File) {
      if (file && file[0]) {
        const formData: FormData = new FormData();
        formData.append('file', file[0]);
        const res = this.httpClient.post(`${this.API_URL}/rest/category-mapping/upload`, formData);
        return res;
      } else {
        throw new Error('Invalid Image');
      }
    }
}
