import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class TemplateUpdateService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public zip(form: FormData) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/template/zip`, form);
  }

  public show(id) {
    return this.httpClient.get(`${this.API_URL}/rest/cms/template/${id}`, {});
  }

  public create(form: FormData) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/template`, form);
  }
  public patch(id, form: FormData) {
    return this.httpClient.patch(`${this.API_URL}/rest/cms/template/${id}`, form);
  }

}
