import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PageDeleteDialogService } from './PageDeleteDialogService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'tage-delete-dialog',
  templateUrl: './PageDeleteDialog.html',
  styleUrls: ['./PageDeleteDialog.scss'],
  providers: []
})
export class PageDeleteDialogComponent extends AbstractComponent<any> {

  // コンストラクタ
  constructor(
    private sanitizer: DomSanitizer,
    private service: PageDeleteDialogService,
    private matDialogRef: MatDialogRef<PageDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public parameter: any,
    ) {
    super();
    if (parameter) {
      this.props.parameter = parameter;
    }
  }

  /** 書き出し */
  protected render() {
  }

  public delete() {
    this.loading = true;
    this.service.delete(this.props.parameter.tid).subscribe((res: any) => {
      this.loading = false;
      this.matDialogRef.close('SUCCESS');
    }, (err) => {
      this.loading = false;
      this.matDialogRef.close('FAILURE');
    });
  }

}
