import { Component, ViewChild } from '@angular/core';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CmsUtil } from '../../CmsUtil';


import { PageListService } from './PageListService';
import { PageDeleteDialogComponent } from '../delete-dialog/PageDeleteDialogComponent';

@Component({
  selector: 'page-list',
  templateUrl: './PageList.html',
  styleUrls: ['./PageList.scss'],
  providers: [],
})
export class PageListComponent extends AbstractComponent<any> {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private service: PageListService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    super();
  }


  protected render() {
    this.props.ecName = CmsUtil.getEcName(this.route.url['value'][0].path);
    const siteId = CmsUtil.getSiteId(this.route.url['value'][0].path);
    this.service.getList(siteId).subscribe((e: any) => {
      const result: any = [];
      for (let i = 0; i < e.length; i++) {
        result.push(Object.assign({ no : (i + 1) }, e[i]));
      }
      this.props.displayedColumns = [
        'no',
        'device_type_name',   
        'content_type_name',
        'content_name',
        'page_url',
        'note',
        'actions'
      ];
      this.props.dataSource = new MatTableDataSource<any[]>(result);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
    });
  }

  /** 登録/更新 */
  public update(type, element= {}) {
    const site = this.route.url['value'][0].path;
    this.router.navigate([`/cms/${site}/page/update/${ element['tid'] ? element['tid'] : 'create' }`, {}]);
  }

  /** 削除 */
  public delete(element) {
    const dialogRef = this.dialog.open(PageDeleteDialogComponent, {
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SUCCESS') {
        this.render();
      }
    }, error => {
      console.log(error);
    });
  }

  /*フィルター検索*/
  public applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
