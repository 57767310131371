import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class NoticeDeleteDialogService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  deleteNotice(tid) {
    console.log(tid)
    return this.httpClient.delete(`${this.API_URL}/rest/cms/notice/${tid}`, tid);
  }
}
