import { Component, ViewChild } from '@angular/core';
import { ShipmentReportService } from './ShipmentReportService';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource, MatDialogRef } from '@angular/material';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'zozo-shipmentReport',
    templateUrl: './ShipmentReport.html',
    styleUrls: ['./ShipmentReport.scss'],
    providers: []
})

export class ShipmentReportComponent extends AbstractComponent<any> {
	// View テーブル情報
	@ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(private service: ShipmentReportService,
	) {
		super();
		this.props = {
			dataSource: null,
		};
	}
	protected render() {
		this.loading = true;
    this.service.get().subscribe((res: any) => {
      this.props.displayedColumns = [
        'file_name',
        'item_count',
        'exec_status_name',
        'created_at',
      ];
			this.props.dataSource = new MatTableDataSource<any[]>(res);
			this.props.dataSource.sort = this.sort;
			this.props.dataSource.paginator = this.paginator;
			this.loading = false;
		}, (err) => {
			console.log(err);
			this.loading = false;
		});
	}

	/* フィルター検索 */
  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
