import { Component, ViewChild } from '@angular/core';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialogRef, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NoticeDeleteDialogComponent } from '../delete-dialog/NoticeDeleteDialogComponent';
import { NoticeListService } from './NoticeListService';
import * as moment from 'moment';
import { CmsUtil } from '../../CmsUtil';

@Component({
  selector: 'notice-list',
  templateUrl: './NoticeList.html',
  styleUrls: ['./NoticeList.scss'],
  providers: [],
})
export class NoticeListComponent extends AbstractComponent<any> {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public deleteDialogRef: MatDialogRef<NoticeDeleteDialogComponent>;
  public MatTableDataSource: MatTableDataSource<NoticeDeleteDialogComponent>;


  constructor(
    private service: NoticeListService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) {
    super();
  }

  protected render() {
    this.props.ecName = CmsUtil.getEcName(this.route.url['value'][0].path);
    const siteId = CmsUtil.getSiteId(this.route.url['value'][0].path);
    this.service.getList(siteId).subscribe((e: any) => {
      const result: any = [];
      for (let i = 0; i < e.length; i++) {
        result.push(Object.assign({ no : (i + 1) }, e[i]));
      }
      this.props.displayedColumns = [
        'no',
        'notice_title',
        'start_date',
        'end_date',
        'actions'
      ];
      this.props.dataSource = new MatTableDataSource<any[]>(result);
      console.log(this.props.dataSource);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
    });
  }


  /** 登録/更新 */
  public update(type, element= {}) {
    const site = this.route.url['value'][0].path;
    this.router.navigate([`/cms/${site}/notice/update/${ element['tid'] ? element['tid'] : 'create' }`, {}]);
  }

  /*お知らせ削除*/
  deleteNotice(element) {
    this.deleteDialogRef = this.dialog.open(NoticeDeleteDialogComponent, {
      data: { 'notice': element, parent: this }
    });
    this.deleteDialogRef.afterClosed().subscribe(result => {
      this.render();
    });
  }

  public dateFormat(date) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }

  /*フィルター検索*/
  public applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
