import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplateUpdateService } from './TemplateUpdateService';
import { ToastrService } from 'ngx-toastr';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { FileUploader } from 'ng2-file-upload';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { DateTimeAdapter } from 'ng-pick-datetime';


@Component({
  selector: 'template-update',
  templateUrl: './TemplateUpdate.html',
  styleUrls: ['./TemplateUpdate.scss'],
  providers: []
})

export class TemplateUpdateComponent extends AbstractComponent<any>  {
  public uploader: FileUploader;

  @ViewChild('file')
  fileInput;

  public props = {
    tid : -1,
    hasBaseDropZoneOver : false,
    form : null,
    file : null,
    thumbnail : null,
    templatePath : null,
    btnName : '登録',
    dirName : ''
  };

  // コンストラクタ
  constructor(
    private service: TemplateUpdateService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    super();
  }

  public render() {
    this.uploader = new FileUploader({});
    this.props.hasBaseDropZoneOver =  false;
    this.renderForm();

    this.loading = true;
    this.route.params.subscribe(param => {
      if (param.id == 'create') {
        this.loading = false;
      } else {
        this.props.btnName = "更新";
        this.props.tid = param.id;

        this.service.show(param.id).subscribe((res:any) => {
          this.props.form.controls['blockId'].setValue(res.block_id);
          this.props.form.controls['blockTitle'].setValue(res.block_title);
          this.props.templatePath = this.sanitizer.bypassSecurityTrustResourceUrl(res.template_url);
          let _dirNames = res.upload_dir_path.split('/');
          this.props.dirName = _dirNames[_dirNames.length -2];
          this.loading = false;
        })
      }
    });
  }

  public renderForm() {
    this.props.form = this.fb.group({
      blockId : new FormControl('', Validators.required),
      blockTitle: new FormControl('', Validators.required),
    });

  }

  public onSubmit() {
    if (this.props.form.invalid) {
      return;
    }
    this.loading = true;
    // upload パラメータ
    let data = new FormData();
    Object.keys(this.props.form.value).map(key => {
      data.append(key, this.props.form.value[key]);
    });
    if (this.props.tid == -1) {
      if (!this.props.file ) {
        alert('テンプレートを選択してください')
        this.loading = false;
        return;
      }
      data.append('file', this.props.file, this.props.file.name);
      this.service.create(data).subscribe(this.success.bind(this), this.error.bind(this));
    } else {
      if (this.props.file ) {
        data.append('file', this.props.file, this.props.file.name);
      }
      this.service.patch(this.props.tid, data).subscribe(this.success.bind(this), this.error.bind(this));
    }
  }

  private success() {
    this.loading = false;
    this.router.navigate(['/cms/template']);
  }

  private error(err) {
    this.loading = false;
    let errorMessage: string;
    errorMessage = typeof err.message === 'string' ? err.message : 'バナー登録に失敗しました。';
    this.toastr.error(errorMessage);
  }



  public fileOverBase(e:any):void {
    this.props.hasBaseDropZoneOver = e;
  }

  public clickFile() {
    console.log( this.fileInput);
    this.fileInput.nativeElement.click();
  }

  /** UPLOAD処理 */
  public upload(files) {
    this.uploadSelect({ target: { files } });
  }

  /**  */
  public uploadSelect(e) {
    if (e.target.files && e.target.files[0]) {
      this.props.file = e.target.files[0];
      let data = new FormData();
      data.append('file', this.props.file, this.props.file.name);
      this.service.zip(data).subscribe((e:any) => {
        this.props.templatePath = this.sanitizer.bypassSecurityTrustResourceUrl(e.path);
      },
      (e) => {
        console.log(e);
      });
    }
    return false;
  }
}
