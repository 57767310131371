import { RouterModule, Routes } from '@angular/router';
import { UserUpdateComponent } from '../admin/user/update/UserUpdateComponent';
import { UserEditComponent } from './edit/UserEditComponent';
import { UserPasswordComponent } from './password/UserPasswordComponent';
import { RoleGuardService } from '../RoleGuardService';
import { AuthService } from '../../../core/common/service/AuthService';

/** ルーティング設定 */
const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'admin/user/update', component: UserUpdateComponent, canActivate: [RoleGuardService] },
      { path: 'user/edit', component: UserEditComponent, canActivate: [AuthService] },
      { path: 'user/password', component: UserPasswordComponent, canActivate: [AuthService] }
    ]
  }
];
export const UserRoutingModule = RouterModule.forRoot(routes);
