import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { IndexService } from './IndexService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { CmsUtil } from '../CmsUtil';

@Component({
  selector: 'index',
  templateUrl: './Index.html',
  styleUrls: ['./Index.scss'],
  providers: []
})
export class IndexComponent extends AbstractComponent<any> {
  // コンストラクタ
  constructor(private service: IndexService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {
    super();
    this.props = {
    };
  }

  protected render() {
    this.props.ecName = CmsUtil.getEcName(this.route.url['value'][0].path);
    const siteId = CmsUtil.getSiteId(this.route.url['value'][0].path);
    this.props.form = new FormGroup({
      siteId: new FormControl(siteId),
      contentType: new FormControl('index'),
      newItemName: new FormControl(false),
      newItemPrice: new FormControl(true),
      newInventoryCount: new FormControl('1', [Validators.required, Validators.pattern('^[0-9]*$')]),
      newShowCount: new FormControl('6', [Validators.required, Validators.pattern('^[0-9]*$')]),
      saleItemName: new FormControl(false),
      saleItemPrice: new FormControl(true),
      saleInventoryCount: new FormControl('1', [Validators.required, Validators.pattern('^[0-9]*$')]),
      saleShowCount: new FormControl('6', [Validators.required, Validators.pattern('^[0-9]*$')]),
      rankingItemName: new FormControl(false),
      rankingItemPrice: new FormControl(true),
      rankingInventoryCount: new FormControl('1', [Validators.required, Validators.pattern('^[0-9]*$')]),
      rankingShowCount: new FormControl('6', [Validators.required, Validators.pattern('^[0-9]*$')]),
      recommendItemName: new FormControl(false),
      recommendItemPrice: new FormControl(true),
      recommendItems: new FormArray([]),
    });
    const recommendItems = this.props.form.get('recommendItems');
    for (let i = 0; i < 6; i++) {
      recommendItems.push(new FormControl(''));
    }

    this.loading = true;
    this.service.getContent(siteId).subscribe((res: any) => {
      if (_.get(res, 'content.contentType')) {
        for (let i = 0; i < res.content.recommendItems.length - 6; i++) {
          recommendItems.push(new FormControl(''));
        }

        this.props.form.patchValue({
          siteId: siteId,
          contentType: 'index',
          newItemName: res.content.newItemName,
          newItemPrice: res.content.newItemPrice,
          newInventoryCount: res.content.newInventoryCount,
          newShowCount: res.content.newShowCount,
          saleItemName: res.content.newItemName,
          saleItemPrice: res.content.newItemPrice,
          saleInventoryCount: res.content.newInventoryCount,
          saleShowCount: res.content.newShowCount,
          rankingItemName: res.content.rankingItemName,
          rankingItemPrice: res.content.rankingItemPrice,
          rankingInventoryCount: res.content.rankingInventoryCount,
          rankingShowCount: res.content.rankingShowCount,
          recommendItemName: res.content.recommendItemName,
          recommendItemPrice: res.content.recommendItemPrice,
          recommendItems: res.content.recommendItems,
        });
      }
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
  }

  get recommendItems(): FormArray { return this.props.form.get('recommendItems') as FormArray; }

  public onAddRecommend() {
    this.recommendItems.push(new FormControl(''));
  }
  public onDeleteRecommend(idx) {
    if (this.recommendItems.length > 1) {
      this.recommendItems.removeAt(idx);
    }
  }

  public onSubmit() {
    if (this.props.form.invalid) {
      return;
    }

    this.loading = true;
    this.service.postContent(this.props.form.value).subscribe(() => {
      this.loading = false;
      this.toastr.success('保存しました。');
      this.render();
    }, (err) => {
      this.loading = false;
      this.toastr.error('保存に失敗しました。');
      console.log(err);
    });
  }

  public onPreview() {
    this.loading = true;
    this.service.postPreview(this.props.form.value).subscribe((res: any) => {
      window.open(res.url, 'preview');
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
      const msg = (typeof err === 'string') ? err : '保存に失敗しました。';
      this.toastr.error(msg);
    });
  }

}
