import { RouterModule, Routes } from '@angular/router';
import { UserRoleGuardService } from '../UserRoleGuardService';
import { ItemMstListComponent } from './list/ItemMstListComponent';


/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'item-mst',
    children: [
      { path: 'magaseek', component: ItemMstListComponent, canActivate: [UserRoleGuardService] },
    ]
  }
];
export const ItemMstRoutingModule = RouterModule.forRoot(routes);
