import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MappingListService } from './MappingListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'mapping-list',
  templateUrl: './MappingList.html',
  styleUrls: ['./MappingList.scss'],
  providers: []
})
export class MappingListComponent extends AbstractComponent<any> {

  // View テーブル情報
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public file: any;

  // コンストラクタ
  constructor(private service: MappingListService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    super();
    this.props = {
      dataSource: null,
    };
  }

  // Override
  protected render() {
    this.props.form = new FormGroup({
      csv: new FormControl(null, [Validators.required])
    });

    this.loading = true;
    this.service.getHistory().subscribe((res: any) => {
      this.props.displayedColumns = ['file_name', 'created_at', 'created_by'];
      this.props.dataSource = new MatTableDataSource<any[]>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
  }

  /*フィルター検索*/
  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* csvファイルがuploadされた時 */
  onChange(files) {
    this.file = files;
  }

  /* xlsxファイルをupload */
  onSubmit() {
    this.loading = true;
    this.service.uploadXls(this.file).subscribe((res: any) => {
      this.render();
      this.toastr.success(res.message);
    }, (err) => {
      console.log(err);
      this.toastr.error((typeof err.message === 'string') ? err.message : err.message.join('<br>'));
      this.loading = false;
    });
  }

}
