import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from 'src/core/common/service/UserService';
import { AuthService } from '../../core/common/service/AuthService';

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(
    public userService: UserService,
    public authService: AuthService,
    private router: Router
  ) { }
  async canActivate() {
    const res: any = await this.userService.getUserMe().toPromise();
    if (this.authService.isAuthenticated() && res.role === 'Admin') {
      return true;
    }
    return false;
  }
}
