import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class HomeEditService extends AbstractService {
  constructor(private httpClient: HttpClient) {
      super();
  }

  getContent() {
    const params = new HttpParams()
      .set('contentName', 'home');
    return this.httpClient.get(`${this.API_URL}/rest/static-content`, { params: params });
  }

  postContent(formValue) {
    const body = {
      'contentName': 'home',
      'contentBody': formValue.content,
    };
    return this.httpClient.post(`${this.API_URL}/rest/static-content`, body);
  }
}
