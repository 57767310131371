import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class UserDeleteDialogService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  deleteUser(tid) {
    const params = new HttpParams().set('tid', tid);
    return this.httpClient.delete(`${this.API_URL}/rest/user`, { params });
  }
}
