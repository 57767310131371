import { OnInit, AfterViewInit, ElementRef } from '@angular/core';

/**
 *  <AbstractComponent>
 *
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/06/28 T.Utsunomiya   新規作成
 */
export abstract class  AbstractComponent<PropsType>　implements OnInit, AfterViewInit  {
  protected loading:Boolean = false;
  public props:PropsType;

  constructor(props?: PropsType) {
    if (!props) {
      let _props:any = {};
      this.props = _props;
    }
  }

  ngOnInit() {
    this.componentWillMount();
    this.render();
    window.scroll(0, 0);
  }
  ngAfterViewInit() {
    this.componentDidMount();
  }
  ngDestroy() {
    this.componentWillUnmount();
  }

  /** */
  protected componentWillMount() {}

  /** */
  protected componentWillUnmount() {}

  /** */
  protected render() {}

  /** */
  protected componentDidMount() {}
}
