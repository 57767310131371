
/**
 * CMSユーティリティ
 *
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付         内容
 *  1 2020/09/25  新規作成
 *
 */
export class CmsUtil {

  /**
   * ECサイト名称取得
   * @param siteName サイト名
   */
  static getEcName(siteName: string) {
    switch (siteName) {
      case 'rakuten':
        return '楽天';
      case 'paypay':
        return 'PayPay';
      case 'wowma':
        return 'Wowma!';
    }
    return '';
  }

  /**
   * ECサイトID取得
   * @param siteName サイト名
   */
  static getSiteId(siteName: string) {
    switch (siteName) {
      case 'rakuten':
        return '002';
      case 'paypay':
        return '014';
      case 'wowma':
        return '012';
    }
    return '';
  }

}
