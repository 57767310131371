import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';


/**
 * Project ListのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/__/__    新規作成
 */
@Injectable()
export class SkuDialogService extends AbstractService {
  constructor(private  httpClient:  HttpClient) {
    super();
  }

  getSku(itemId){
    const params = new HttpParams()
      .set('itemId', itemId)
    return this.httpClient.get(`${this.API_URL}/rest/item/getSku`, { params: params });
  }
}
