import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BannerUpdateService } from './BannerUpdateService';
import { ToastrService } from 'ngx-toastr';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { FileUploader } from 'ng2-file-upload';
import * as moment from 'moment';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { CmsUtil } from '../../CmsUtil';

@Component({
  selector: 'banner-update',
  templateUrl: './BannerUpdate.html',
  styleUrls: ['./BannerUpdate.scss'],
  providers: []
})

export class BannerUpdateComponent extends AbstractComponent<any>  {
  public uploader: FileUploader;

  public props = {
    ecName: '',
    tid : -1,
    hasBaseDropZoneOver : false,
    form : null,
    selectBannerTypeRequired : false,
    file : null,
    thumbnail : null,
    btnName : '登録',
  };

  // コンストラクタ
  constructor(
    private service: BannerUpdateService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private fb: FormBuilder
  ) {
    super();
    dateTimeAdapter.setLocale('ja-JP');

  }

  public render() {
    this.uploader = new FileUploader({});
    this.props.hasBaseDropZoneOver =  false;
    this.props.ecName = CmsUtil.getEcName(this.route.url['value'][0].path);
    this.renderForm();

    this.loading = true;
    this.route.params.subscribe(param => {
      if (param.id === 'create') {
        this.loading = false;

      } else {
        this.props.btnName = '更新';
        this.props.tid = param.id;
        this.service.show(param.id).subscribe((res: any) => {
          this.props.form.controls['selectBannerType'].setValue(res.banner_type);
          this.props.form.controls['title'].setValue(res.banner_title);
          this.props.form.controls['url'].setValue(res.banner_url);
          this.props.form.controls['startDate'].setValue(res.start_date);
          this.props.form.controls['endDate'].setValue(res.end_date);
          this.props.form.controls['displayOrder'].setValue(res.display_order);
          this.props.thumbnail = res.image_url;
          this.loading = false;
        });
      }
    });
  }

  public renderForm() {
    const siteId = CmsUtil.getSiteId(this.route.url['value'][0].path);
    this.props.form = this.fb.group({
      siteId: new FormControl(siteId),
      selectBannerType : new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      url: new FormControl('', Validators.required),
      startDate : new FormControl('', Validators.required),
      endDate : new FormControl('', Validators.required),
      displayOrder : new FormControl('', Validators.required)
    });
  }

  public onSubmit() {
    this.props.selectBannerTypeRequired = false;
    if (this.props.form.controls.selectBannerType.value === '' ||
        this.props.form.controls.selectBannerType.value === '-1') {
      this.props.selectBannerTypeRequired = true;
    }
    if (this.props.form.invalid) {
      return;
    }
    const startDate = moment(this.props.form.get('startDate').value).format('YYYYMMDDHHmm');
    const endDate = moment(this.props.form.get('endDate').value).format('YYYYMMDDHHmm');
    if ( startDate >= endDate ) {
      this.toastr.error('公開開始日時は公開終了日時より前を選択してください');
      return;
    }
    const data = new FormData();
    Object.keys(this.props.form.value).map(key => {
      data.append(key, this.props.form.value[key]);
    });
    this.loading = true;
    if (this.props.tid === -1) {
      if (!this.props.file ) {
        alert('バナー画像を選択してください');
        this.loading = false;
        return;
      }
      // create
      data.append('file', this.props.file, this.props.file.name);
      this.service.create(data).subscribe(this.success.bind(this), this.error.bind(this));
    } else {
      data.append('tid', String(this.props.tid));
      if (this.props.file ) {
        data.append('file', this.props.file, this.props.file.name);
      }
      // update
      this.service.patch(this.props.tid, data).subscribe(this.success.bind(this), this.error.bind(this));
    }
    // 登録の場合
  }

  private success() {
    this.loading = false;
    const site = this.route.url['value'][0].path;
    this.router.navigate([`/cms/${site}/banner`]);
  }
  private error(err) {
    this.loading = false;
    let errorMessage: string;
    errorMessage = typeof err.message === 'string' ? err.message : 'バナー登録に失敗しました。';
    this.toastr.error(errorMessage);
  }


  public fileOverBase(e: any): void {
    this.props.hasBaseDropZoneOver = e;
  }

  /** UPLOAD処理 */
  public upload(files) {
    this.uploadSelect({ target: { files } });
  }

  /**  */
  public uploadSelect(e) {
    if (e.target.files && e.target.files[0]) {
      this.props.file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.props.file);
      reader.onload = (event: any) => {
        this.props.thumbnail = event.target.result;
      };
    }
    return false;
  }


}
