import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 * Project ListのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/__/__    新規作成
 */

@Injectable()
export class NewListService extends AbstractService {
    constructor(private httpClient: HttpClient) {
      super();
    }

    getConvHistory() {
      const params = new HttpParams()
        .set('category', 'new');
      return this.httpClient.get(`${this.API_URL}/rest/item/conv-history`, { params: params });
    }

    uploadCsv(file: File) {
      if (file && file[0]) {
        const formData: FormData = new FormData();
        formData.append('file', file[0]);
        const res = this.httpClient.post(`${this.API_URL}/rest/item/new`, formData);
        return res;
      } else {
        throw new Error('Invalid Image');
      }
    }

    downloadCsv(convertedFileName, dirPath) {
      const zipPath = dirPath + '/' + convertedFileName;
      return this.httpClient.get(`${this.API_URL}/rest/${zipPath}`, { responseType: 'blob' });
    }
}
