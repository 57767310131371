import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class JanCsvService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  uploadCsv(file: File) {
    if (file && file[0]) {
      const formData: FormData = new FormData();
      formData.append('file', file[0]);
      const res = this.httpClient.post(`${this.API_URL}/rest/shoplist/janCsv/convert`, formData);
      return res;
    } else {
      throw new Error('Invalid file');
    }
  }

  getList() {
    return this.httpClient.get(`${this.API_URL}/rest/shoplist/janCsv/list`);
  }

  downloadCsv(path) {
    const params = new HttpParams().set('dirPath', path);
    return this.httpClient.get(`${this.API_URL}/rest/shoplist/janCsv/csv`, { params: params, responseType: 'blob' });
  }
}