import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class TemplateListService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getList() {
    return this.httpClient.get(`${this.API_URL}/rest/cms/template`, { params: {} });
  }
}
