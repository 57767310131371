import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 * Project ListのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/__/__    新規作成
 */
@Injectable()
export class ExcludeItemDeleteDialogService extends AbstractService {
  constructor(private  httpClient:  HttpClient) {
    super();
  }

  deleteExcludeItem(tid) {
    console.log(tid);
    const params = new HttpParams()
      .set('tid', tid);
    return this.httpClient.delete(`${this.API_URL}/rest/item/deleteExcludeItem`, { params });
  }

}
