import { Component, ViewChild, Inject, ViewEncapsulation } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MatSort, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ExcludeListService } from './ExcludeListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { Router, ActivatedRoute } from '@angular/router';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { ToastrService } from 'ngx-toastr';
import { SkuDialogComponent} from './sku-dialog/SkuDialogComponent';
import { ExcludeItemDeleteDialogComponent} from './delete-dialog/ExcludeItemDeleteDialogComponent';
import * as moment from 'moment';
import { User } from 'src/core/common/model/UserModel';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { locateHostElement } from '@angular/core/src/render3/instructions';

/**
 * テンプレート一覧画面
 *  <Controller>
 *
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/06/28 T.Utsunomiya   新規作成
 */
@Component({
  selector: 'exclude_list',
  templateUrl: './ExcludeList.html',
  styleUrls: ['./ExcludeList.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class ExcludeListComponent extends AbstractComponent<any> {

  // private templateList = [];
  // View テーブル情報
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public skuDialogRef: MatDialogRef<SkuDialogComponent>;
  public deleteDialogRef: MatDialogRef<ExcludeItemDeleteDialogComponent>;

  // コンストラクタ
  constructor(
    private service: ExcludeListService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public toastr: ToastrService
  ){
    super();
    this.props = {
      proposition: {},
      project: {},
      dataSource: null,
    };
  }
  public form;

  // Override
  protected render() {
    this.loading = true;
    this.service.getExcludeItems().subscribe((res: any) => {
      this.props.displayedColumns = ['no', 'image', 'brand_name', 'item_number', 'item_name', 'made_date',
                                      'created_at', 'created_by', 'actions'];
      const result = res.map((data, no) => {
        no += 1;
        return {
          no,
          image: data.thumb || '/static/images/noimage.png',
          brand_name: data.brand_name || '-',
          item_id: data.item_id,
          item_number: data.item_id + '\n' + (data.item_number || '-') + '\n' + (data.item_number2 || '-'),
          item_name: data.item_name || '-',
          made_date: data.product_date || '-',
          created_at: data.created_at,
          created_by: data.created_by || '-',
          tid: data.tid,
        }
      });
      this.props.dataSource = new MatTableDataSource<any[]>(result);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
    this.form = new FormGroup({
      itemId: new FormControl(null, [Validators.required])
    });
  }

  onSubmit() {
    const itemId = this.form.value.itemId;
    this.service.checkIfExistsItem(itemId).subscribe((res:any) => {
      if (res) {
        this.service.postExcludeItem(itemId).subscribe((res:any) =>{
          this.toastr.success('登録が完了しました。');
          this.render();
        })
      } else {
        this.toastr.error('登録出来ませんでした。');
      }
    });
  }

  sku(element) {
    this.skuDialogRef = this.dialog.open(SkuDialogComponent, {
      data: { data: element, parent: this }
    });
    this.skuDialogRef.afterClosed().subscribe(result => {
      this.render();
    });
  }

  deleteExcludeItem(element) {
    this.deleteDialogRef = this.dialog.open(ExcludeItemDeleteDialogComponent, {
      data: { data: element, parent: this }
    });
    this.deleteDialogRef.afterClosed().subscribe(result => {
      this.render();
    });
  }

  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
