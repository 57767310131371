import { Component, ViewChild } from '@angular/core';
import { MovedataListService } from './MovedataListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'movedata-list',
  templateUrl: './MovedataList.html',
  styleUrls: ['./MovedataList.scss'],
  providers: [],
})
export class MovedataListComponent extends AbstractComponent<any> {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public form;
  public file;
  public title: string;
  public linkFileType = this.route.url['value'][0].path;
  public registerFlg = false;

  constructor(
    private service: MovedataListService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    super();
  }
  protected render() {
    this.linkFileType = this.route.url['value'][0].path;
    this.title = this.getTitle();
    this.form = new FormGroup({
      csv: new FormControl(null, [Validators.required])
    });

    this.service.getList(this.linkFileType).subscribe((res: any) => {
      this.props.displayedColumns =
        ['converted_file_name', 'converted_file_line_num',
        'pre_converted_file_name', 'pre_converted_file_line_num'];

      this.props.displayedColumns.push('created_at', 'created_by');

      this.props.dataSource = new MatTableDataSource<any>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
    });

  }

  getTitle() {
    switch (this.linkFileType) {
      case 'kumai':
        return '熊井倉庫';
    }
  }

  upload(file: File[]) {
    this.file = file;
  }

  onSubmit() {
    this.registerFlg = true;
    this.service.uploadCsv(this.file, this.linkFileType, this.form.value).subscribe((res: any) => {
      this.toastr.success('変換が完了しました');
      this.render();
      this.registerFlg = false;
    }, (err) => {
      console.log(err);
      this.toastr.error((err.message) ? err.message : '変換に失敗しました');
      this.registerFlg = false;
    });
  }

  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
