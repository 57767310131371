import { Component } from '@angular/core';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import { YahooService } from './YahooService';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'yahoo',
    templateUrl: './Yahoo.html',
    styleUrls: ['./Yahoo.scss'],
    providers: []
})
export class YahooComponent extends AbstractComponent<any> {

  // コンストラクタ
  constructor(private service: YahooService,
    private toastr: ToastrService) {
    super();
    this.props = {
      registerFlg: false,
    };
  }

  protected render() {
    this.props.form = new FormGroup({
      siteId: new FormControl('014'),
      shopId: new FormControl(null, [Validators.required]),
      ftpHost: new FormControl(null, [Validators.required]),
      ftpPort: new FormControl(null, [Validators.required]),
      ftpUser: new FormControl(null, [Validators.required]),
      ftpPassword: new FormControl(null, [Validators.required]),
      cmsDirectory: new FormControl(null, [Validators.required]),
      apiKey: new FormControl(null, [Validators.required]),
      apiSecret: new FormControl(null, [Validators.required]),
      taxFraction: new FormControl(null, [Validators.required]),
      expiryDate: new FormControl(null),
    });

    this.loading = true;
    this.service.getContent().subscribe((res: any) => {
      if (res) {
        this.props.form.patchValue({
          siteId: res.site_id,
          shopId: res.shop_id,
          ftpHost: res.ftp_host,
          ftpPort: res.ftp_port,
          ftpUser: res.ftp_user,
          ftpPassword: res.ftp_password,
          cmsDirectory: res.cms_directory,
          apiKey: res.api_key,
          apiSecret: res.api_secret,
          taxFraction: String(res.tax_fraction),
          expiryDate: (res.expiry_date) ? moment(res.expiry_date).format('YYYY-MM-DD HH:mm:ss') : '',
        });
      }
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
  }

  onSubmit() {
    this.loading = true;
    this.props.registerFlg = true;
    this.service.postContent(this.props.form.value).subscribe((res: any) => {
      this.render();
      this.props.registerFlg = false;
      this.loading = false;
      this.toastr.success('更新しました');
    }, (err) => {
      console.log(err);
      this.toastr.error((err.message) ? err.message : '更新に失敗しました');
      this.props.registerFlg = false;
      this.loading = false;
    });
  }

  onYahooAuth() {
    if (!this.props.form.get('apiKey').value) {
      this.toastr.error('アプリケーションIDは必須入力です。');
      return;
    }
    this.loading = true;

    const values = {
      apiKey: this.props.form.get('apiKey').value,
      apiSecret: this.props.form.get('apiSecret').value,
    };

    this.service.postYahooAuth(values).subscribe((res: any) => {
      if (res.location) {
        document.location.href = res.location;
      }
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.toastr.error((err.message && typeof err.message === 'string') ? err.message : '更新に失敗しました');
      this.loading = false;
    });

  }

}
