import { RouterModule, Routes } from '@angular/router';
import { AuthService } from '../../core/common/service/AuthService';
import { LoginComponent } from './login/LoginComponent';
import { HomeComponent } from './home/HomeComponent';
import { UserRoleGuardService } from './UserRoleGuardService';

/** ルーティング設定 */
const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    canActivate: [UserRoleGuardService]
  }, // 権限チェック
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
];

export const AppRoutingModule = RouterModule.forRoot(routes);
