import { Component, ViewChild } from '@angular/core';
import { SalesdataListService } from './SalesdataListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'salesdata-list',
  templateUrl: './SalesdataList.html',
  styleUrls: ['./SalesdataList.scss'],
  providers: [],
})
export class SalesdataListComponent extends AbstractComponent<any> {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public form;
  public file;
  public title: string;
  public linkFileType = this.route.url['value'][0].path;
  public registerFlg = false;
  public isSelectedDate = false;
  public isWarehouseName = false;

  constructor(
    private service: SalesdataListService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    super();
  }
  protected render() {
    this.linkFileType = this.route.url['value'][0].path;
    this.title = this.getTitle();

    if (this.linkFileType === 'shoplist' || this.linkFileType === 'fashionwalker' ||
      this.linkFileType === 'flagshop') {
      this.isSelectedDate = true;
      this.isWarehouseName = false;
      this.form = new FormGroup({
        selectedDate: new FormControl(null, [Validators.required]),
        warehouseName: new FormControl(),
        csv: new FormControl(null, [Validators.required])
      });
    } else if (this.linkFileType === 'rakuten') {
      this.isSelectedDate = true;
      this.isWarehouseName = true;
      this.form = new FormGroup({
        selectedDate: new FormControl(null, [Validators.required]),
        warehouseName: new FormControl(null, [Validators.required]),
        csv: new FormControl(null, [Validators.required])
      });
    } else {
      this.isSelectedDate = false;
      this.isWarehouseName = false;
      this.form = new FormGroup({
        selectedDate: new FormControl(),
        warehouseName: new FormControl(),
        csv: new FormControl(null, [Validators.required])
      });
    }

    this.service.getList(this.linkFileType).subscribe((res: any) => {
      this.props.displayedColumns =
        ['converted_file_name', 'converted_file_line_num',
        'pre_converted_file_name', 'pre_converted_file_line_num'];

      if (this.linkFileType === 'shoplist' || this.linkFileType === 'fashionwalker' ||
            this.linkFileType ===  'flagshop') {
        this.props.displayedColumns.push('selected_date');
      } else if (this.linkFileType === 'rakuten') {
        this.props.displayedColumns.push('selected_date');
        this.props.displayedColumns.push('warehouse_name');
      }
      this.props.displayedColumns.push('created_at', 'created_by');

      this.props.dataSource = new MatTableDataSource<any>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
    });

  }

  getTitle() {
    switch (this.linkFileType) {
      case 'shoplist':
        return 'SHOPLIST';
      case 'marui':
        return 'マルイ';
      case 'zozo':
        return 'ZOZO';
      case 'fashionwalker':
        return 'ファッションウォーカー';
      case 'locondo':
        return 'ロコンド';
      case 'rakuten':
        return 'Rakuten Fashion';
      case 'magaseek':
        return 'MAGASEEK';
      case 'flagshop':
        return 'FLAG SHOP';
      case 'mitsui':
        return '三井 ＆モール';
      case 'amazon':
        return 'Amazon';
      case 'ryuryumall':
        return 'RyuRyumall';
      default:
        return '';
    }
  }

  upload(file: File[]) {
    this.file = file;
  }

  onSubmit() {
    this.registerFlg = true;
    this.service.uploadCsv(this.file, this.linkFileType, this.form.value).subscribe((res: any) => {
      this.toastr.success('変換が完了しました');
      this.render();
      this.registerFlg = false;
    }, (err) => {
      console.log(err);
      this.toastr.error((err.message) ? err.message : '変換に失敗しました');
      this.registerFlg = false;
    });
  }

  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
