import { RouterModule, Routes } from '@angular/router';
import { HomeEditComponent } from './edit/HomeEditComponent';
import { RoleGuardService } from '../../RoleGuardService';

/** ルーティング設定 */
const routes: Routes = [
    {
        path: 'admin/home',
        children: [
          { path: 'edit', component: HomeEditComponent, canActivate: [RoleGuardService] },
        ]
    }
];
export const HomeRoutingModule = RouterModule.forRoot(routes);
