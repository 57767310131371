import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class PageDeleteDialogService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  delete(tid) {
    return this.httpClient.delete(`${this.API_URL}/rest/cms/page/${tid}`, tid);
  }
}
