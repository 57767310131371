// 参考：http://neos21.hatenablog.com/entry/2017/11/30/080000

import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * ファイルをドラッグ & ドロップで取得するためのディレクティブ
 */
@Directive({
  selector: '[appFileDrop]'
})
export class FileDropDirective {

  constructor(private el: ElementRef) {
    el.nativeElement.style.backgroundColor = '#ffffff';
  }

  @Output()
  public onFileDrop: EventEmitter<File[]> = new EventEmitter<File[]>();
  
  /**
   * ファイルが要素にドラッグされて重なった時のイベント
   */
  @HostListener('dragover', ['$event'])
  public onDragOver(e: any): void {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
    this.el.nativeElement.style.backgroundColor = '#c0c0c0';
  }

  /**
   * ファイルが要素から離れた時のイベント
   */
  @HostListener('dragleave', ['$event'])
  public onDragLeave(e: any): void {
    this.el.nativeElement.style.backgroundColor = '#ffffff';
  }
  
  /**
   * ファイルドロップ時のイベント
   * 取得したファイルを引数に onFileDrop イベントを発火させる
   */
  @HostListener('drop', ['$event'])
  public onDrop(e: any): void {
    e.preventDefault();
    this.el.nativeElement.style.backgroundColor = '#ffffff';
    this.onFileDrop.emit(e.dataTransfer.files);
  }
}
