import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 * Project ListのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2020/05/22    新規作成
 */

@Injectable()
export class SalesMgtUploadDialogService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  post(form: FormData) {
    return this.httpClient.post(`${this.API_URL}/rest/sales-mgt/list`, form);
  }
}
