import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { DeliveryResultDialogComponent } from './delivery-result-dialog/DeliveryResultDialogComponent';
import { DeliveryResultService } from './DeliveryResultService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'delivery-result',
  templateUrl: './DeliveryResult.html',
  styleUrls: ['./DeliveryResult.scss'],
  providers: []
})
export class DeliveryResultComponent extends AbstractComponent<any> {
  public file: File;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public displayDialogRef: MatDialogRef<DeliveryResultDialogComponent>;

  // コンストラクタ
  constructor(
    private service: DeliveryResultService,
    private dialog: MatDialog,
    private toastr: ToastrService) {
    super();
  }

  protected render() {
    this.service.getHist().subscribe((res: any) => {
      this.props.displayedColumns = ['no', 'fileName', 'actions'];
      const result = res.map((data, no) => {
        no += 1;
        return {
          no,
          fileName: data.fileName,
          dirId: data.dirId,
        }
      });
      this.props.dataSource = new MatTableDataSource<any>(result);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
    });
  }

  display(fileName, dirId) {
    this.displayDialogRef = this.dialog.open(DeliveryResultDialogComponent, {
      data: { 'name': fileName, 'dirId': dirId, parent: this },
      height: '80%',
      width: '90%',
    });
    this.displayDialogRef.afterClosed().subscribe(result => {
      this.render();
    });
  }

  download(fileName, dirId) {
    this.service.downloadCsv(fileName, dirId).subscribe((res: any) => {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(res, fileName);
      } else {
        const objectURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = objectURL;
        link.download = fileName;
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  // ファイルが選択された時
  onChange(files) {
    this.file = files;
  }

  // csvファイルアップロード
  uploadCsv() {
    this.loading = true;
    try {
      if(!this.file) {
        this.loading = false;
        return;
      }
      this.service.uploadMovementResultsCsv(this.file).subscribe((res: any) => {
        this.loading = false;
        this.toastr.success('アップロードが完了しました');
        this.render();
        this.file = null;
        let file = document.getElementById('upfile') as HTMLInputElement;;
        file.value = '';
      }, (err) => {
        this.toastr.error('アップロードに失敗しました');
        this.loading = false;
        this.file = null;
        let file = document.getElementById('upfile') as HTMLInputElement;;
        file.value = '';
      });
    } catch (err) {
      console.log(err);
      this.toastr.error('アップロードに失敗しました');
      this.loading = false;
      this.file = null;
      let file = document.getElementById('upfile') as HTMLInputElement;;
      file.value = '';
    }
  }

  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
