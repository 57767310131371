import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class WowmaService extends AbstractService {
  constructor(private httpClient: HttpClient) {
      super();
  }

  getContent() {
    return this.httpClient.get(`${this.API_URL}/rest/cms/setting/012`);
  }

  postContent(formValue) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/setting`, formValue);
  }
}
