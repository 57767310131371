import { Component, ViewChild } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { DeliveryLogService } from './DeliveryLogService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'delivery-log',
  templateUrl: './DeliveryLog.html',
  styleUrls: ['./DeliveryLog.scss'],
  providers: []
})
export class DeliveryLogComponent extends AbstractComponent<any> {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // コンストラクタ
  constructor(
    private service: DeliveryLogService,
    private toastr: ToastrService) {
    super();
  }

  protected render() {
    this.loading = true;
    this.service.getLog().subscribe((res: any) => {
      this.props.displayedColumns = ['warehouseId', 'inputDate', 'actions'];
      this.props.dataSource = new MatTableDataSource<any>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
      this.loading = false;
    });
  }

  download(warehouseId, inputDate) {
    this.loading = true;
    this.service.downloadCsv(warehouseId, inputDate).subscribe((res: any) => {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(res, inputDate);
      } else {
        const objectURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = objectURL;
        link.download = `${warehouseId}_${inputDate}.csv`;
        link.click();
        document.body.removeChild(link);
        this.loading = false;
      }
    }, () => {
      this.loading = false;
    });
  }

  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
