import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { UserEditService } from './UserEditService';
import { Router } from '@angular/router';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'user-edit',
  templateUrl: './UserEdit.html',
  styleUrls: ['./UserEdit.scss'],
  providers: []
})

export class UserEditComponent extends AbstractComponent<any>  {
  private id: number;
  private name: string;
  private email: string;

  constructor(private formBuilder: FormBuilder,
    private service: UserEditService,
    private router: Router,
    private toastr: ToastrService,
  ) {
    super();
  }

  public render() {
    this.service.getUserMe().subscribe((res: any) => {
      this.id = res.tid;
      this.props.form = this.formBuilder.group({
        name: new FormControl(res.userName),
        email: new FormControl(res.email),
      });
    }, err => {
      this.router.navigate([`/login`]);
    });
    this.props.form = this.formBuilder.group({
      name: new FormControl(this.name),
      email: new FormControl(this.email),
    });
  }

  onSubmit() {
    if (this.props.form.invalid) {
      return;
    }
    this.service.updateUser(
      this.id,
      this.props.form.controls.name.value,
      this.props.form.controls.email.value,
    ).subscribe(() => {
      this.toastr.success('更新が完了しました');
    }, err => {
      this.toastr.error(err.message);
    });
  }
}
