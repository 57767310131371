import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { RankingService } from './RankingService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { CmsUtil } from '../CmsUtil';

@Component({
  selector: 'ranking',
  templateUrl: './Ranking.html',
  styleUrls: ['./Ranking.scss'],
  providers: []
})
export class RankingComponent extends AbstractComponent<any> {
  // コンストラクタ
  constructor(private service: RankingService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {
    super();
    this.props = {
      brands: [],
      categories: [],
      targets: [],
      conditionTypes: [
        {
          id: '1',
          title: 'ブランド指定',
        },
        {
          id: '2',
          title: '商品管理番号指定',
        },
      ],
      weeklySelectedTargetIdx: -1,
      weeklySelectedCategoryIdx: -1,
      monthlySelectedTargetIdx: -1,
      monthlySelectedCategoryIdx: -1,
    };
  }

  protected render() {
    this.props.ecName = CmsUtil.getEcName(this.route.url['value'][0].path);
    const siteId = CmsUtil.getSiteId(this.route.url['value'][0].path);
    this.props.form = new FormGroup({
      siteId: new FormControl(siteId),
      contentType: new FormControl('item_ranking'),
      rankingTargets: new FormArray([]),
      rankingCategories: new FormArray([]),
      weeklyRankingItemName: new FormControl(false),
      weeklyRankingItemPrice: new FormControl(true),
      weeklyRankingInventoryCount: new FormControl('1', [Validators.required, Validators.pattern('^[0-9]*$')]),
      weeklyRankingSelectTarget: new FormControl(''),
      weeklyRankingSelectCategory: new FormControl(''),
      weeklyRankingConfig: new FormArray([new FormArray([this.initCategorySettings()])]),
      monthlyRankingItemName: new FormControl(false),
      monthlyRankingItemPrice: new FormControl(true),
      monthlyRankingInventoryCount: new FormControl('1', [Validators.required, Validators.pattern('^[0-9]*$')]),
      monthlyRankingSelectTarget: new FormControl(''),
      monthlyRankingSelectCategory: new FormControl(''),
      monthlyRankingConfig: new FormArray([new FormArray([this.initCategorySettings()])]),
    });
    this.rankingTargets.push(new FormControl('', Validators.required));
    this.rankingCategories.push(new FormControl('', Validators.required));
    this.props.form.get('weeklyRankingSelectTarget').valueChanges.subscribe((value) => {
      this.props.weeklySelectedTargetIdx = this.rankingTargets.value.findIndex(val => val === value);
    });
    this.props.form.get('weeklyRankingSelectCategory').valueChanges.subscribe((value) => {
      this.props.weeklySelectedCategoryIdx = this.rankingCategories.value.findIndex(val => val === value);
    });
    this.props.form.get('monthlyRankingSelectTarget').valueChanges.subscribe((value) => {
      this.props.monthlySelectedTargetIdx = this.rankingTargets.value.findIndex(val => val === value);
    });
    this.props.form.get('monthlyRankingSelectCategory').valueChanges.subscribe((value) => {
      this.props.monthlySelectedCategoryIdx = this.rankingCategories.value.findIndex(val => val === value);
    });

    this.loading = true;
    this.service.getContent(siteId).subscribe((res: any) => {
      if (_.get(res, 'content.contentType')) {
        for (let i = 0; i < res.content.rankingTargets.length - 1; i++) {
          this.rankingTargets.push(new FormControl('', Validators.required));
        }
        for (let i = 0; i < res.content.rankingCategories.length - 1; i++) {
          this.rankingCategories.push(new FormControl('', Validators.required));
        }
        this.props.form.patchValue({
          siteId: siteId,
          contentType: 'item_ranking',
          rankingTargets: res.content.rankingTargets,
          rankingCategories: res.content.rankingCategories,
          weeklyRankingItemName: res.content.weeklyRankingItemName,
          weeklyRankingItemPrice: res.content.weeklyRankingItemPrice,
          weeklyRankingInventoryCount: res.content.weeklyRankingInventoryCount,
          weeklyRankingSelectTarget: res.content.weeklyRankingSelectTarget,
          weeklyRankingSelectCategory: res.content.weeklyRankingSelectCategory,
          weeklyRankingConfig: this.patchSettings(this.weeklyRankingConfig, res.content.weeklyRankingConfig),
          monthlyRankingItemName: res.content.monthlyRankingItemName,
          monthlyRankingItemPrice: res.content.monthlyRankingItemPrice,
          monthlyRankingInventoryCount: res.content.monthlyRankingInventoryCount,
          monthlyRankingSelectTarget: res.content.monthlyRankingSelectTarget,
          monthlyRankingSelectCategory: res.content.monthlyRankingSelectCategory,
          monthlyRankingConfig: this.patchSettings(this.monthlyRankingConfig, res.content.monthlyRankingConfig),
        });
      }
      this.props.brands = res.brands;
      this.props.categories = res.categories;
      this.props.targets = res.targets;
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
  }

  get rankingTargets(): FormArray { return this.props.form.get('rankingTargets') as FormArray; }
  get rankingCategories(): FormArray { return this.props.form.get('rankingCategories') as FormArray; }
  get weeklyRankingConfig(): FormArray { return this.props.form.get('weeklyRankingConfig') as FormArray; }
  get monthlyRankingConfig(): FormArray { return this.props.form.get('monthlyRankingConfig') as FormArray; }

  public getWeeklyRankingItemFixed(i, j) {
    return (<FormGroup>(<FormArray>this.weeklyRankingConfig.at(i)).at(j)).get('rankingItemFixed').value;
  }
  public getWeeklyRankingTypes(i, j): FormArray {
    return (<FormGroup>(<FormArray>this.weeklyRankingConfig.at(i)).at(j)).get('rankingTypes') as FormArray;
  }
  public getMonthlyRankingItemFixed(i, j) {
    return (<FormGroup>(<FormArray>this.monthlyRankingConfig.at(i)).at(j)).get('rankingItemFixed').value;
  }
  public getMonthlyRankingTypes(i, j): FormArray {
    return (<FormGroup>(<FormArray>this.monthlyRankingConfig.at(i)).at(j)).get('rankingTypes') as FormArray;
  }

  public patchSettings(config, rankingSettings) {
    for (let i = 0; i < rankingSettings.length; i++) {
      if (i > 0) {
        config.push(new FormArray([this.initCategorySettings()]));
      }
      for (let j = 1; j < rankingSettings[i].length; j++) {
        (<FormArray>config.at(i)).push(this.initCategorySettings());
      }
    }
    return rankingSettings;
  }

  public initCategorySettings() {
    const group = {};
    group[`rankingItemFixed`] = new FormControl('1');
    group[`rankingTypes`] = new FormArray([]);
    group[`rankingBrands`] = new FormArray([]);
    group[`rankingItems`] = new FormArray([]);
    group[`rankingShowCount`] = new FormControl('10');
    const grp = new FormGroup(group);

    for (let i = 0; i < 10; i++) {
      (<FormArray>grp.get(`rankingTypes`)).push(new FormControl('1'));
      (<FormArray>grp.get(`rankingBrands`)).push(new FormControl(''));
      (<FormArray>grp.get(`rankingItems`)).push(new FormControl(''));
    }
    return grp;
  }

  public onAddTarget() {
    this.rankingTargets.push(new FormControl('', Validators.required));
    this.weeklyRankingConfig.push(new FormArray([]));
    const idx = this.weeklyRankingConfig.length - 1;
    for (let i = 0; i < this.rankingCategories.length; i++) {
      (<FormArray>this.weeklyRankingConfig.at(idx)).push(this.initCategorySettings());
    }
    this.monthlyRankingConfig.push(new FormArray([]));
    const idx2 = this.monthlyRankingConfig.length - 1;
    for (let i = 0; i < this.rankingCategories.length; i++) {
      (<FormArray>this.monthlyRankingConfig.at(idx2)).push(this.initCategorySettings());
    }
  }
  public onDeleteTarget(idx) {
    if (this.rankingTargets.length > 1) {
      this.rankingTargets.removeAt(idx);
      this.weeklyRankingConfig.removeAt(idx);
      this.monthlyRankingConfig.removeAt(idx);
    }
  }
  public onAddCategory() {
    this.rankingCategories.push(new FormControl('', Validators.required));
    for (let i = 0; i < this.weeklyRankingConfig.length; i++) {
      (<FormArray>this.weeklyRankingConfig.at(i)).push(this.initCategorySettings());
    }
    for (let i = 0; i < this.monthlyRankingConfig.length; i++) {
      (<FormArray>this.monthlyRankingConfig.at(i)).push(this.initCategorySettings());
    }
  }
  public onDeleteCategory(idx) {
    if (this.rankingCategories.length > 1) {
      this.rankingCategories.removeAt(idx);
      for (let i = 0; i < this.rankingTargets.length; i++) {
        (<FormArray>this.weeklyRankingConfig.at(i)).removeAt(idx);
        (<FormArray>this.monthlyRankingConfig.at(i)).removeAt(idx);
      }
    }
  }

  public showWeeklyConfig(i, j) {
    return this.props.weeklySelectedTargetIdx === i && this.props.weeklySelectedCategoryIdx === j;
  }
  public showMonthlyConfig(i, j) {
    return this.props.monthlySelectedTargetIdx === i && this.props.monthlySelectedCategoryIdx === j;
  }

  public getTargetTitle(id) {
    const ret = this.props.targets.find(e => e.targetCode === id);
    return _.get(ret, 'targetTitle');
  }
  public getCategoryTitle(id) {
    const ret = this.props.categories.find(e => e.categoryId === id);
    return _.get(ret, 'categoryTitle');
  }

  public onSubmit() {
    if (this.props.form.invalid) {
      return;
    }

    this.loading = true;
    this.service.postContent(this.props.form.value).subscribe(() => {
      this.loading = false;
      this.toastr.success('保存しました。');
      this.render();
    }, (err) => {
      this.loading = false;
      this.toastr.error('保存に失敗しました。');
      console.log(err);
    });
  }

  public onPreview() {
    this.loading = true;
    this.service.postPreview(this.props.form.value).subscribe((res: any) => {
      window.open(res.url, 'preview');
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
      const msg = (typeof err === 'string') ? err : '保存に失敗しました。';
      this.toastr.error(msg);
    });
    return;
  }

}
