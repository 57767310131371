import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { SaleService } from './SaleService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { CmsUtil } from '../CmsUtil';

@Component({
  selector: 'sale',
  templateUrl: './Sale.html',
  styleUrls: ['./Sale.scss'],
  providers: []
})
export class SaleComponent extends AbstractComponent<any> {
  // コンストラクタ
  constructor(private service: SaleService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {
    super();
    this.props = {
      targets: [],
    };
  }

  protected render() {
    this.props.ecName = CmsUtil.getEcName(this.route.url['value'][0].path);
    const siteId = CmsUtil.getSiteId(this.route.url['value'][0].path);
    this.props.form = new FormGroup({
      siteId: new FormControl(siteId),
      contentType: new FormControl('sale_item'),
      saleTargets: new FormArray([]),
      saleItemName: new FormControl(false),
      saleItemPrice: new FormControl(true),
      saleInventoryCount: new FormControl('1', [Validators.required, Validators.pattern("^[0-9]*$")]),
    });
    this.saleTargets.push(new FormControl('', Validators.required));

    this.loading = true;
    this.service.getContent(siteId).subscribe((res: any) => {
      if (_.get(res, 'content.contentType')) {
        for (let i = 0; i < res.content.saleTargets.length - 1; i++) {
          this.saleTargets.push(new FormControl('', Validators.required));
        }
        this.props.form.patchValue({
          siteId: siteId,
          contentType: 'sale_item',
          saleTargets: res.content.saleTargets,
          saleItemName: res.content.saleItemName,
          saleItemPrice: res.content.saleItemPrice,
          saleInventoryCount: res.content.saleInventoryCount,
        });
      }
      this.props.targets = res.targets;
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
  }

  get saleTargets(): FormArray { return this.props.form.get('saleTargets') as FormArray; }

  public onAddTarget() {
    this.saleTargets.push(new FormControl('', Validators.required));
  }
  public onDeleteTarget(idx) {
    if (this.saleTargets.length > 1) {
      this.saleTargets.removeAt(idx);
    }
  }

  public getTargetTitle(id) {
    const ret = this.props.targets.find(e => e.targetCode === id);
    return _.get(ret, 'targetTitle');
  }

  public onSubmit() {
    if (this.props.form.invalid) {
      return;
    }

    this.loading = true;
    this.service.postContent(this.props.form.value).subscribe(() => {
      this.loading = false;
      this.toastr.success('保存しました。');
      this.render();
    }, (err) => {
      this.loading = false;
      this.toastr.error('保存に失敗しました。');
      console.log(err);
    });
  }

  public onPreview() {
    this.loading = true;
    this.service.postPreview(this.props.form.value).subscribe((res: any) => {
      window.open(res.url, 'preview');
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
      const msg = (typeof err === 'string') ? err : '保存に失敗しました。';
      this.toastr.error(msg);
    });
    return;
  }

}
