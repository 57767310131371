import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class ShoplistPassService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  postPassword(body) {
    return this.httpClient.post(`${this.API_URL}/rest/shoplist/shoplistPass/save`, body);
  }

  getPassHistory() {
    return this.httpClient.get(`${this.API_URL}/rest/shoplist/shoplistPass/history`);
  }
}