import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class UserPasswordService extends AbstractService {
  constructor(
    private httpClient: HttpClient) {
    super();
  }
  // ログインユーザ情報の取得
  getUserMe() {
    return this.httpClient.get(`${this.API_URL}/rest/user/me`);
  }
  // パスワードの変更
  updatePass(tid, password) {
    return this.httpClient.put(`${this.API_URL}/rest/user/password`, { 'tid': tid, 'password': password });
  }
}
