import { Component, ViewChild } from '@angular/core';
import { ItemMstListService } from './ItemMstListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'item-mst-list',
  templateUrl: './ItemMstList.html',
  styleUrls: ['./ItemMstList.scss'],
  providers: [],
})
export class ItemMstListComponent extends AbstractComponent<any> {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public ecName: string;
  public file;
  public registerFlg = false;

  constructor(
    private service: ItemMstListService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    super();
  }
  protected render() {
    this.props.form = new FormGroup({
      csv: new FormControl(null, [Validators.required])
    });

    this.ecName = this.route.url['value'][0].path;
    this.service.get({ siteId: this.getSiteId(this.ecName) }).subscribe((res: any) => {
      this.props.displayedColumns = [
        'excel_file_name',
        'csv_file_name',
        'item_count',
        'exec_status_name',
        'created_at',
        'created_by',
      ];

      this.props.dataSource = new MatTableDataSource<any>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
    });

  }

  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onChange(files) {
    this.file = files;
  }

  onSubmit() {
    this.loading = true;
    this.registerFlg = true;
    this.service.uploadFile(this.file).subscribe((res: any) => {
      this.registerFlg = false;
      this.render();
      this.toastr.success('商品マスタ生成を開始しました');
    }, (err) => {
      console.log(err);
      this.toastr.error((typeof err.message === 'string') ? err.message : err.message.join('<br>'));
      this.registerFlg = false;
      this.loading = false;
    });
  }

  private getSiteId(siteName) {
    const list = {
      magaseek: '008',
    };

    return list[siteName];
  }
}
