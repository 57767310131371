import { Component, ViewChild } from '@angular/core';
import { JanCsvService } from './JanCsvService';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'shoplist-janCsv',
	templateUrl: './JanCsv.html',
	styleUrls: ['./JanCsv.scss'],
	providers: []
})

export class JanCsvComponent extends AbstractComponent<any> {
	// View テーブル情報
	@ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	public file;
	public form;

	constructor(
		private service: JanCsvService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private router: Router,
        private route: ActivatedRoute,) 
    {
		super();
		this.props = {
			dataSource: null,
		};
	}

	protected render() {
		this.service.getList().subscribe((res: any) => {
			this.props.displayedColumns =
			  ['converted_file_name', 'converted_file_line_num', 'pre_converted_file_name', 'pre_converted_file_line_num', 'createdAt', 'createdBy'];
			this.props.dataSource = new MatTableDataSource<any>(res);
			this.props.dataSource.sort = this.sort;
			this.props.dataSource.paginator = this.paginator;
		});

		this.form = new FormGroup({
			csv: new FormControl(null, [Validators.required])
		});
	}

	upload(file: File[]) {
		this.file = file;
	}

	onSubmit() {
		this.service.uploadCsv(this.file).subscribe((res: any) => {
			this.render();
			this.toastr.success('CSVデータの変換が正常に完了しました');
		}, err => {
			this.toastr.error(err.message);
		})
	}

	download(dirPath) {
    this.service.downloadCsv(dirPath).subscribe((res: any) => {
      const fileName = dirPath.slice(dirPath.lastIndexOf('/') + 1);
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(res, fileName);
      } else {
        const objectURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = objectURL;
        link.download = fileName;
        link.click();
        document.body.removeChild(link);
      }
    });
  }

	applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }
}