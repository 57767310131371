import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

/**
 * Tenant dialogのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/06/21 T.Utsunomiya   新規作成
 */
@Injectable()
export class AppHeaderService {
  // API_URL = '/rest/auth/me';
  API_URL = '/rest/user/me';
  constructor(private  httpClient:  HttpClient) {}

  me() {
    // return this.httpClient.post(this.API_URL, {});
    return this.httpClient.get(this.API_URL, {});
  }
}
