import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../common/service/AuthService';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private service: AuthService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (location.href.indexOf('login') === -1) {
        if (err.status === 401) {
          this.service.logout();
        }
      }
      return throwError(err.error);
    }));
  }
}