import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DeliveryResultDialogService } from './DeliveryResultDialogService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { DeliveryResultComponent } from '../DeliveryResultComponent';

@Component({
  selector: 'delivery-result-dialog',
  templateUrl: './DeliveryResultDialog.html',
  styleUrls: ['./DeliveryResultDialog.scss']
})
export class DeliveryResultDialogComponent extends AbstractComponent<any> {
  public parent: DeliveryResultComponent;
  public fileName: string;
  public dirId: string;
  public text: string;

  // コンストラクタ
  constructor(
    private service: DeliveryResultDialogService,
    @Inject(MAT_DIALOG_DATA) public parameter: any
  ) {
    super();
    if (parameter) {
      this.parent = parameter.parent;
      this.fileName = parameter.name;
      this.dirId = parameter.dirId;
    }
  }

  /** 書き出し */
  protected render() {
    this.service.getCsv(this.fileName, this.dirId).subscribe((res: any) => {
      this.fileToText(res).then(text => {
        this.text = text;
      })
        .catch(err => console.log(err));
    });
  }

  protected fileToText(file): any {
    const reader = new FileReader();
    reader.readAsText(file, 'shift-jis');
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
    });
  }
}
