import { RouterModule, Routes } from '@angular/router';
import { UserRoleGuardService } from '../UserRoleGuardService';
import { ShoplistPassComponent } from './password/ShoplistPassComponent';
import { JanCsvComponent } from './janCsv/JanCsvComponent';
import { MasterComponent } from './master/MasterComponent';
import { ShoplistMovementComponent } from "./movement/ShoplistMovementComponent";

/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'shoplist',
    children: [
      { path: '', redirectTo: 'password', pathMatch: 'full' },
      { path: 'password', component: ShoplistPassComponent, canActivate: [UserRoleGuardService] },
      { path: 'janCsv', component: JanCsvComponent, canActivate: [UserRoleGuardService] },
      { path: 'master', component: MasterComponent, canActivate: [UserRoleGuardService] },
      { path: 'movement/101707', component: ShoplistMovementComponent, canActivate: [UserRoleGuardService] },
      { path: 'movement/101640', component: ShoplistMovementComponent, canActivate: [UserRoleGuardService] },
    ]
  }
];
export const ShoplistRoutingModule = RouterModule.forRoot(routes);
