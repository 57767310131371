import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { ExcludeItemDeleteDialogService } from './ExcludeItemDeleteDialogService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import { ExcludeListComponent } from '../ExcludeListComponent';

/**
 * 案件登録画面
 *  <Controller>
 *
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/06/28 T.Utsunomiya   新規作成
 */
@Component({
  selector: 'exclude-item-delete-dialog',
  templateUrl: './ExcludeItemDeleteDialog.html',
  styleUrls: ['./ExcludeItemDeleteDialog.scss']
})
export class ExcludeItemDeleteDialogComponent extends AbstractComponent<any> {
  private parent: ExcludeListComponent;

  // コンストラクタ
  constructor(
    private service: ExcludeItemDeleteDialogService,
    @Inject(MAT_DIALOG_DATA) public parameter: any,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    super();
    if (parameter) {
      this.parent = parameter.parent;
      this.props.data = parameter.data;
    }
  }

  /** 書き出し */
  protected render() {
    console.log(this.props.data);
    let brandName = '';
    let itemName = '';
    let madeDate = '';
    if (this.props.data) {
      brandName = this.props.data.brand_name || '';
      itemName = this.props.data.item_name || '';
      madeDate = this.props.data.made_date || '';
    }
    this.props.registerForm = this.formBuilder.group({
      brandName: new FormControl(brandName, Validators.required),
      itemName: new FormControl(itemName, Validators.required),
      madeDate: new FormControl(madeDate, Validators.required),
    });
  }

  /** 削除 */
  protected onSubmit() {
    this.loading = true;
    this.service.deleteExcludeItem(this.props.data.tid).subscribe((res:any) => {
      this.toastr.success('削除が完了しました');
      const dialogRef = this.parent.deleteDialogRef;
      dialogRef.close();
    }, (error) => {
      this.loading = false;
      this.toastr.error('削除に失敗しました');
    });
  }

}
