import { Component, ViewChild } from '@angular/core';
import { PvsListService } from './PvsListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { typeofExpr } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'pvs-list',
  templateUrl: './PvsList.html',
  styleUrls: ['./PvsList.scss'],
  providers: []
})
export class PvsListComponent extends AbstractComponent<any> {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public form;
  public file;
  public title: string;
  public linkFileType = this.route.url['value'][0].path;

  constructor(
    private service: PvsListService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    super();
  }
  protected render() {
    this.service.getList().subscribe((res: any) => {
      this.props.displayedColumns =
        ['converted_file_name', 'converted_file_name.txt','converted_file_line_num', 'pre_converted_file_name', 'pre_converted_file_line_num', 'createdAt', 'createdBy'];
      this.props.dataSource = new MatTableDataSource<any>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
    });
    this.form = new FormGroup({
      csv: new FormControl(null, [Validators.required])
    });
  }

  upload(file: File[]) {
    this.file = file;
  }

  onSubmit() {
    this.service.uploadCsv(this.file).subscribe((res: any) => {
      this.render();
      this.toastr.success('CSVデータの変換が正常に完了しました');
    }, err => {
      this.toastr.error(err.message);
    });
  }

  download(dir_path) {
    this.service.downloadCsv(dir_path).subscribe((res: any) => {
      const fileName = dir_path.slice(dir_path.lastIndexOf('/') + 1);
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(res, fileName);
      } else {
        const objectURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = objectURL;
        link.download = fileName;
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
