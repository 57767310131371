import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BannerDeleteDialogService } from './BannerDeleteDialogService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';


@Component({
  selector: 'banner-delete-dialog',
  templateUrl: './BannerDeleteDialog.html',
  styleUrls: ['./BannerDeleteDialog.scss'],
  providers: []
})
export class BannerDeleteDialogComponent extends AbstractComponent<any> {

  props;

  // コンストラクタ
  constructor(private service: BannerDeleteDialogService,
    private matDialogRef : MatDialogRef<BannerDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public parameter: any,
    private formBuilder: FormBuilder) {
    super();
    if (parameter) {
      this.props.parameter = parameter;
    }
  }

  /** 書き出し */
  protected render() {
  }

  public delete() {
    this.loading = true;
    this.service.delete(this.props.parameter.tid).subscribe((res: any) => {
      this.matDialogRef.close('SUCCESS');
    });
    // this.service.deleteUser(id).subscribe((res: any) => {
    //   this.loading = false;
    //   this.matDialogRef.close('SUCCESS')
    // }, (err) => {
    //   this.loading = false;
    //   this.matDialogRef.close('ERROR'),
    //   this.loading = false;
    // });
  }

  public dateFormat(date) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
}
