import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { PageUpdateService } from './PageUpdateService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { CmsUtil } from '../../CmsUtil';

@Component({
  selector: 'page-update',
  templateUrl: './PageUpdate.html',
  styleUrls: ['./PageUpdate.scss'],
  providers: []
})
export class PageUpdateComponent extends AbstractComponent<any> {
  // コンストラクタ
  constructor(private service: PageUpdateService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {
    super();
    this.props = {
      tid: -1
    };
  }

  protected componentWillMount() {
    const siteId = CmsUtil.getSiteId(this.route.url['value'][0].path);

    this.props.deviceTypeList = [];
    this.props.deviceTypeList.push({ value: '', name: '選択してください' });
    this.props.deviceTypeList.push({ value: 'pc', name: 'PC' });
    this.props.deviceTypeList.push({ value: 'sp', name: 'スマホ' });

    this.props.contentTypeList = [];
    this.props.contentTypeList.push({ value: '', name: '選択してください' });
    if (siteId !== '014') {
      this.props.contentTypeList.push({ value: 'index', name: '店舗トップ'});
      this.props.contentTypeList.push({ value: 'item_detail', name: '商品詳細' });
    }
    this.props.contentTypeList.push({ value: 'item_ranking', name: '売れ筋商品ランキング' });
    this.props.contentTypeList.push({ value: 'new_item', name: '新着アイテム' });
    this.props.contentTypeList.push({ value: 'sale_item', name: 'セールアイテム' });
    this.props.contentTypeList.push({ value: 'custom', name: 'カスタム' });

    this.props.form = new FormGroup({
      siteId: new FormControl(siteId),
      contentType: new FormControl(''),
      deviceType: new FormControl(''),
      contentName: new FormControl('', Validators.required),
      htmlContent: new FormControl('', Validators.required),
      cssContent: new FormControl(''),
      jsContent: new FormControl(''),
      note: new FormControl(''),
    });
  }

  protected render() {
    this.props.ecName = CmsUtil.getEcName(this.route.url['value'][0].path);
    this.props.form.get('contentType').valueChanges.subscribe((value) => {
      if (value) {
        this.props.form.patchValue({
          contentName: (value !== 'custom') ? value : ''
        });
      }
    });

    this.loading = true;
    this.route.params.subscribe(param => {
      if (param.id === 'create') {
        this.loading = false;
      } else {
        this.props.tid = param.id;
        this.service.show(param.id).subscribe((res: any) => {
          this.props.form.patchValue({
            siteId: res.site_id,
            deviceType: res.device_type,
            contentType: res.content_type,
            contentName: res.content_name,
            htmlContent: res.html_content,
            cssContent: res.css_content,
            jsContent: res.js_content,
            note: res.note,
          });
          this.loading = false;
        }, (err) => {
          console.log(err);
          this.loading = false;
        });
      }
    });
  }

  public onSubmit() {
    if (this.props.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.props.tid === -1) {
      this.service.postContent(this.props.form.value).subscribe((res: any) => {
        this.props.tid = res.tid;
        this.loading = false;
        this.toastr.success('保存しました。');
      }, (err) => {
        console.log(err);
        this.loading = false;
        const msg = (typeof err === 'string') ? err : '保存に失敗しました。';
        this.toastr.error(msg);
      });

    } else {
      this.service.patchContent(this.props.tid, this.props.form.value).subscribe(() => {
        this.loading = false;
        this.toastr.success('保存しました。');
      }, (err) => {
        console.log(err);
        this.loading = false;
        const msg = (typeof err === 'string') ? err : '保存に失敗しました。';
        this.toastr.error(msg);
      });
    }
  }

  public onPreview() {
    this.loading = true;
    this.service.postPreview(this.props.form.value).subscribe((res: any) => {
      window.open(res.url, 'preview');
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
      const msg = (typeof err === 'string') ? err : '保存に失敗しました。';
      this.toastr.error(msg);
    });
    return;
  }

}
