import { RouterModule, Routes } from '@angular/router';
import { RoleGuardService } from '../RoleGuardService';
import { AdjustStocksComponent } from './adjust_stocks/AdjustStocksComponent';


/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'stocks',
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'adjust_stocks', component: AdjustStocksComponent, canActivate: [RoleGuardService] },
    ]
  }
];
export const StocksRoutingModule = RouterModule.forRoot(routes);
