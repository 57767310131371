import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NoticeUpdateService } from './NoticeUpdateService';
import { ToastrService } from 'ngx-toastr';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { FileUploader } from 'ng2-file-upload';
import * as moment from 'moment';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { CmsUtil } from '../../CmsUtil';


@Component({
  selector: 'notice-update',
  templateUrl: './NoticeUpdate.html',
  styleUrls: ['./NoticeUpdate.scss'],
  providers: []
})

export class NoticeUpdateComponent extends AbstractComponent<any>  {
  public uploader: FileUploader;

  public props = {
    parent: null,
    form : null,
    text : null,
    ecName : null,
  };

  // コンストラクタ
  constructor(
    private service: NoticeUpdateService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private fb: FormBuilder
  ) {
    super();
    dateTimeAdapter.setLocale('ja-JP');

  }

  public render() {
    this.uploader = new FileUploader({});
    this.props.ecName = CmsUtil.getEcName(this.route.url['value'][0].path);
    this.renderForm();

    this.loading = true;
    this.route.params.subscribe(param => {
      if (param.id === 'create') {
        this.loading = false;
      } else {
        this.service.show(param.id).subscribe((res: any) => {
          this.props.form.controls['siteId'].setValue(res.siteId);
          this.props.form.controls['tid'].setValue(res.tid);
          this.props.form.controls['title'].setValue(res.notice_title);
          this.props.form.controls['text'].setValue(res.notice_text);
          this.props.form.controls['startDate'].setValue(res.start_date);
          this.props.form.controls['endDate'].setValue(res.end_date);
          this.loading = false;
        });
      }
    });
  }

  public renderForm() {
    const siteId = CmsUtil.getSiteId(this.route.url['value'][0].path);
    this.props.form = this.fb.group({
      siteId: new FormControl(siteId),
      tid: new FormControl(''),
      title: new FormControl('', Validators.required),
      text: new FormControl('', Validators.required),
      startDate : new FormControl('', Validators.required),
      endDate : new FormControl('', Validators.required)
    });
  }

  public onSubmit() {
    if (this.props.form.invalid) {
      console.log(this.props.form);
      return;
    }
    const startDate = moment(this.props.form.get('startDate').value).format('YYYYMMDDHHmm');
    const endDate = moment(this.props.form.get('endDate').value).format('YYYYMMDDHHmm');
    if ( startDate >= endDate ) {
      this.toastr.error('公開開始日時は公開終了日時より前を選択してください');
      return;
    }

    this.loading = true;
    this.props.form.value.siteId = CmsUtil.getSiteId(this.route.url['value'][0].path)
    this.service.create(this.props.form.value).subscribe(e => {
    });

    // 登録の場合
    this.loading = false;
    const site = this.route.url['value'][0].path;
    this.router.navigate([`/cms/${site}/notice`]);
  }

  public cancell() {
    const site = this.route.url['value'][0].path;
    this.router.navigate([`/cms/${site}/notice`]);
  }
}
