import { RouterModule, Routes } from '@angular/router';
import { UserRoleGuardService } from '../UserRoleGuardService';
import { SalesdataListComponent } from './list/SalesdataListComponent';

/** ルーティング設定 */
const routes: Routes = [
    {
      path: 'salesdata',
      children: [
        { path: 'shoplist', component: SalesdataListComponent, canActivate: [UserRoleGuardService] },
        { path: 'marui', component: SalesdataListComponent, canActivate: [UserRoleGuardService] },
        { path: 'zozo', component: SalesdataListComponent, canActivate: [UserRoleGuardService] },
        { path: 'fashionwalker', component: SalesdataListComponent, canActivate: [UserRoleGuardService] },
        { path: 'locondo', component: SalesdataListComponent, canActivate: [UserRoleGuardService] },
        { path: 'rakuten', component: SalesdataListComponent, canActivate: [UserRoleGuardService] },
        { path: 'magaseek', component: SalesdataListComponent, canActivate: [UserRoleGuardService] },
        { path: 'flagshop', component: SalesdataListComponent, canActivate: [UserRoleGuardService] },
        { path: 'mitsui', component: SalesdataListComponent, canActivate: [UserRoleGuardService] },
        { path: 'amazon', component: SalesdataListComponent, canActivate: [UserRoleGuardService] },
        { path: 'ryuryumall', component: SalesdataListComponent, canActivate: [UserRoleGuardService] },
      ]
    }
];
export const SalesdataRoutingModule = RouterModule.forRoot(routes);
