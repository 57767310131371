import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class YahooService extends AbstractService {
  constructor(private httpClient: HttpClient) {
      super();
  }

  getContent() {
    return this.httpClient.get(`${this.API_URL}/rest/cms/setting/014`);
  }

  postContent(formValue) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/setting`, formValue);
  }

  postYahooAuth(values) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/setting/yahoo/auth`, values);
  }
}
