import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { BundleFileDropDialogService } from './BundleFileDropDialogService'
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'bundle-file-drop-dialog',
  templateUrl: './BundleFileDropDialog.html',
  styleUrls: ['./BundleFileDropDialog.scss'],
  providers: []
})
export class BundleFileDropDialogComponent extends AbstractComponent<any> {
  public file: any;

  // コンストラクタ
  constructor(
    @Inject(MAT_DIALOG_DATA) public parameter: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private service: BundleFileDropDialogService,
  ) {
    super();
    if (parameter) {
      this.props.user = parameter.user;
      this.props.parent = parameter.parent;
    }
  }

  /** 書き出し */
  protected render() {
    this.props.useTypeCsvForm = this.formBuilder.group({
      imageFlg: new FormControl(false, Validators.required),
      itemFlg: new FormControl('1', Validators.required),
      rakutenAfterwardFlg: new FormControl(false, Validators.required),
      skuFlg: new FormControl(false, Validators.required),
      selectFlg: new FormControl(false, Validators.required),
      catFlg: new FormControl(false, Validators.required),
      productFlg: new FormControl(false, Validators.required),
      shoplistFlg: new FormControl(false, Validators.required),
      shoplistAfterwardFlg: new FormControl(false, Validators.required),
      shoplistBrandFlg: new FormControl(false, Validators.required),
      yahooFlg: new FormControl(false, Validators.required),
      amazonFlg: new FormControl(false, Validators.required),
      itemDateFlg: new FormControl(false, Validators.required),
      imageDateFlg: new FormControl(false, Validators.required),
      mitsuiFlg: new FormControl(false, Validators.required),
      upfile: new FormControl(null, [Validators.required])
    });
  }

  /* csvファイルがuploadされた時 */
  protected onChange(files) {
    this.file = files;
  }

  /* 実行 */
  protected async onSubmit() {
    this.loading = true;
    try {
      this.service.postFileAndFileList(this.props.useTypeCsvForm.value, this.file).subscribe((res:any) => {
        this.toastr.success('登録処理を開始しました');
        const dialogRef = this.props.parent.BundleFileDropDialog;
        dialogRef.close();
        this.loading = false;
      }, (err) => {
        console.log(err);
        this.toastr.error('登録処理に失敗しました');
        const dialogRef = this.props.parent.BundleFileDropDialog;
        this.loading = false;
        dialogRef.close();
      });
    } catch (e) {
      console.log(e);
      this.toastr.error('登録処理に失敗しました');
      const dialogRef = this.props.parent.BundleFileDropDialog;
      this.loading = false;
      dialogRef.close();
    }

  }
}
