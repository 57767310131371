import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class MasterService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  uploadCsv(a_file: File, c_file: File) {
    if (a_file && a_file[0] && c_file && c_file[0]) {
      const formData: FormData = new FormData();
      formData.append('file', a_file[0]);
      formData.append('file', c_file[0]);
      const res = this.httpClient.post(`${this.API_URL}/rest/shoplist/master/convert`, formData);
      return res;
    } else {
      throw new Error('Invalid file');
    }
  }

  getList() {
    return this.httpClient.get(`${this.API_URL}/rest/shoplist/master/list`);
  }

  downloadCsv(path) {
    const params = new HttpParams().set('dirPath', path);
    return this.httpClient.get(`${this.API_URL}/rest/shoplist/master/csv`, { params: params, responseType: 'blob' });
  }

}