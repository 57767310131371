import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class YahooTokenService extends AbstractService {
  constructor(private httpClient: HttpClient) {
      super();
  }

  post(values) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/setting/yahoo/token`, values);
  }
}
