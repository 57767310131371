import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class SalesdataListService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getList(linkFileType: string) {
    const params = new HttpParams().set('linkFileType', linkFileType);
    return this.httpClient.get(`${this.API_URL}/rest/salesdata`, { params: params });
  }

  /**
   * 売上データ変換
   * @param file 変換元ファイル
   * @param linkFileType 対象EC名
   *
   */
  uploadCsv(file: File, linkFileType: string, body) {
    const formData: FormData = new FormData();
    formData.append('file', file[0]);
    formData.append('linkFileType', linkFileType);
    formData.append('selectedDate', (body.selectedDate) ? body.selectedDate.format('YYYY-MM-DD') : '');
    formData.append('warehouseName', (body.warehouseName) ? body.warehouseName : '');
    const res = this.httpClient.post(`${this.API_URL}/rest/salesdata/convert`, formData);
    return res;
  }
}
