import { Component, ViewChild } from '@angular/core';
import { ImportPriceService } from './ImportPriceService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'crossmall-importPrice',
  templateUrl: './ImportPrice.html',
  styleUrls: ['./ImportPrice.scss'],
  providers: []
})
export class ImportPriceComponent extends AbstractComponent<any> {

  public file;
	public form;

	constructor(
    private service: ImportPriceService,
		private toastr: ToastrService,
  ) {
    super();
		this.props = {
			dataSource: null,
		};
  }

  protected render() {
		this.form = new FormGroup({
			csv: new FormControl(null, [Validators.required])
		});

  }

	upload(file: File[]) {
		this.file = file;
	}

	onSubmit() {
		this.service.uploadCsv(this.file).subscribe((res: any) => {
			this.toastr.success('CSVデータの変換が正常に完了しました');
		}, err => {
			this.toastr.error(err.message);
		})
	}

}