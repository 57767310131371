import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { HomeService } from './HomeService';

@Component({
  selector: 'app-home',
  templateUrl: './Home.html',
  styleUrls: ['./Home.scss']
})
export class HomeComponent extends AbstractComponent<any> {
  // コンストラクタ
  constructor(
    private service: HomeService,
    private route: ActivatedRoute,
  ) {
    super();
    this.props = {
      content: '',
    };
  }

  // 初期の表示画面を描写
  protected render() {
    this.loading = true;
    this.service.getContent().subscribe((res: any) => {
      this.props.content = res.contentBody;
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
  }
}
