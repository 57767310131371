import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { AdjustStocksService } from './AdjustStocksService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ActivatedRoute } from '@angular/router';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { ToastrService } from 'ngx-toastr';

let before
let beforep = []
let beforeData = []
let afterData = []
let registData = []
let change = []
let changeData = []
let deleteData = []

let delf
let del

/**
 * 在庫振分設定画面
 *  <Controller>
 *
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/06/28 T.Utsunomiya   新規作成
 */
@Component({
  selector: 'adjust_stocks',
  templateUrl: './AdjustStocks.html',
  styleUrls: ['./AdjustStocks.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class AdjustStocksComponent extends AbstractComponent<any> {

  // View テーブル情報
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // コンストラクタ
  constructor(
    private service: AdjustStocksService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ){
    super();
    this.props = {
      dataSource: {}
    };
  }

  public months: string[] = [
    '一年前', '半年前', '一ヶ月前'
  ];

  public directions: string[] = [
    'より新しい', 'より古い'
  ];

  // 初期の表示画面を描写
  protected render() {
    this.loading = true;
    this.service.getAdjust().subscribe((res: any) => {
      before = res.map((data, no) => {
        no++;
        return {
          no: no,
          madeDateConditionMonths: data.madeDateConditionMonths,
          madeDateConditionDirection: data.madeDateConditionDirection,
          amountCondition: data.amountCondition,
          brandPriority: data.brandPriority,
          tid: data.tid
        };
      });
      beforep = JSON.parse(JSON.stringify(before));
      for(let i in beforep) {
        const tid: number = beforep[i].tid;
        beforeData.push(tid);
      }

      this.props.displayedColumns = ['no', 'madeDateCondition', 'amountCondition', 'brandPriority', 'actions'];
      this.props.dataSource = new MatTableDataSource<any[]>(before);
      this.loading = false;
    }, (err) => {
      this.loading = false;
    });

    this.props.dataSource.sort = this.sort;
    this.props.dataSource.paginator = this.paginator;
  }

  // テーブルに新規の行を追加する
  addConditions = (el: number) => {
    let result = this.props.dataSource.data;
    for(let i in result) {
      if(result[i].no > el) {
        result[i].no = result[i].no + 1;
      }
    }
    result.splice(el, 0, {"no":el + 1, "madeDateConditionMonths":null, "madeDateConditionDirection":null, "amountCondition":null, "brandPriority":null})
    this.props.dataSource = new MatTableDataSource<fileData>(result);
  }

  // 指定した行をテーブルから削除する
  delConditions = (el: number) => {
    let result = this.props.dataSource.data;
    if(result.length == 1) {
      return;
    }
    del = result[el-1].tid;

    result.splice(el-1, 1)
    for(let i in result) {
      result[i].no = Number(i) + 1
    }
    this.props.dataSource = new MatTableDataSource<fileData>(result);
  }

  // 編集したテーブルデータでDBを更新
  onSubmit = () => {
    const after = this.props.dataSource.data;
    console.log(after)
    for (let i in after) {
      if(!after[i].madeDateConditionMonths) {
        this.toastr.error('製造年月条件(期間)は必須です');
        return;
      }
      if(!after[i].madeDateConditionDirection) {
        this.toastr.error('製造年月条件(範囲)は必須です');
        return;
      }
      if(parseInt(after[i].amountCondition) != 0) {
        if(!after[i].amountCondition) {
          this.toastr.error('個数条件は必須です');
          return;
        }
      }
      if(parseInt(after[i].brandPriority) != 0) {
        if(!after[i].brandPriority) {
          this.toastr.error('CROSSMALL在庫数は必須です');
          return;
        }
      }
      if(parseInt(after[i].brandPriority) > parseInt(after[i].amountCondition)) {
        this.toastr.error('個数条件はCROSSMALL在庫数以上になるように設定してください');
        return;
      }
    }

    if (!after) {
      return;
    }

    for(let i in after) {
      const tid: number = after[i].tid;
      afterData.push(tid);
    }

    //（登録）tidがない
    for(let i in afterData) {
      if(!afterData[i]) {
        registData.push(after[i]);
      }
    }

    //（更新）差分が存在する
    changeData = []
    for(let i in afterData) {
      if(afterData[i]) {
        changeData.push(after[i]);
      }
    }
    for(let i in changeData) {
      if(changeData[i]) {
        if(!(beforep[i].no == changeData[i].no
          && beforep[i].madeDateConditionMonths == changeData[i].madeDateConditionMonths
          && beforep[i].madeDateConditionDirection == changeData[i].madeDateConditionDirection
          && beforep[i].amountCondition == changeData[i].amountCondition
          && beforep[i].brandPriority == changeData[i].brandPriority
        )) {
          change.push(changeData[i]);
        }
      }
    }

    // (削除）更新前にtidがあり更新後にtidがない
    beforeData.concat(afterData).forEach(item => {
      if(beforeData.includes(item) && !afterData.includes(item)) {
        for(let i in beforep) {
          if(beforep[i].tid == item) {
            del = item;
            beforep[i].delFlg = 1;
            deleteData.push(beforep[i]);
          }
        }
      }
    })

    beforeData = []
    afterData = []
    let update = deleteData.concat(change);
    deleteData = []
    change = []
    update = update.concat(registData);
    registData = []
    this.loading = true;
    this.service.updateAdjust(update).subscribe(() => {
      this.loading = false;
      this.toastr.success('更新が完了しました');
      this.render();
    }, err => {
      this.loading = false;
      this.toastr.error(err.message);
    });
  }
}

export interface fileData {
  no: number;
  made_date_condition_months: any;
  made_date_condition_direction: any;
  amount_condition: any;
  randPriority: any;
}
