import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../model/UserModel';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }
  baseUrl = 'http://localhost:8080/user-portal/users';

  getUsers() {
    /* let fakeUsers = [{id: 1, firstName: 'Dhiraj', lastName: 'Ray', email: 'dhiraj@gmail.com'},
     {id: 1, firstName: 'Tom', lastName: 'Jac', email: 'Tom@gmail.com'},
     {id: 1, firstName: 'Hary', lastName: 'Pan', email: 'hary@gmail.com'},
     {id: 1, firstName: 'praks', lastName: 'pb', email: 'praks@gmail.com'},
   ];
   return Observable.of(fakeUsers).delay(5000);*/
    return this.http.get<User[]>(this.baseUrl);
  }

  getUserById(id: number) {
    return this.http.get<User>(this.baseUrl + '/' + id);
  }

  createUser(loginId: string, userName: string, email: string, password: string, role: string) {
    return this.http.post<any>('/rest/user', {
      'loginId': loginId, 'userName': userName,
      'email': email, 'password': password, 'role': role
    });
  }

  updateUser(loginId: string, userName: string, email: string, role: string) {
    return this.http.put<any>('/rest/user', { 'loginId': loginId, 'userName': userName, 'email': email, 'role': role });
  }

  deleteUser(id: number) {
    return this.http.delete(this.baseUrl + '/' + id);
  }

  // ログインユーザ情報の取得
  getUserMe() {
    return this.http.get(`/rest/user/me`);
  }

}
