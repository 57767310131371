import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppLeftSideService } from './AppLeftSideService';

@Component({
  selector: 'app-left-side',
  templateUrl: './AppLeftSide.html',
  styleUrls: ['./AppLeftSide.scss'],
})
export class AppLeftSideComponent implements OnInit {

  thenBlock: TemplateRef<any> = null;
  show: boolean;
  subNowClick: boolean;
  subSalesUlFlg: boolean;
  subDeliverySlipUlFlg: boolean;
  subItemUlFlg: boolean;
  subItemMstUlFlg: boolean;
  subCrossmallUlFlg: boolean;
  subControlUlFlg: boolean;
  subRakutenCmslUlFlg: boolean;
  subPaypayCmslUlFlg: boolean;
  subWowmaCmslUlFlg: boolean;
  subSalesMgtlUlFlg: boolean;
  subShopListUlFlg: boolean;
  subZozoUlFlg: boolean;
  subMoveDataUlFlg: boolean;
  subDHUlFlg: boolean;
  subLocondoUlFlg: boolean;
  isAdmin: boolean;
  isGuest: boolean;

  constructor(private router: Router,
    private appLeftSideService: AppLeftSideService) { }

  ngOnInit() {
    // 以下のurlに該当する場合、サイドバーの項目を表示する
    this.subSalesUlFlg = [
      '/salesdata/shoplist',
      '/salesdata/marui',
      '/salesdata/zozo',
      '/salesdata/fashionwalker',
      '/salesdata/locondo',
      '/salesdata/rakuten',
      '/salesdata/magaseek',
      '/salesdata/flagshop',
      '/salesdata/mitsui',
      '/salesdata/amazon',
      '/salesdata/ryuryumall',
    ].indexOf(this.router.url) >= 0;
    this.subDeliverySlipUlFlg = [
      '/delivery/list/101707',
      '/delivery/list/101640',
      '/delivery/log',
      '/delivery/result',
    ].indexOf(this.router.url) >= 0;
    this.subItemUlFlg = [
      '/items/new',
      '/items/rearrival',
      '/items/individual',
      '/items/bundle'
    ].indexOf(this.router.url) >= 0;
    this.subItemMstUlFlg = [
      '/item-mst/magaseek',
    ].indexOf(this.router.url) >= 0;
    this.subCrossmallUlFlg = [
      '/crossmall/anapnet',
      '/crossmall/6digit',
      '/crossmall/7digit',
      '/crossmall/10digit',
      '/crossmall/importPrice'
    ].indexOf(this.router.url) >= 0;
    this.subRakutenCmslUlFlg = [
      '/cms/rakuten/index',
      '/cms/rakuten/detail',
      '/cms/rakuten/ranking',
      '/cms/rakuten/new',
      '/cms/rakuten/sale',
      '/cms/rakuten/banner',
      '/cms/rakuten/notice',
      '/cms/rakuten/page',
      '/cms/rakuten/setting',
    ].indexOf(this.router.url) >= 0;
    this.subPaypayCmslUlFlg = [
      '/cms/paypay/ranking',
      '/cms/paypay/new',
      '/cms/paypay/sale',
      '/cms/paypay/banner',
      '/cms/paypay/notice',
      '/cms/paypay/page',
      '/cms/paypay/setting',
    ].indexOf(this.router.url) >= 0;
    this.subWowmaCmslUlFlg = [
      '/cms/wowma/index',
      '/cms/wowma/detail',
      '/cms/wowma/ranking',
      '/cms/wowma/new',
      '/cms/wowma/sale',
      '/cms/wowma/banner',
      '/cms/wowma/notice',
      '/cms/wowma/page',
      '/cms/wowma/setting',
    ].indexOf(this.router.url) >= 0;
    this.subMoveDataUlFlg = [
      '/movedata/kumai',
    ].indexOf(this.router.url) >= 0;
    this.subDHUlFlg = [
      '/dh/pvs',
      '/dh/marui',
    ].indexOf(this.router.url) >= 0;
    this.subSalesMgtlUlFlg = [
      '/sales-mgt/index',
      '/sales-mgt/ranking',
    ].indexOf(this.router.url) >= 0;
    this.subShopListUlFlg = [
      '/shoplist/password',
      '/shoplist/janCsv',
      '/shoplist/master',
      '/shoplist/movement/101707',
      '/shoplist/movement/101640',
    ].indexOf(this.router.url) >= 0;
    this.subZozoUlFlg = [
      '/zozo/shipment/acs',
      '/zozo/shipment/all',
      '/zozo/shipment/af',
      '/zozo/shipment-report',
    ].indexOf(this.router.url) >= 0;

    this.show = true;
    this.isAdmin = false;
    this.isGuest = true;
    this.appLeftSideService.me().subscribe((res: any) => {
      this.isAdmin = (res.role === 'Admin');
      this.isGuest = (res.role === 'Guest');
    });
  }

  isActive(path: string) {
    return (this.router.url.indexOf(path) > -1);
  }

  onClickSalesdata() {
    this.subSalesUlFlg = !this.subSalesUlFlg;
  }

  onClickDelivery() {
    this.subDeliverySlipUlFlg = !this.subDeliverySlipUlFlg;
  }

  onClickItem() {
    this.subItemUlFlg = !this.subItemUlFlg;
  }
  onClickItemMst() {
    this.subItemMstUlFlg = !this.subItemMstUlFlg;
  }

  onClickCrossmall() {
    this.subCrossmallUlFlg = !this.subCrossmallUlFlg;
  }

  onClickControl() {
    this.subControlUlFlg = !this.subControlUlFlg;
  }

  onClickRakutenCms() {
    this.subRakutenCmslUlFlg = !this.subRakutenCmslUlFlg;
  }

  onClickPaypayCms() {
    this.subPaypayCmslUlFlg = !this.subPaypayCmslUlFlg;
  }

  onClickWowmaCms() {
    this.subWowmaCmslUlFlg = !this.subWowmaCmslUlFlg;
  }

  onClickMoveData() {
    this.subMoveDataUlFlg = !this.subMoveDataUlFlg;
  }

  onClickDHData() {
    this.subDHUlFlg = !this.subDHUlFlg;
  }

  onClickSalesMgt() {
    this.subSalesMgtlUlFlg = !this.subSalesMgtlUlFlg;
  }

  onClickShopList() {
    this.subShopListUlFlg = !this.subShopListUlFlg;
  }

  onClickZozo() {
    this.subZozoUlFlg = !this.subZozoUlFlg;
  }

  onClickLocondo() {
    this.subLocondoUlFlg = !this.subLocondoUlFlg;
  }

}
