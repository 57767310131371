import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class ItemMstListService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  get(params) {
    return this.httpClient.get(`${this.API_URL}/rest/item-mst/`, { params });
  }

  uploadFile(file) {
    const formData: FormData = new FormData();
    formData.append('file', file[0]);
    return this.httpClient.post(`${this.API_URL}/rest/item-mst/`, formData);
  }

}
