import { RouterModule, Routes } from '@angular/router';
import { UserRoleGuardService } from '../UserRoleGuardService';
import { MovedataListComponent } from './kumai/MovedataListComponent';

/** ルーティング設定 */
const routes: Routes = [
    {
      path: 'movedata',
      children: [
        { path: 'kumai', component: MovedataListComponent, canActivate: [UserRoleGuardService] },
      ]
    }
];
export const MovedataRoutingModule = RouterModule.forRoot(routes);
