import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 * Template ListのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/__/__    新規作成
 */
@Injectable()
export class ExcludeListService extends AbstractService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  checkIfExistsItem(itemId: string) {
    const params = new HttpParams()
      .set('itemId', itemId);
    return this.httpClient.get(`${this.API_URL}/rest/item/checkExcludeItem`, {params: params});
  }


  postExcludeItem(itemId: string) {
    const data = {
      itemId: itemId
    }
    return this.httpClient.post(`${this.API_URL}/rest/item/postExcludeItem`, data);
  }

  getExcludeItems() {
    return this.httpClient.get(`${this.API_URL}/rest/item/getExcludeItems`);
  }

}
