import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class UserUpdateService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  // ユーザ登録
  entryUser(userName, email, password, role) {
    return this.httpClient.post(`${this.API_URL}/rest/user`, {
      'userName': userName, 'email': email,
      'password': password, 'role': role
    });
  }

  // ユーザ編集
  editUser(tid, name, email, role) {
    return this.httpClient.put(`${this.API_URL}/rest/user`, {
      'tid': tid, 'userName': name,
      'email': email, 'role': role
    });
  }
}
