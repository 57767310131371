import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';


@Injectable()
export class NoticeUpdateService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }


  show(id) {
    return this.httpClient.get(`${this.API_URL}/rest/cms/notice/${id}`, {});
  }

  create(form:FormData) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/notice`, form);
  }

}
