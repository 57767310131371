import { RouterModule, Routes } from '@angular/router';
import { UserRoleGuardService } from '../UserRoleGuardService';
import { SalesMgtListComponent } from './list/SalesMgtListComponent';
import { SalesRankingComponent } from './ranking/SalesRankingComponent';


/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'sales-mgt',
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: SalesMgtListComponent, canActivate: [UserRoleGuardService] },
      { path: 'ranking', component: SalesRankingComponent, canActivate: [UserRoleGuardService] },
    ]
  }
];
export const SalesMgtRoutingModule = RouterModule.forRoot(routes);
