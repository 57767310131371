import { Component } from '@angular/core';
import {  ActivatedRoute } from '@angular/router';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import { YahooTokenService } from './YahooTokenService';

@Component({
    selector: 'yahoo-token',
    templateUrl: './YahooToken.html',
    styleUrls: ['./YahooToken.scss'],
    providers: []
})
export class YahooTokenComponent extends AbstractComponent<any> {
  // コンストラクタ
  constructor(private service: YahooTokenService,
    private route: ActivatedRoute,
    private toastr: ToastrService) {
    super();
    this.props = {
      stateFlg: false,
      message: false,
      expiryDate: null,
    };
  }

  protected render() {
    this.loading = true;
    this.route.queryParams.subscribe(params => {
      if (!params['code'] || !params['state']) {
        document.location.href = 'cms/paypay/setting';
        this.loading = false;
        return;
      }
      const values = {
        code: params['code'],
        state: params['state'],
      };
      this.service.post(values).subscribe((res: any) => {
        console.log(res);
        this.loading = false;
        this.props.stateFlg = true;
        if (res.expiryDate) {
          this.props.message = 'Yahoo ID認証が完了しました。';
          this.props.expiryDate = res.expiryDate;
        } else {
          this.props.message = res.message;
        }
      }, (err) => {
        console.log(err);
        this.loading = false;
        this.props.stateFlg = true;
        this.props.message = (typeof err === 'string') ? err : '認証に失敗しました。';
      });
    });

  }

}
