import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 * delivery ListのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/__/__    新規作成
 */
@Injectable()
export class DeliveryListService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }
  // サーバAPIを利用
  getDelivery(warehouseId) {
    const params = new HttpParams()
      .set('warehouseId', warehouseId);
    return this.httpClient.get(`${this.API_URL}/rest/delivery/delivery-slip/`, { params });
  }

  registerDeliverySlip(formValue) {
    const body = {
      'inputDate': formValue.inputDate.format('YYYY-MM-DD'),
      'warehouseId': formValue.warehouseId,
      'deliverySlipId': formValue.deliverySlipNum
    };
    return this.httpClient.post(`${this.API_URL}/rest/delivery/delivery-slip`, body);  // サーバAPI用
  }

  deleteDelivery(deliveryId, userId) {
    const params = new HttpParams()
      .set('deliveryId', deliveryId)
      .set('userId', userId);
    return this.httpClient.delete(`${this.API_URL}/rest/delivery-slip`, { params: params });
  }

}
