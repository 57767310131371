import { Component, OnInit, ViewChild } from '@angular/core';
declare var AdminLTE: any;
import { faChevronLeft, faChevronRight } from '@fortawesome/fontawesome-free-solid';
import fontawesome from '@fortawesome/fontawesome';

import { AppHeaderService } from './AppHeaderService';
import { AuthService } from '../../../core/common/service/AuthService';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './AppHeader.html',
  styleUrls: ['./AppHeader.scss']
})
export class AppHeaderComponent implements OnInit {

  user: any = { logo: '' };

  constructor(private service: AppHeaderService,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    AdminLTE.init();
    fontawesome.library.add(faChevronLeft, faChevronRight);
    this.service.me().subscribe(res => {
      this.user = res;
      // this.user.logo = 'data:' + this.user.mimetype + ';base64,' + this.user.logoData;
      // this.user.logo = '../../../static/images//logo/logo-mid-white.png';
    });
  }

  logout() {
    this.authService.logout();
  }

  userEdit() {
    this.router.navigate(['/user/edit']);
  }

}
