import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserUpdateService } from './UserUpdateService';
import { ToastrService } from 'ngx-toastr';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';

@Component({
  selector: 'user-update',
  templateUrl: './UserUpdate.html',
  styleUrls: ['./UserUpdate.scss'],
  providers: []
})

export class UserUpdateComponent extends AbstractComponent<any>  {
  public isCreate: boolean;

  // コンストラクタ
  constructor(
    private service: UserUpdateService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    super();
    this.props.user = {};
  }

  public render() {
    this.loading = true;
    this.route.params.subscribe(param => {
      // 登録の場合
      if (param.type === 'create') {
        this.isCreate = true;
        this.props.form = this.fb.group({
          name: new FormControl('', Validators.required),
          password: new FormControl('', [Validators.required,
          Validators.pattern('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?["!?\#$%&@\'()*+,-.\/])[a-zA-Z0-9!?z"\#$%&@\'()*+,-.\/]{8,100}$')]),
          checkPassword: new FormControl('', [Validators.required]),
          email: new FormControl('', Validators.required),
          role: new FormControl('', Validators.required),
        }, { validator: this.checkPasswords });
      } else {
        // 更新の場合
        this.isCreate = false;
        this.props.user.id = param.id;
        this.props.user.name = param.name;
        this.props.form = new FormGroup({
          name: new FormControl(param.name, Validators.required),
          email: new FormControl(param.email, Validators.required),
          role: new FormControl(param.role, Validators.required),
        });
      }
      this.loading = false;
    });
  }

  public onSubmit() {
    if (this.props.form.invalid) { return; }
    this.loading = true;
    // 登録の場合
    if (this.isCreate) {
      this.service.entryUser(
        this.props.form.controls.name.value,
        this.props.form.controls.email.value,
        this.props.form.controls.password.value,
        this.props.form.controls.role.value).subscribe(() => {
          this.router.navigate(['/admin/user/list']);
        }, (err) => {
          let errorMessage: string;
          errorMessage = typeof err.message === 'string' ? err.message : 'ユーザ登録に失敗しました。';
          this.toastr.error(errorMessage);
        });
    } else {
      // 更新の場合
      this.loading = true;
      this.service.editUser(
        Number(this.props.user.id),
        this.props.form.controls.name.value,
        this.props.form.controls.email.value,
        this.props.form.controls.role.value
      ).subscribe(() => {
        this.router.navigate(['/admin/user/list']);
      }, err => {
        let errorMessage: string;
        errorMessage = typeof err.message === 'string' ? err.message : 'ユーザ登録に失敗しました。';
        this.toastr.error(errorMessage);
      });
    }
    this.loading = false;
  }

  private checkPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const checkPassword = group.get('checkPassword').value;
    return pass === checkPassword ? null : { notSame: true };
  }
}
