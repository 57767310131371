import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';
import { map } from 'rxjs/operators';

@Injectable()
export class UserEditService extends AbstractService {
  constructor(
    private httpClient: HttpClient) {
    super();
  }
  // ログインユーザ情報の取得
  getUserMe() {
    return this.httpClient.get(`${this.API_URL}/rest/user/me`);
  }
  // ユーザ更新
  updateUser(tid, userName, email) {
    return this.httpClient.put(`${this.API_URL}/rest/user`, {
      'tid': tid, 'userName': userName,
      'email': email,
    });
  }
}
