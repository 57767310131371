import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { DeliveryDeleteDialogService } from './DeliveryDeleteDialogService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import { DeliveryListComponent } from '../list/DeliveryListComponent';
/**
 * 案件登録画面
 *  <Controller>
 *
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/06/28 T.Utsunomiya   新規作成
 */
@Component({
  selector: 'delivery-delete-dialog',
  templateUrl: './DeliveryDeleteDialog.html',
  styleUrls: ['./DeliveryDeleteDialog.scss']
})
export class DeliveryDeleteDialogComponent extends AbstractComponent<any> {
  private parent: DeliveryListComponent;

  // コンストラクタ
  constructor(private service: DeliveryDeleteDialogService,
    @Inject(MAT_DIALOG_DATA) public parameter: any,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private formBuilder: FormBuilder) {
    super();
    if (parameter) {
      this.parent = parameter.parent;
      this.props.delivery = parameter.delivery;
      this.props.project = parameter.project;
    }
  }

  /** 書き出し */
  protected render() {
    let inputDate = '';
    let warehouseId = '';
    let deliverySlipId = '';
    if (this.props.project) {
      inputDate = this.props.project.inputDate || '';
      warehouseId = this.props.project.warehouseId || '';
      deliverySlipId = this.props.project.deliverySlipId || '';
    }
    this.props.registerForm = this.formBuilder.group({
      inputDate: new FormControl(inputDate, Validators.required),
      warehouseId: new FormControl(warehouseId, Validators.required),
      deliverySlipId: new FormControl(deliverySlipId, Validators.required),
    });
  }

  /** 削除 */
  protected onSubmit() {
    this.loading = true;
    this.service.deleteDelivery(this.props.project.tid,
      this.props.project.inputDate,
      this.props.project.warehouseId
    ).subscribe((res: any) => {
      this.toastr.success('削除が完了しました');
      const dialogRef = this.parent.deleteDialogRef;
      dialogRef.close();
    }, () => {
      this.loading = false;
      this.toastr.error('削除が失敗しました');
    });
  }
}
