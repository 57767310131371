import { Directive } from '@angular/core';
import { Component, ElementRef, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[angular-tree-patch]'
})
export class AngularTreePatchComponent implements AfterViewChecked {
 
  private _el: HTMLElement;

  constructor(el: ElementRef) {
    this._el = el.nativeElement;
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      if (this._el.getAttribute('no-svg')) {
        let _rowall = this._el.querySelector('svg'); 
        if (_rowall) {
          _rowall.remove();
        }  
      }
    },0);
    if (this._el.getAttribute('no-header')) {
      let _rowall = this._el.querySelector('.treeview-header'); 
      if (_rowall) {
        _rowall.remove();
      }  
    }

    let _button = this._el.querySelector('.pull-right.form-check-label');
    if (_button) {
      _button.remove();
    }
  }
}
