import { RouterModule, Routes } from '@angular/router';
import { CrossmallListComponent } from './list/CrossmallListComponent';
import { ImportPriceComponent } from './importPrice/ImportPriceComponent';
import { UserRoleGuardService } from '../UserRoleGuardService';

/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'crossmall',
    children: [
      { path: 'anapnet', component: CrossmallListComponent, canActivate: [UserRoleGuardService] },
      { path: '6digit', component: CrossmallListComponent, canActivate: [UserRoleGuardService] },
      { path: '7digit', component: CrossmallListComponent, canActivate: [UserRoleGuardService] },
      { path: '10digit', component: CrossmallListComponent, canActivate: [UserRoleGuardService] },
      { path: 'importPrice', component: ImportPriceComponent, canActivate: [UserRoleGuardService] },
    ]
  }
];
export const CrossmallRoutingModule = RouterModule.forRoot(routes);
