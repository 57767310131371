import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 * Project ListのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/__/__    新規作成
 */
@Injectable()
export class DeliveryEntryDialogService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  registerDeliverySlip(formValue) {
    const body = {
      'inputDate': formValue.inputDate,
      'warehouseId': formValue.warehouseId,
      'deliverySlipId': formValue.deliverySlipNum
    };
    return this.httpClient.post(`${this.API_URL}rest/delivery/delivery-slip`, body);  // サーバAPI用
  }

}
