import { Component, ViewChild } from '@angular/core';
import { ShipmentService } from './ShipmentService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'zozo-shipment',
  templateUrl: './Shipment.html',
  styleUrls: ['./Shipment.scss'],
  providers: [],
})
export class ShipmentComponent extends AbstractComponent<any> {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public ecName: string;
  public file;
  public registerFlg = false;

  constructor(
    private service: ShipmentService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    super();
  }
  protected render() {
    this.props.form = new FormGroup({
      csv: new FormControl(null, [Validators.required])
    });

    this.props.targetCode = this.route.url['value'][1].path;
    this.props.pageTitle = (this.props.targetCode === 'all') ? '全from ⇒ 全to 連携' :
                            (this.props.targetCode === 'af') ? 'アパレル ZOZO 出荷データ連携' : 'ZOZO 出荷データ連携';
    this.service.get(this.props.targetCode).subscribe((res: any) => {
      this.props.displayedColumns = [
        'output_file_name',
        'output_item_count',
        'input_file_name',
        'input_item_count',
        'exec_status_name',
        'created_at',
        'created_by',
      ];
      this.props.dataSource = new MatTableDataSource<any>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
    });

  }

  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onChange(files) {
    this.file = files;
  }

  onSubmit() {
    this.loading = true;
    this.registerFlg = true;
    this.service.uploadFile(this.file, this.props.targetCode).subscribe((res: any) => {
      this.registerFlg = false;
      this.render();
      this.toastr.success('出荷データ連携を開始しました');
    }, (err) => {
      console.log(err);
      this.toastr.error((typeof err.message === 'string') ? err.message : err.message.join('<br>'));
      this.registerFlg = false;
      this.loading = false;
    });
  }
	download(dirPath) {
    this.service.downloadCsv(dirPath).subscribe((res: any) => {
      const fileName = dirPath.slice(dirPath.lastIndexOf('/') + 1);
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(res, fileName);
      } else {
        const objectURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = objectURL;
        link.download = fileName;
        link.click();
        document.body.removeChild(link);
      }
    });
  }

}
