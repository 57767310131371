import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 *
 * @author GAUSS
 * @since 1.0.0
 */

@Injectable()
export class PageUpdateService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  show(id) {
    return this.httpClient.get(`${this.API_URL}/rest/cms/page/${id}`, {});
  }

  postContent(data) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/page`, data);
  }
  patchContent(id, form: FormData) {
    return this.httpClient.patch(`${this.API_URL}/rest/cms/page/${id}`, form);
  }

  postPreview(data) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/page/preview`, data);
  }

}
