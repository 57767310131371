import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { DetailService } from './DetailService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { CmsUtil } from '../CmsUtil';

@Component({
  selector: 'detail',
  templateUrl: './Detail.html',
  styleUrls: ['./Detail.scss'],
  providers: []
})
export class DetailComponent extends AbstractComponent<any> {
  // コンストラクタ
  constructor(private service: DetailService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {
    super();
    this.props = {
    };
  }

  protected render() {
    this.props.ecName = CmsUtil.getEcName(this.route.url['value'][0].path);
    const siteId = CmsUtil.getSiteId(this.route.url['value'][0].path);
    this.props.form = new FormGroup({
      siteId: new FormControl(siteId),
      contentType: new FormControl('item_detail'),
      relationItemName: new FormControl(false),
      relationItemPrice: new FormControl(true),
      relationItemFixed: new FormControl('1'),
      relationNew: new FormControl('2'),
      relationRanking: new FormControl('2'),
      relationRecommend: new FormControl('2'),
      relationTarget: new FormControl('1'),
      relationCategory: new FormControl('1'),
      relationBrand: new FormControl('1'),
      relationInventoryCount: new FormControl('1', [Validators.required, Validators.pattern("^[0-9]*$")]),
      relationShowCount: new FormControl('6', [Validators.required, Validators.pattern("^[0-9]*$")]),
      relationItems: new FormArray([]),
      newItemName: new FormControl(false),
      newItemPrice: new FormControl(true),
      newInventoryCount: new FormControl('1', [Validators.required, Validators.pattern("^[0-9]*$")]),
      newShowCount: new FormControl('6', [Validators.required, Validators.pattern("^[0-9]*$")]),
      rankingItemName: new FormControl(false),
      rankingItemPrice: new FormControl(true),
      rankingInventoryCount: new FormControl('1', [Validators.required, Validators.pattern("^[0-9]*$")]),
      rankingShowCount: new FormControl('6', [Validators.required, Validators.pattern("^[0-9]*$")]),
      recommendItemName: new FormControl(false),
      recommendItemPrice: new FormControl(true),
      recommendItems: new FormArray([]),
    });
    const relationItems = this.props.form.get('relationItems');
    const recommendItems = this.props.form.get('recommendItems');
    for (let i = 0; i < 6; i++) {
      relationItems.push(new FormControl(''));
      recommendItems.push(new FormControl(''));
    }

    this.loading = true;
    this.service.getContent(siteId).subscribe((res: any) => {
      if (_.get(res, 'content.contentType')) {
        for (let i = 0; i < res.content.relationItems.length - 6; i++) {
          relationItems.push(new FormControl(''));
        }
        for (let i = 0; i < res.content.recommendItems.length - 6; i++) {
          recommendItems.push(new FormControl(''));
        }

        this.props.form.patchValue({
          siteId: siteId,
          contentType: 'item_detail',
          relationItemName: res.content.relationItemName,
          relationItemPrice: res.content.relationItemPrice,
          relationItemFixed: res.content.relationItemFixed,
          relationNew: res.content.relationNew,
          relationRanking: res.content.relationRanking,
          relationRecommend: res.content.relationRecommend,
          relationTarget: res.content.relationTarget,
          relationCategory: res.content.relationCategory,
          relationBrand: res.content.relationBrand,
          relationInventoryCount: res.content.relationInventoryCount,
          relationShowCount: res.content.relationShowCount,
          relationItems: res.content.relationItems,
          newItemName: res.content.newItemName,
          newItemPrice: res.content.newItemPrice,
          newInventoryCount: res.content.newInventoryCount,
          newShowCount: res.content.newShowCount,
          rankingItemName: res.content.rankingItemName,
          rankingItemPrice: res.content.rankingItemPrice,
          rankingInventoryCount: res.content.rankingInventoryCount,
          rankingShowCount: res.content.rankingShowCount,
          recommendItemName: res.content.recommendItemName,
          recommendItemPrice: res.content.recommendItemPrice,
          recommendItems: res.content.recommendItems,
        });
      }
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
  }

  get relationItems(): FormArray { return this.props.form.get('relationItems') as FormArray; }
  get recommendItems(): FormArray { return this.props.form.get('recommendItems') as FormArray; }

  public onAddRelation() {
    this.relationItems.push(new FormControl(''));
  }
  public onDeleteRelation(idx) {
    if (this.relationItems.length > 1) {
      this.relationItems.removeAt(idx);
    }
  }
  public onAddRecommend() {
    this.recommendItems.push(new FormControl(''));
  }
  public onDeleteRecommend(idx) {
    if (this.recommendItems.length > 1) {
      this.recommendItems.removeAt(idx);
    }
  }

  public onSubmit() {
    if (this.props.form.invalid) {
      return;
    }

    this.loading = true;
    this.service.postContent(this.props.form.value).subscribe(() => {
      this.loading = false;
      this.toastr.success('保存しました。');
      this.render();
    }, (err) => {
      this.loading = false;
      this.toastr.error('保存に失敗しました。');
      console.log(err);
    });
  }

  public onPreview() {
    if (this.props.form.invalid) {
      return;
    }

    this.loading = true;
    this.service.postPreview(this.props.form.value).subscribe((res: any) => {
      window.open(res.url, 'preview');
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
      const msg = (typeof err === 'string') ? err : '保存に失敗しました。';
      this.toastr.error(msg);
    });
    return;
  }

}
