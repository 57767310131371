import { RouterModule, Routes } from '@angular/router';
import { RoleGuardService } from '../RoleGuardService';
import { IndexComponent } from './index/IndexComponent';
import { DetailComponent } from './detail/DetailComponent';
import { RankingComponent } from './ranking/RankingComponent';
import { NewComponent } from './new/NewComponent';
import { SaleComponent } from './sale/SaleComponent';
import { BannerListComponent } from './banner/list/BannerListComponent';
import { BannerUpdateComponent } from './banner/update/BannerUpdateComponent';
import { NoticeListComponent } from './notice/list/NoticeListComponent';
import { NoticeUpdateComponent } from './notice/update/NoticeUpdateComponent';
import { PageListComponent } from './page/list/PageListComponent';
import { PageUpdateComponent } from './page/update/PageUpdateComponent';
import { RakutenComponent } from './setting/rakuten/RakutenComponent';
import { WowmaComponent } from './setting/wowma/WowmaComponent';
import { YahooComponent } from './setting/yahoo/YahooComponent';
import { YahooTokenComponent } from './setting/yahoo-token/YahooTokenComponent';


/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'cms',
    children: [
      { path: 'rakuten/index', component: IndexComponent, canActivate: [RoleGuardService] },
      { path: 'rakuten/detail', component: DetailComponent, canActivate: [RoleGuardService] },
      { path: 'rakuten/ranking', component: RankingComponent, canActivate: [RoleGuardService] },
      { path: 'rakuten/new', component: NewComponent, canActivate: [RoleGuardService] },
      { path: 'rakuten/sale', component: SaleComponent, canActivate: [RoleGuardService] },
      { path: 'rakuten/banner', component: BannerListComponent, canActivate: [RoleGuardService] },
      { path: 'rakuten/banner/update/:id', component: BannerUpdateComponent, canActivate: [RoleGuardService] },
      { path: 'rakuten/notice', component: NoticeListComponent, canActivate: [RoleGuardService] },
      { path: 'rakuten/notice/update/:id', component: NoticeUpdateComponent, canActivate: [RoleGuardService] },
      { path: 'rakuten/page', component: PageListComponent, canActivate: [RoleGuardService] },
      { path: 'rakuten/page/update/:id', component: PageUpdateComponent, canActivate: [RoleGuardService] },
      { path: 'rakuten/setting', component: RakutenComponent, canActivate: [RoleGuardService] },

      { path: 'paypay/index', component: IndexComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/detail', component: DetailComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/ranking', component: RankingComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/new', component: NewComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/sale', component: SaleComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/banner', component: BannerListComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/banner/update/:id', component: BannerUpdateComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/notice', component: NoticeListComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/notice/update/:id', component: NoticeUpdateComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/page', component: PageListComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/page/update/:id', component: PageUpdateComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/setting', component: YahooComponent, canActivate: [RoleGuardService] },
      { path: 'paypay/setting/token', component: YahooTokenComponent, canActivate: [RoleGuardService] },

      { path: 'wowma/index', component: IndexComponent, canActivate: [RoleGuardService] },
      { path: 'wowma/detail', component: DetailComponent, canActivate: [RoleGuardService] },
      { path: 'wowma/ranking', component: RankingComponent, canActivate: [RoleGuardService] },
      { path: 'wowma/new', component: NewComponent, canActivate: [RoleGuardService] },
      { path: 'wowma/sale', component: SaleComponent, canActivate: [RoleGuardService] },
      { path: 'wowma/banner', component: BannerListComponent, canActivate: [RoleGuardService] },
      { path: 'wowma/banner/update/:id', component: BannerUpdateComponent, canActivate: [RoleGuardService] },
      { path: 'wowma/notice', component: NoticeListComponent, canActivate: [RoleGuardService] },
      { path: 'wowma/notice/update/:id', component: NoticeUpdateComponent, canActivate: [RoleGuardService] },
      { path: 'wowma/page', component: PageListComponent, canActivate: [RoleGuardService] },
      { path: 'wowma/page/update/:id', component: PageUpdateComponent, canActivate: [RoleGuardService] },
      { path: 'wowma/setting', component: WowmaComponent, canActivate: [RoleGuardService] },
    ]
  }
];
export const CmsRoutingModule = RouterModule.forRoot(routes);
