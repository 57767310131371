import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { SalesMgtUploadDialogService } from './SalesMgtUploadDialogService'
import { ToastrService } from 'ngx-toastr';
import { FileUploader } from 'ng2-file-upload';


@Component({
  selector: 'sales-mgt-upload-dialog',
  templateUrl: './SalesMgtUploadDialog.html',
  styleUrls: ['./SalesMgtUploadDialog.scss'],
  providers: []
})
export class SalesMgtUploadDialogComponent extends AbstractComponent<any> {
  public uploader: FileUploader;

  public props = {
    parent: null,
    hasBaseDropZoneOver: false,
    form: null,
    file: null,
    selectSiteList: this.getSelectSiteList(),
    supportedFileTypes: [
      'text/csv',
      'application/vnd.ms-excel',
      'application/octet-stream',
    ],
    selectedDateRequired: false,
  };

  // コンストラクタ
  constructor(
    @Inject(MAT_DIALOG_DATA) public parameter: any,
    private fb: FormBuilder,
    private service: SalesMgtUploadDialogService,
    private matDialogRef: MatDialogRef<SalesMgtUploadDialogComponent>,
    private toastr: ToastrService,
  ) {
    super();
    if (parameter) {
      this.props.parent = parameter.parent;
    }
  }

  protected render() {
    this.uploader = new FileUploader({});
    this.props.hasBaseDropZoneOver = false;
    this.props.form = this.fb.group({
      siteId: new FormControl('999', Validators.required),
      selectedDate: new FormControl(''),
      overwriteFlag: new FormControl(false, Validators.required),
    });
  }

  /* 実行 */
  protected async onSubmit() {
    if (this.props.form.invalid) {
      return;
    }
    const siteId = this.props.form.get('siteId').value;
    if ((siteId === '005' || siteId === '002') && !this.props.form.get('selectedDate').value) {
      this.toastr.error('売上日は必須項目です');
      return;
    }
    if (!this.props.file) {
      this.toastr.error('csvファイルが読み込めません。');
      return;
    }
    const data = new FormData();
    Object.keys(this.props.form.value).map(key => {
      data.append(key, this.props.form.value[key]);
    });
    data.append('file', this.props.file, this.props.file.name);

    this.loading = true;
    this.service.post(data).subscribe((res: any) => {
      this.toastr.success('登録処理を開始しました');
      this.matDialogRef.close();
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.toastr.error('登録処理に失敗しました');
      this.loading = false;
      this.matDialogRef.close();
    });
  }

  public showSelectedDate() {
    const siteId = this.props.form.get('siteId').value;
    return siteId === '005' || siteId === '002'　|| siteId === '003';
  }

  public fileOverBase(e: any): void {
    this.props.hasBaseDropZoneOver = e;
  }

  /** UPLOAD処理 */
  public upload(files) {
    this.uploadSelect({ target: { files } });
  }

  /**  */
  public uploadSelect(e) {
    if (e.target.files && e.target.files[0]) {
      this.props.file = e.target.files[0];
    }
    return false;
  }

  private getSelectSiteList() {
    return [
      {
        siteId: '999',
        siteName: 'カスタム',
      },
      {
        siteId: '004',
        siteName: 'ZOZOTOWN',
      },
      {
        siteId: '005',
        siteName: 'SHOPLIST',
      },
      {
        siteId: '008',
        siteName: 'MAGASEEK',
      },
      {
        siteId: '002',
        siteName: '楽天市場',
      },
      {
        siteId: '003',
        siteName: 'Yahoo!PayPayモール',
      },
      // {
      //   siteId: '012',
      //   siteName: 'Wowma',
      // },
    ];
  }
}
