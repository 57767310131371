import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

/**
 * Project ListのServiceクラス
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/__/__    新規作成
 */

@Injectable()
export class RearrivalListService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getConvHistory() {
    const params = new HttpParams()
      .set('category', 'rearrival');
    return this.httpClient.get(`${this.API_URL}/rest/item/conv-history`, { params: params });
  }

  postRearrivalItem(data) {
    const body = {
      'dateFrom': parseInt(data.dateFrom, 10),
      'dateTo': parseInt(data.dateTo, 10)
    };
    const res = this.httpClient.post(`${this.API_URL}/rest/item/rearrival`, body);
    return res;
  }

  downloadCsv(convertedFileName, dirPath) {
    const zipPath = dirPath + '/' + convertedFileName;
    return this.httpClient.get(`${this.API_URL}/rest/${zipPath}`, { responseType: 'blob' });
  }

}
