import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class SalesMgtListService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  get() {
    return this.httpClient.get(`${this.API_URL}/rest/sales-mgt/list`);
  }

}
