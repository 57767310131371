import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class PageListService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getList(siteId) {
    const params = new HttpParams()
      .set('siteId', siteId);
    return this.httpClient.get(`${this.API_URL}/rest/cms/page`, { params });
  }
}
