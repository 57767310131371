import { Component, ViewChild } from '@angular/core';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog, MatTable } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import clonedeep from 'lodash.clonedeep';
import { CmsUtil } from '../../CmsUtil';

import { BannerListService } from './BannerListService';
import * as moment from 'moment';
import { BannerDeleteDialogComponent } from '../delete-dialog/BannerDeleteDialogComponent';

export interface BannerElement {
  banner_type: string;
  banner_title: string;
  banner_url: string;
  start_date: Date;
  end_date: Date;
  tid: number;
  display_order: number;
  image_url: string;
}

@Component({
  selector: 'banner-list',
  templateUrl: './BannerList.html',
  styleUrls: ['./BannerList.scss'],
  providers: [],
})
export class BannerListComponent extends AbstractComponent<any> {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private service: BannerListService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    super();
  }


  protected render() {
    this.props.ecName = CmsUtil.getEcName(this.route.url['value'][0].path);
    const siteId = CmsUtil.getSiteId(this.route.url['value'][0].path);

    this.service.getList(siteId).subscribe((e: any) => {

      const result: any = [];
      for (let i = 0; i < e.length; i++) {
        result.push(Object.assign({
          no : (i + 1),
          banner_url: e[i].banner_url + '?' + moment().format('YYYYMMDDHHmmssdddd'),
        }, e[i]));
      }
      this.props.displayedColumns = [
        'no',
        'banner_type',
        'banner_title',
        'image_url',
        'banner_url',
        'start_date',
        'end_date',
        'actions'
      ];
      this.props.dataSource = new MatTableDataSource<any[]>(result);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
    });
  }


  /** 登録/更新 */
  public update(type, element= {}) {
    const site = this.route.url['value'][0].path;
    this.router.navigate([`/cms/${site}/banner/update/${ element['tid'] ? element['tid'] : 'create' }`, {}]);
  }

  /** 削除 */
  public delete(element) {
    const dialogRef = this.dialog.open(BannerDeleteDialogComponent, {
      disableClose : true,
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SUCCESS') {
        this.render();
      }
    }, error => {
      console.log(error);
    });

  }

  public dateFormat(date) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }

  /*フィルター検索*/
  public applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public onListDrop(event: CdkDragDrop<BannerElement[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    let idx = 0;
    for (const e of this.props.dataSource.data) {
      e.no = ++idx;
    }
    this.props.dataSource.data = clonedeep(this.props.dataSource.data);
    this.loading = true;
    this.service.updateoOrder(this.props.dataSource.data.map(e => e.tid)).subscribe((res: any) => {
      this.loading = false;
      // this.render();
    }, (e: any) => {
      console.log(e);
      this.loading = false;
    });
  }
}
