import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class BannerUpdateService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  show(id) {
    return this.httpClient.get(`${this.API_URL}/rest/cms/banner/${id}`, {});
  }

  create(form: FormData) {
    return this.httpClient.post(`${this.API_URL}/rest/cms/banner`, form);
  }

  patch(id, form: FormData) {
    return this.httpClient.patch(`${this.API_URL}/rest/cms/banner/${id}`, form);
  }
}
