import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class ShoplistMovementService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  get(warehouseId) {
    return this.httpClient.get(`${this.API_URL}/rest/shoplist/movement/${warehouseId}`);
  }

}
