import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { SkuDialogService } from './SkuDialogService';
import { ExcludeListComponent } from '../ExcludeListComponent';


/**
 * 案件登録画面
 *  <Controller>
 *
 * @author GAUSS
 * @since 1.0.0
 *
 * 履歴：
 * NO 日付        更新者        内容
 *  1 2018/06/28 T.Utsunomiya   新規作成
 */
@Component({
  selector: 'sku-dialog',
  templateUrl: './SkuDialog.html',
  styleUrls: ['./SkuDialog.scss']
})
export class SkuDialogComponent extends AbstractComponent<any> {
  private parent: ExcludeListComponent;
  private id: string;
  private redirectUrl: string;

  // コンストラクタ
  constructor(private service: SkuDialogService,
    @Inject(MAT_DIALOG_DATA) public parameter: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder) {
    super();
    if (parameter) {
      console.log(parameter);
      
      this.parent = parameter.parent;
      this.props.data = parameter.data;
      this.props.dataSource = null;
    }
  }

  /** 書き出し */
  protected render() {
    this.service.getSku(this.props.data.item_id).subscribe((res:any) =>{
      const result = res.map((data, no) => {
        no += 1;
        return {
          no,
          jan_code: data.jan_code, 
          color_name: data.color_name,
          color_id: data.color_id, 
          size_name: data.size_name, 
          size_id: data.size_id, 
        }
      });
      this.props.dataSource = new MatTableDataSource<any[]>(result);  
    });
    // ユーザ一覧取得 
    this.loading = true;
    
    this.props.displayedColumns = ['no', 'jan_code', 'color_name', 'color_id', 'size_name', 'size_id'];
    
    this.loading = false;
  }

  
}
