import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserListComponent } from '../list/UserListComponent';
import { UserDeleteDialogService } from './UserDeleteDialogService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'user-delete-dialog',
  templateUrl: './UserDeleteDialog.html',
  styleUrls: ['./UserDeleteDialog.scss'],
  providers: []
})
export class UserDeleteDialogComponent extends AbstractComponent<any> {

  private parent: UserListComponent;
  // コンストラクタ
  constructor(private service: UserDeleteDialogService,
    @Inject(MAT_DIALOG_DATA) public parameter: any,
    private toastr: ToastrService,
    private formBuilder: FormBuilder) {
    super();
    if (parameter) {
      this.parent = parameter.parent;
      this.props.user = parameter.user;
    }
  }

  /** 書き出し */
  protected render() {
    let id: string;
    let name: string;
    let email: string;
    if (this.props.user) {
      id = this.props.user.id || '';
      name = this.props.user.name || '';
      email = this.props.user.email || '';
    }
    this.props.registerForm = this.formBuilder.group({
      name: new FormControl(name, Validators.required),
      email: new FormControl(email),
    });
  }

  onSubmit(id) {
    this.loading = true;
    if (this.props.registerForm.invalid) {
      return;
    }
    const dialogRef = this.parent.deleteDialogRef;
    this.service.deleteUser(id).subscribe((res: any) => {
      this.loading = false;
      dialogRef.close();
      this.toastr.success('削除が完了しました');
    }, (err) => {
      this.loading = false;
      dialogRef.close();
      this.toastr.error('ユーザ削除に失敗しました。');
      this.loading = false;
    });
  }
}
