
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()
export class DeliveryLogService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getLog() {
    return this.httpClient.get(`${this.API_URL}/rest/delivery/log-list`);
  }

  downloadCsv(warehouseId, inputDate) {
    const params = new HttpParams()
      .set('fileName', inputDate.toString(10))
      .set('warehouseId', warehouseId.toString(10));
    return this.httpClient.get(`${this.API_URL}/rest/delivery/csv`, { params: params, responseType: 'blob' });
  }
}
