import { RouterModule, Routes } from '@angular/router';
import { UserRoleGuardService } from '../UserRoleGuardService';
import { PvsListComponent } from './pvs/PvsListComponent';
import { MaruiListComponent } from './marui/MaruiListComponent';


/** ルーティング設定 */
const routes: Routes = [
  {
    path: 'dh',
    children: [
      { path: 'pvs', component: PvsListComponent, canActivate: [UserRoleGuardService] },
      { path: 'marui', component: MaruiListComponent, canActivate: [UserRoleGuardService] },
    ]
  }
];
export const DhRoutingModule = RouterModule.forRoot(routes);
