import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { RearrivalListService } from './RearrivalListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'rearrival-list',
  templateUrl: './RearrivalList.html',
  styleUrls: ['./RearrivalList.scss'],
  providers: []
})
export class RearrivalListComponent extends AbstractComponent<any> {

  // View テーブル情報
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // コンストラクタ
  constructor(private service: RearrivalListService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {
    super();
    this.props = {
      dataSource: null,
    };
  }

  // Override
  protected render() {
    this.props.dateList = [];
    for (let cnt = 1; cnt <= 14; cnt++) {
      this.props.dateList.push(cnt);
    }
    // 取得期間入力
    this.props.acquisitionPeriodForm = this.formBuilder.group({
      dateFrom: new FormControl('', Validators.required),
      dateTo: new FormControl('', Validators.required),
    });

    // ユーザ一覧取得
    this.loading = true;
    this.service.getConvHistory().subscribe((res: any) => {
      this.props.displayedColumns = ['item_count', 'created_at', 'created_by', 'actions'];
      this.props.dataSource = new MatTableDataSource<any[]>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
      this.route.params.subscribe(param => {
        if (param.color && param.msg) {
          this.setToast(param.color, param.msg);
        }
      });
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
  }

  /*フィルター検索*/
  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* csvファイルをupload */
  onSubmit() {
    this.service.postRearrivalItem(this.props.acquisitionPeriodForm.value).subscribe(() => {
      this.toastr.success('商品の取得を開始しました。');
      this.render();
    }, (err) => {
      this.toastr.error('商品の取得に失敗しました。');
      console.log(err);
    });
  }

  /* zipダウンロード */
  download(convertedFileName, filePath) {
    this.loading = true;
    this.service.downloadCsv(convertedFileName, filePath).subscribe((res: any) => {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(res, convertedFileName);
      } else {
        const objectURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = objectURL;
        link.download = `${convertedFileName}`;
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false;
    }, (err) => {
      this.toastr.error('対象ファイルはダウンロードできません。');
      this.loading = false;
    });
  }

  /* toast表示 */
  setToast(color, msg) {
    if (color === '1') {
      color = 'success';
    }
    if (msg === '10') {
      msg = '登録が完了しました';
    }
    if (msg === '20') {
      msg = '更新が完了しました';
    }
    console.log(color, msg);
    this.toastr.success(msg);
  }
}
