
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from 'src/core/common/service/AbstractService';

@Injectable()

export class DeliveryResultDialogService extends AbstractService {
  constructor(private httpClient: HttpClient) {
    super();
  }
  getCsv(fileName, dirId) {
    const params = new HttpParams().set('fileName', fileName).set('dirId', dirId);
    return this.httpClient.get(`${this.API_URL}/rest/delivery/history-csv`, { params: params, responseType: 'blob' });
  }
}
