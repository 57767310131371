import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource, MatDialogRef } from '@angular/material';
import { UserListService } from './UserListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { ToastrService } from 'ngx-toastr';
import { UserDeleteDialogComponent } from '../delete-dialog/UserDeleteDialogComponent';

@Component({
  selector: 'user-list',
  templateUrl: './UserList.html',
  styleUrls: ['./UserList.scss'],
  providers: []
})
export class UserListComponent extends AbstractComponent<any> {

  // View テーブル情報
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public deleteDialogRef: MatDialogRef<UserDeleteDialogComponent>;

  // コンストラクタ
  constructor(private service: UserListService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public toastr: ToastrService) {
    super();
    this.props = {
      dataSource: null,
    };
  }

  // Override
  protected render() {
    // ユーザ一覧取得
    this.loading = true;
    this.service.getUser().subscribe((res: any) => {
      const result = res.map((data, no) => {
        no++;
        return {
          no: no, id: data.tid, name: data.userName, email: data.email, role: data.role
        };
      });
      this.props.displayedColumns = ['no', 'name', 'email', 'actions'];
      this.props.dataSource = new MatTableDataSource<any[]>(result);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
      this.route.params.subscribe(param => {
        if (param.color && param.msg) {
          this.setToast(param.color, param.msg);
        }
      });
      this.loading = false;
    }, (err) => {
      this.loading = false;
    });
  }

  /*フィルター検索*/
  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /*ユーザ登録・編集*/
  updateUser(element) {
    if (element === 'create') {
      this.router.navigate(['/admin/user/update/', { type: element }]);
    } else {
      this.router.navigate(['/admin/user/update/',
      { type: 'update', no: element.no, id: element.id, name: element.name, email: element.email, role: element.role }]);
    }
  }

  /*ユーザ削除*/
  // tslint:disable-next-line:no-shadowed-variable
  deleteUser(element) {
    this.deleteDialogRef = this.dialog.open(UserDeleteDialogComponent, {
      data: { 'user': element, parent: this }
    });
    this.deleteDialogRef.afterClosed().subscribe(result => {
      this.render();
    });
  }

  /* toast表示 */
  setToast(color, msg) {
    if (color === '1') {
      color = 'success';
    }
    if (msg === '10') {
      msg = '登録が完了しました';
    }
    if (msg === '20') {
      msg = '更新が完了しました';
    }
    this.toastr.success(msg);
  }
}
