import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NewListService } from './NewListService';
import { AbstractComponent } from 'src/core/common/component/AbstractComponent';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'new-list',
  templateUrl: './NewList.html',
  styleUrls: ['./NewList.scss'],
  providers: []
})
export class NewListComponent extends AbstractComponent<any> {

  // View テーブル情報
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public file: any;

  // コンストラクタ
  constructor(private service: NewListService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    super();
    this.props = {
      dataSource: null,
      registerFlg: false,
    };
  }

  // Override
  protected render() {
    this.props.form = new FormGroup({
      csv: new FormControl(null, [Validators.required])
    });

    this.loading = true;
    this.service.getConvHistory().subscribe((res: any) => {
      this.props.displayedColumns = ['item_count', 'created_at', 'created_by', 'actions'];
      this.props.dataSource = new MatTableDataSource<any[]>(res);
      this.props.dataSource.sort = this.sort;
      this.props.dataSource.paginator = this.paginator;
      this.route.params.subscribe(param => {
        if (param.color && param.msg) {
          this.setToast(param.color, param.msg);
        }
      });
      this.loading = false;
    }, (err) => {
      console.log(err);
      this.loading = false;
    });
  }

  /*フィルター検索*/
  applyFilter(filterValue: string) {
    this.props.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* csvファイルがuploadされた時 */
  onChange(files) {
    this.file = files;
  }

  /* csvファイルをupload */
  onSubmit() {
    this.loading = true;
    this.props.registerFlg = true;
    try {
      this.service.uploadCsv(this.file).subscribe((res: any) => {
        this.render();
        this.toastr.success('登録処理を開始しました');
        this.props.registerFlg = false;
        this.loading = false;
      }, (err) => {
        console.log(err);
        this.toastr.error((err.message) ? err.message : '変換に失敗しました');
        this.props.registerFlg = false;
        this.loading = false;
      });
    } catch (e) {
      console.log(e);
      this.toastr.error((e.message) ? e.message : '変換に失敗しました');
      this.props.registerFlg = false;
      this.loading = false;
    }

  }

  /* zipダウンロード */
  download(convertedFileName, filePath) {
    this.loading = true;
    this.service.downloadCsv(convertedFileName, filePath).subscribe((res: any) => {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(res, convertedFileName);
      } else {
        const objectURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = objectURL;
        link.download = `${convertedFileName}`;
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false;
    }, (err) => {
      this.toastr.error('対象ファイルはダウンロードできません。');
      this.loading = false;
    });
  }

  /* toast表示 */
  setToast(color, msg) {
    if (color === '1') {
      color = 'success';
    }
    if (msg === '10') {
      msg = '登録が完了しました';
    }
    if (msg === '20') {
      msg = '更新が完了しました';
    }
    this.toastr.success(msg);
  }

}
